import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
import {
  add_edit_diver,
  delete_driver,
  get_driver,
  get_otp,
} from "../../utils/apis";
import { toast } from "react-toastify";
import { Delete_dialog } from "../../utils/DialogBox";
import ButtonComp from "../../components/ButtonComp";
import OtpInput from "react-otp-input";
import { BiArrowBack } from "react-icons/bi";
import CustomInput from "../../components/custom/CustomInput";
import Add_Doc from "../../components/custom/Add_doc";
import { ingress, Loader, user_id } from "../../utils/utils";

const DriverDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [detail, setDetail] = useState({});
  const [doc, setDoc] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [docList, setDocList] = useState([]);
  const [edit, setEdit] = useState(location.state.edit);
  const [open, setOpen] = React.useState(false);
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp1_show, setOtp1_show] = useState(false);
  const [otp2_show, setOtp2_show] = useState(false);
  const [otp_loading, setOtp_loading] = useState("0");
  const [userId1, setUserId1] = useState("");
  const [userId2, setUserId2] = useState("");
  const [action, setAction] = useState("1");
  const [id, setId] = useState("");

  const get_d = () => {
    setLoading(true);
    get_driver(user_id, location.state.id)
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setDetail(data.data.details[0]);
          setId(data.data.details[0].id);
          setDoc(data.data.documents);
        } else {
          toast.error("wrong");
          setDetail({});
          setDoc([]);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    get_d();
  }, []);
  const handle_deleteDriver = () => {
    setOpen(false);

    delete_driver(user_id, location.state.id, action, id)
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          action == 1 && navigate(-1);
          get_d();
        } else {
          toast.error(data.message);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  const handleFileChange = (e, i) => {
    if (Object.entries(docList[i]).length == 0) {
      toast.error("please enter document name");
      return;
    }
    let newitem_detail = docList.map((el, ind) =>
      ind === i
        ? {
            ...el,
            name: e.target.files[0].name,
            docData: e.target.files[0],
          }
        : el
    );
    setDocList(newitem_detail);
  };
  const filter_data = docList.filter(
    (v, i) => Object.entries(docList[i]).length != 0 && v?.name !== ""
  );

  const handle_getotp = (id, no, load) => {
    setOtp_loading(load);
    get_otp(no)
      .then((res) => {
        if (res.success) {
          id ? setOtp1_show(true) : setOtp2_show(true);
          id ? setUserId1(res.id) : setUserId2(res.id);
          toast.success(res.message);
          setOtp_loading("0");
        } else {
          toast.error(res.message[0].mobile);
          setOtp_loading("0");
        }
      })
      .catch((e) => {
        setOtp_loading("0");
      });
  };
  // console.log(detail.pan);
  const edit_driver = () => {
    setLoading1(true);
    const body = new FormData();
    body.append("userid", user_id);
    body.append("driverid", detail.id);
    body.append("name", detail.name);
    body.append("mobile1", detail.mobile1);
    body.append("mobile2", detail.mobile2);
    body.append("address", detail.address);
    body.append("aadhar", detail.aadhaar);
    body.append("pan", detail.pan);
    body.append("drivinglicense", detail.license);
    body.append("drivinglicensevalidity", detail.license_validity);
    body.append("ingress", ingress);
    body.append("documentscount", filter_data.length);
    body.append("names", filter_data.map((v) => v.filename).join(",,,"));
    {
      filter_data.forEach((v, i) =>
        body.append(`${"document" + i}`, v.docData)
      );
    }
    add_edit_diver(body).then((data) => {
      data && navigate(-1);
      console.log(data);
      setLoading1(false);
    });
  };
  return (
    <div>
      <div className={"card custom_input_df_button padding-xy sb alc mb10"}>
        <div className="heading-text">Driver Detail</div>
        <div className="df gap20  ">
          <ButtonComp
            tittle={"Edit"}
            icon={<FiEdit2 />}
            onClick={() => {
              setEdit(true);
            }}
          />
          <ButtonComp
            tittle={"Delete"}
            icon={<IoTrashOutline />}
            b_color={"#f00"}
            onClick={() => {
              setId(location.state.id);
              setOpen(true);
              setAction("1");
            }}
          />
          <ButtonComp
            b_color={"#000"}
            tittle={"Back"}
            icon={<BiArrowBack />}
            onClick={() => navigate(-1)}
          />
        </div>
      </div>
      {loading ? (
        <div className={"card p20 jcc df"}>
          <Loader />
        </div>
      ) : (
        <div className={"card padding-xy"}>
          <CustomInput
            textonly={edit ? false : true}
            textonly1={edit ? false : true}
            disabled1={edit ? false : true}
            disabled2={edit ? false : true}
            label1={"Driver Name"}
            value1={detail.name}
            onChange1={(e) => {
              let updatedValue = {};
              updatedValue = { name: e.target.value };
              setDetail((pre) => ({ ...pre, ...updatedValue }));
            }}
            label2={"Aadhar"}
            value2={detail.aadhaar}
            onChange2={(e) => {
              let updatedValue = {};
              updatedValue = { aadhaar: e.target.value };
              setDetail((pre) => ({ ...pre, ...updatedValue }));
            }}
          />
          <CustomInput
            textonly={edit ? false : true}
            textonly1={edit ? false : true}
            disabled1={edit ? false : true}
            disabled2={edit ? false : true}
            label1={"Pan Number"}
            value1={detail.pan}
            onChange1={(e) => {
              let updatedValue = {};
              updatedValue = { pan: e.target.value };
              setDetail((pre) => ({ ...pre, ...updatedValue }));
            }}
            label2={"Driving License Number"}
            value2={detail.license}
            onChange2={(e) => {
              let updatedValue = {};
              updatedValue = { license: e.target.value };
              setDetail((pre) => ({ ...pre, ...updatedValue }));
            }}
          />
          <CustomInput
            area1={edit ? true : false}
            textonly={edit ? false : true}
            disabled1={edit ? false : true}
            label1={"Address"}
            value1={detail.address}
            onChange1={(e) => {
              let updatedValue = {};
              updatedValue = { address: e.target.value };
              setDetail((pre) => ({ ...pre, ...updatedValue }));
            }}
            textonly1={edit ? false : true}
            label2={"Driving License Validity"}
            type2="date"
            min2={new Date().toISOString().split("T")[0]}
            value2={detail.license_validity}
            onChange2={(e) => {
              let updatedValue = {};
              updatedValue = { license_validity: e.target.value };
              setDetail((pre) => ({ ...pre, ...updatedValue }));
            }}
          />
          <CustomInput
            textonly1
            label1={"Mobile 1"}
            value1={detail.mobile1}
            label2={"Mobile 2"}
            value2={detail.mobile2}
            disabled1
            disabled2
            textonly
            onChange1={(e) => {
              let updatedValue = {};
              updatedValue = { mobile1: e.target.value };
              setDetail((pre) => ({ ...pre, ...updatedValue }));
            }}
            onChange2={(e) => {
              let updatedValue = {};
              updatedValue = { mobile2: e.target.value };
              setDetail((pre) => ({ ...pre, ...updatedValue }));
            }}
            button1={
              edit &&
              detail.mobile1 != detail.mobile1 && (
                <ButtonComp
                  loading={otp_loading == "1" ? true : false}
                  tittle={"Get OTP"}
                  onClick={() => handle_getotp(true, detail.mobile1, "1")}
                />
              )
            }
            button2={
              edit &&
              detail.mobile2 != detail.mobile2 && (
                <ButtonComp
                  loading={otp_loading == "2" ? true : false}
                  tittle={"Get OTP"}
                  onClick={() => handle_getotp(false, detail.mobile2, "2")}
                />
              )
            }
            bottom1={
              otp1_show && (
                <div className={"otp_container"}>
                  <OtpInput
                    value={otp1}
                    shouldAutoFocus={true}
                    onChange={(e) => setOtp1(e)}
                    numInputs={4}
                    inputStyle={"inputStyle"}
                    separator={<span style={{ margin: 5 }}> </span>}
                  />
                </div>
              )
            }
            bottom2={
              otp2_show && (
                <div className={"otp_container"}>
                  <OtpInput
                    value={otp2}
                    onChange={(e) => setOtp2(e)}
                    numInputs={4}
                    inputStyle={"inputStyle"}
                    separator={<span style={{ margin: 5 }}> </span>}
                  />
                </div>
              )
            }
          />

          <div className={"df alc mt10 sb"}>
            <div>Documents</div>
            {edit && (
              <ButtonComp
                tittle={"Add"}
                onClick={() => setDocList((pre) => [...pre, {}])}
              ></ButtonComp>
            )}
          </div>
          {doc.map((v, i) => (
            <div className={"text_bg mt10 alc df sb"} key={i}>
              <div>{v.name}</div>
              <IoTrashOutline
                className={"icons trash"}
                onClick={() => {
                  setId(v.id);
                  setOpen(true);
                  setAction("2");
                }}
              />
            </div>
          ))}
          {docList.map((v, i) => (
            <Add_Doc
              key={i}
              single
              filename={v.name}
              value={v.filename}
              placeholder={"Enter document name"}
              onChange1={(e) => {
                let newitem_detail = docList.map((el, ind) =>
                  ind === i
                    ? {
                        ...el,
                        filename: e.target.value,
                        name: "",
                      }
                    : el
                );
                setDocList(newitem_detail);
              }}
              onChange={(e) => handleFileChange(e, i)}
              right={
                <ButtonComp
                  tittle={"Delete"}
                  b_color={"#f00"}
                  onClick={() =>
                    setDocList((pre) =>
                      pre.find((v, ind) => ind === i)
                        ? pre.filter((v, index) => index !== i)
                        : [...pre]
                    )
                  }
                />
              }
            />
          ))}

          {edit && (
            <div className="df jcc mt20">
              <ButtonComp
                tittle={"Submit"}
                loading={loading1}
                onClick={() => edit_driver()}
              />
            </div>
          )}
          <div className="mt20 df" />
        </div>
      )}
      <Delete_dialog
        open={open}
        setOpen={setOpen}
        onClick_delete={() => handle_deleteDriver()}
      />
    </div>
  );
};

export default DriverDetail;

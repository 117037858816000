import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
const user_id = user?.userid;
const ingress = user?.access;

export const get_new_order_list = createAsyncThunk("order", async () => {
  const body = new FormData();
  body.append("userid", user_id);
  body.append("ingress", ingress);
  body.append("action", 1);
  const response = await fetch(base_url + "pilot-orders", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  console.log("eewwef", res);
  return res;
});
export const get_processsing_list = createAsyncThunk("processing", async () => {
  const body = new FormData();
  body.append("userid", user_id);
  body.append("ingress", ingress);
  const response = await fetch(base_url + "pilot-accept-orderslist", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  console.log("eewwef", res);
  return res;
});
export const get_deliverd_list = createAsyncThunk("delivered", async () => {
  const body = new FormData();
  body.append("userid", user_id);
  body.append("ingress", ingress);
  body.append("action", 6);
  const response = await fetch(base_url + "pilot-orders", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  console.log("eewwef", res);
  return res;
});
export const get_rejected_list = createAsyncThunk("reject", async () => {
  const body = new FormData();
  body.append("userid", user_id);
  body.append("ingress", ingress);
  body.append("action", 4);
  const response = await fetch(base_url + "pilot-orders", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  console.log("eewwef", res);
  return res;
});

const pilotOrdersSlice = createSlice({
  initialState: {
    n_order_loading: false,
    processing_loading: false,
    delivered_loading: false,
    reject_loading: false,
    new_order_list: [],
    processing_list: [],
    delivered_list: [],
    reject_list: [],
  },
  name: "driver",
  extraReducers: (builder) => {
    builder.addCase(get_new_order_list.pending, (state, action) => {
      state.n_order_loading = true;
    });
    builder.addCase(get_new_order_list.rejected, (state, action) => {
      state.n_order_loading = false;
    });
    builder.addCase(get_new_order_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.n_order_loading = false;
      if (data.success) {
        state.new_order_list = data.data;
      } else {
        state.new_order_list = [];
      }
    });

    builder.addCase(get_processsing_list.pending, (state, action) => {
      state.processing_loading = true;
    });
    builder.addCase(get_processsing_list.rejected, (state, action) => {
      state.processing_loading = false;
    });
    builder.addCase(get_processsing_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.processing_loading = false;
      if (data.success) {
        state.processing_list = data.data;
      } else {
        state.processing_list = [];
      }
    });

    builder.addCase(get_deliverd_list.pending, (state, action) => {
      state.delivered_loading = true;
    });
    builder.addCase(get_deliverd_list.rejected, (state, action) => {
      state.delivered_loading = false;
    });
    builder.addCase(get_deliverd_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.delivered_loading = false;
      if (data.success) {
        state.delivered_list = data.data;
      } else {
        state.delivered_list = [];
      }
    });

    builder.addCase(get_rejected_list.pending, (state, action) => {
      state.reject_loading = true;
    });
    builder.addCase(get_rejected_list.rejected, (state, action) => {
      state.reject_loading = false;
    });
    builder.addCase(get_rejected_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.reject_loading = false;
      if (data.success) {
        state.reject_list = data.data;
      } else {
        state.reject_list = [];
      }
    });
  },
});

export default pilotOrdersSlice.reducer;

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import allotmentSlice from "./slice/allotmentSlice";
import driverSlice from "./slice/driverSlice";
import godownSlice from "./slice/godownSlice";
import inventorySlice from "./slice/inventorySlice";
import notificationSlice from "./slice/notificationSlice";
import orderSlice from "./slice/orderSlice";
import productSlice from "./slice/productSlice";
import userSlice from "./slice/userSlice";
import vehicleSlice from "./slice/vehicleSlice";
import { userApi } from "./userApi";
import indentSlice from "./slice/indentSlice";
import pilotOrdersSlice from "./slice/pilotOrdersSlice";

const store = configureStore({
  reducer: {
    // [userApi.reducerPath]: userApi.reducer,
    user: userSlice,
    vehicle: vehicleSlice,
    driver: driverSlice,
    godown: godownSlice,
    product: productSlice,
    inventory: inventorySlice,
    order: orderSlice,
    allotment: allotmentSlice,
    notification: notificationSlice,
    indents: indentSlice,
    pilotOrders: pilotOrdersSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(userApi.middleware),
});

setupListeners(store.dispatch);
export default store;

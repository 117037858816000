import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiChevronDown, BiEditAlt, BiSupport } from "react-icons/bi";
import { MdOutlineLogout, MdOutlineNotificationsNone } from "react-icons/md";
import { useSelector } from "react-redux";
import { ingress } from "../../utils/utils";
import { FaUserAlt } from "react-icons/fa";
import ContactUs from "./ContactUs";
import Report from "./Report";
import { GrBug } from "react-icons/gr";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import store from "../../redux/store";
import { remove_profile_data } from "../../redux/slice/userSlice";

export default function ProfileIcon() {
  const navigate = useNavigate();
  const [open_con, setOpen_con] = React.useState(false);
  const [open_rep, setOpen_rep] = React.useState(false);
  const [open_alert, setOpen_alert] = React.useState(false);

  const { data } = useSelector((state) => state.user);
  const handleClickClose = () => {
    setOpen_alert(false);
  };
  const location = useLocation();
  const [isActive, setIsActive] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  React.useEffect(() => {
    setIsActive(location.pathname);
  }, [location]);
  return (
    <React.Fragment>
      <div
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={"show_p gap10 cursor alc"}
      >
        <div className="avtar ">
          {data?.length == 0 ? (
            <FaUserAlt />
          ) : ingress == 4 ? (
            data[0]?.profile[0]?.name?.split("")[0]
          ) : (
            data[0]?.profile[0]?.tradename?.split("")[0]
          )}
        </div>
        <div>
          {data?.length == 0 ||
            (ingress == 4 && (
              <>
                <div>{data[0]?.profile[0]?.name}</div>
                <div style={{ fontSize: 10 }}>
                  {data[0]?.profile[0]?.driverid}
                </div>
              </>
            ))}
          {(ingress == 1 || ingress == 2) && data.length != 0 && (
            <>
              <div>{data[0]?.profile[0]?.tradename?.split(" ")[0]}</div>
              {ingress == 1 ? (
                <div style={{ fontSize: 10 }}>
                  {data[0]?.profile[0]?.vendorid}
                </div>
              ) : (
                <div style={{ fontSize: 10 }}>{data[0]?.profile[0]?.dpid}</div>
              )}
            </>
          )}
        </div>
        <BiChevronDown size={20} />
      </div>
      <Box
        className={"hide_p"}
        sx={{ alignItems: "center", textAlign: "center" }}
      >
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2, gap: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar sx={{ width: 32, height: 32, backgroundColor: "#169e49" }}>
            {data?.length == 0 || ingress == 4
              ? data[0]?.profile[0]?.name?.split("")[0]
              : data[0]?.profile[0]?.tradename?.split("")[0]}
          </Avatar>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="p10  " style={{ width: "200px " }}>
          <Link
            onClick={() => handleClose()}
            to={"/profile/information"}
            className={`df alc   ${
              isActive == "/profile"
                ? "link activelink-bg"
                : "link unactivelink-bg"
            }`}
          >
            <BiEditAlt size={20} />
            Edit Profile
          </Link>
          <Link
            onClick={() => handleClose()}
            to={"notifications"}
            className={`df alc   ${
              isActive == "/notifications"
                ? "link activelink-bg"
                : "link unactivelink-bg"
            }`}
          >
            <MdOutlineNotificationsNone size={20} />
            Notification
          </Link>
          <Link
            onClick={() => {
              setOpen_con(true);
              handleClose();
            }}
            className={` alc  hide_p ${
              isActive == "/contact"
                ? "link activelink-bg"
                : "link unactivelink-bg"
            }`}
          >
            <BiSupport className="cursor" size={20} />
            Contact Us
          </Link>
          <Link
            onClick={() => {
              setOpen_rep(true);
              handleClose();
            }}
            className={` alc hide_p  ${
              isActive == "/report"
                ? "link activelink-bg"
                : "link unactivelink-bg"
            }`}
          >
            <GrBug className="cursor" size={20} />
            Report
          </Link>

          <Link
            onClick={() => {
              handleClose();
              setOpen_alert(true);
            }}
            className={`df alc   ${
              isActive == "" ? "link activelink-bg" : "link unactivelink-bg"
            }`}
          >
            <MdOutlineLogout size={20} />
            Log out
          </Link>
        </div>
      </Menu>
      <Dialog
        open={open_alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to logout?"}
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClickClose}>cancle</Button>
          <Button
            onClick={() => {
              localStorage.clear("user");
              store.dispatch(remove_profile_data());
              handleClickClose();
              navigate("/login");
            }}
            // autoFocus
          >
            Log Out
          </Button>
        </DialogActions>
      </Dialog>
      <ContactUs open={open_con} setOpen={setOpen_con} />
      <Report open={open_rep} setOpen={setOpen_rep} />
    </React.Fragment>
  );
}

import { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";

export const base_url = "https://gaswala.vensframe.com/api/";

export const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
export const isSmallScreen = window.matchMedia("(max-width: 500px)").matches;
export const valid_mobile = (no) => {
  if (no.length != 10) {
    toast.info("Please enter valid a mobile");
    return;
  }
};
export const NetworkStatus = () => {
  const [status, setStatus] = useState(true);

  useEffect(() => {
    function changeStatus() {
      setStatus(navigator.onLine);
    }
    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
  }, []);

  return status ? "Online" : "Offline";
};
export default function net_info() {
  return <NetworkStatus />;
}

export const No_data = () => {
  return (
    <div className="p20 alc jcc card db flex">
      <h3 className="" style={{ textAlign: "center", fontWeight: "bold" }}>
        No data found
      </h3>
    </div>
  );
};

export const Page_not_found = () => {
  return (
    <div className="p20 alc jcc card db flex">
      <img
        src="https://dashtar-admin.vercel.app/static/media/404.90088bea.svg"
        style={{
          height: "50%",
          width: "50%",
          display: "flex",
          margin: "auto",
        }}
      />
      <h3 className="" style={{ textAlign: "center", fontWeight: "bold" }}>
        Page Not Found
      </h3>
    </div>
  );
};
const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
export const user_id = user?.userid;
export const ingress = user?.access;

export const Loader = () => {
  return (
    <Oval
      height={60}
      width={60}
      color="#4fa94d"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor="#4fa94d"
      strokeWidth={5}
      strokeWidthSecondary={5}
    />
  );
};

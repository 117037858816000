import React, { useEffect, useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddIcon from "@mui/icons-material/Add";
import { TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TableView from "../../components/custom/TableView";
import { toast } from "react-toastify";
import ButtonComp from "../../components/ButtonComp";
import { useDispatch, useSelector } from "react-redux";
import {
  base_url,
  isSmallScreen,
  Loader,
  No_data,
  user_id,
} from "../../utils/utils";
import { FiEye } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
import { delete_product } from "../../utils/apis";
import { Delete_dialog } from "../../utils/DialogBox";
import {
  get_all_products,
  get_product_list,
} from "../../redux/slice/productSlice";

const headerData = [
  "S. No.",
  "Name",
  "Type",
  "Stock",
  "RSP",
  "Discount",
  "Offered Price",
  "Status",
  "Action",
];

const Products = () => {
  const dispatch = useDispatch();
  const { product_list, p_loading } = useSelector((state) => state.product);
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = useState("");
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState("");
  const [_, reload] = useState({});
  useEffect(() => {
    setProduct(product_list);
    dispatch(get_product_list());
  }, []);
  // React.useEffect(() => {
  //   dispatch(get_product_list());
  //   window.addEventListener("change", setProduct(product_list));
  //   setProduct(product_list);
  //   // cleanup this component
  //   return () => {
  //     window.removeEventListener(
  //       "change",
  //       dispatch(get_product_list()),
  //       setProduct(product_list)
  //     );
  //   };
  // }, []);
  const update_product = async (id) => {
    try {
      setLoading1(product[id].id);
      const body = new FormData();
      body.append("userid", user_id);
      body.append("action", 2);
      body.append("productid", product[id].id);
      body.append("discount", product[id].discount);
      body.append("stock", product[id].stock);

      const response = await fetch(base_url + "product", {
        method: "POST",
        body: body,
      });
      const res = await response.json();

      setLoading1("");
      if (res.success) {
        toast.success(res.message);
        dispatch(get_product_list());
      } else {
        toast.success(res.message);
      }
    } catch (error) {
      setLoading1("");
    }
  };
  const delete_p = () => {
    dispatch(get_all_products());
    setOpen(false);
    delete_product(id, setLoading).then((v) => {
      v && dispatch(get_product_list());
      setProduct(product_list);
    });
  };

  return (
    <>
      <div className={"card df padding-xy sb mb10 alc"}>
        <div
          className={
            "border-gray-200 bg-gray-100 padding-xy alc df br search-bar "
          }
        >
          <input
            className="flex"
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <SearchOutlinedIcon />
        </div>
        <div className="flex"></div>

        <ButtonComp
          onClick={() => navigate("/products/add-product")}
          tittle={isSmallScreen ? "Add" : "Add Products"}
          icon={<AddIcon />}
        />
      </div>

      {product_list.length == 0 && p_loading ? (
        <div className="card p20 df alc jcc">
          <Loader />
        </div>
      ) : product_list.length == 0 ? (
        <No_data />
      ) : (
        <TableView
          headerData={headerData}
          bodyData={product
            .filter((item) => {
              return (
                item.description.toLowerCase().includes(search.toLowerCase()) ||
                item.type.toLowerCase().includes(search.toLowerCase())
              );
            })
            .map((v, i) => (
              <TableRow key={i}>
                <TableCell align="center">{i + 1}</TableCell>
                <TableCell align="center">{v.description}</TableCell>
                <TableCell align="center">{v.type}</TableCell>
                <TableCell align="center">
                  {v.status != 0 ? (
                    v.stock
                  ) : (
                    <div className="df jcc " style={{ padding: 0 }}>
                      <input
                        type="number"
                        onChange={(e) => {
                          let newitem_detail = product.map((el, ind) =>
                            ind === i
                              ? {
                                  ...el,
                                  stock: e.target.value,
                                }
                              : el
                          );
                          setProduct(newitem_detail);
                        }}
                        className={
                          "border-gray-200 bg-gray-100 padding-xy alc df br search-bar "
                        }
                        placeholder={v.stock}
                        value={v.stock}
                      />
                    </div>
                  )}
                </TableCell>
                <TableCell align="center">{v.price}</TableCell>
                <TableCell align="center">
                  {v.status == 0 ? (
                    <div className="df jcc">
                      <input
                        type="number"
                        onChange={(e) => {
                          let newitem_detail = product.map((el, ind) =>
                            ind === i
                              ? {
                                  ...el,
                                  discount: e.target.value,
                                }
                              : el
                          );
                          setProduct(newitem_detail);
                        }}
                        className={
                          "border-gray-200 bg-gray-100 padding-xy alc df br search-bar "
                        }
                        placeholder={v.discount}
                        value={v.discount}
                      />
                    </div>
                  ) : (
                    v.discount
                  )}
                </TableCell>
                <TableCell align="center">{v.price - v.discount}</TableCell>
                <TableCell align="center">{v.statustext}</TableCell>

                <TableCell align="center">
                  <div className="df jcc alc">
                    <FiEye
                      size={20}
                      className={"icons black"}
                      // onClick={() =>
                      //   navigate("/driverdetail", {
                      //     state: { id: v.id, edit: false },
                      //   })
                      // }
                    />
                    <IoTrashOutline
                      className={"icons trash"}
                      onClick={() => {
                        setId(v.id);
                        setOpen(true);
                      }}
                      size={20}
                    />
                    {v.status == 0 && (
                      <ButtonComp
                        tittle={"Update"}
                        loading={loading1 == v.id ? true : false}
                        onClick={() => update_product(i)}
                      />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
        />
      )}
      <Delete_dialog
        open={open}
        setOpen={setOpen}
        onClick_delete={() => delete_p()}
      />
    </>
  );
};

export default Products;

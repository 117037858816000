import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonComp from "../../components/ButtonComp";
import { get_rejected_list } from "../../redux/slice/orderSlice";
import { Loader, No_data } from "../../utils/utils";

const Reject = () => {
  const dispatch = useDispatch();
  const { reject_list, reject_loading } = useSelector((state) => state.order);
  useEffect(() => {
    dispatch(get_rejected_list());
  }, []);
  return (
    <div>
      {reject_list.length == 0 && reject_loading ? (
        <div className="card p20 df alc jcc">
          <Loader />
        </div>
      ) : reject_list.length == 0 ? (
        <No_data />
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: window.matchMedia("(max-width: 500px)").matches
              ? "repeat(1, 1fr)"
              : window.matchMedia("(max-width: 900px)").matches
              ? "repeat(2, 1fr)"
              : "repeat(3, 1fr)",
            gridGap: 20,
          }}
        >
          {reject_list.map((v, i) => (
            <div className={"card p20"} key={i}>
              <div style={{ justifyContent: "space-between", display: "flex" }}>
                <div className="fs16 fw600 orange pb6">{v.orderid}</div>
              </div>

              <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Datetime:</div>
                <div className="fs14 fw400 black">{v.datetime}</div>
              </div>
              <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Message:</div>
                <div className="fs14 fw400 black">{v.message}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Reject;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_processsing_list } from "../../redux/slice/orderSlice";
import { Loader, No_data } from "../../utils/utils";
import store from "../../redux/store";
import ButtonComp from "../../components/ButtonComp";
import { useNavigate } from "react-router-dom";

const Processing = () => {
  const navigate = useNavigate();
  const { processing_list, processing_loading } = useSelector(
    (state) => state.order
  );
  useEffect(() => {
    store.dispatch(get_processsing_list());
  }, []);
  return (
    <div>
      {processing_list.length == 0 && processing_loading ? (
        <div className="card p20 df alc jcc">
          <Loader />
        </div>
      ) : processing_list.length == 0 ? (
        <No_data />
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: window.matchMedia("(max-width: 500px)").matches
              ? "repeat(1, 1fr)"
              : window.matchMedia("(max-width: 900px)").matches
              ? "repeat(2, 1fr)"
              : "repeat(3, 1fr)",
            gridGap: 20,
          }}
        >
          {processing_list.map((v, i) => (
            <div className={"card p20"} key={i}>
              <div style={{ justifyContent: "space-between", display: "flex" }}>
                <div className="fs16 fw600 orange pb6">{v.orderid}</div>
                {/* <div className="fs14 fw600 green  cursor">View Detail</div> */}
                <div className="fs14 fw400 black">{v.datetime}</div>
              </div>

              {/* <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Datetime:</div>
                <div className="fs14 fw400 black">{v.datetime}</div>
              </div> */}
              <div className={"df alc sb gap20"}>
                <div className="df alc gap10 pb6">
                  <div className="fs14 fw400 black">Status:</div>
                  <div className="fs14 fw400 green">{v.orderstatustext}</div>
                </div>
                <ButtonComp
                  tittle={"View Detail"}
                  height={30}
                  onClick={() => {
                    navigate("/orders/order-detail", { state: v });
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Processing;

import React, { useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { MdOutlineVerified } from "react-icons/md";
import { Oval } from "react-loader-spinner";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ButtonComp from "../../components/ButtonComp";
import CustomInput from "../../components/custom/CustomInput";
import { get_da_profile, get_vendor_profile } from "../../utils/api";
import { get_otp, verify_email } from "../../utils/apis";
import { base_url, ingress, isValidEmail, user_id } from "../../utils/utils";

const Bussiness_profile = () => {
  const dispatch = useDispatch();
  const { data, state_list, service_list } = useSelector((state) => state.user);
  const [edit, setEdit] = useState(false);
  const [b_info, setB_info] = useState({});
  const [state_dist1, setState_dist1] = useState([]);
  const [state_dist2, setState_dist2] = useState([]);
  const [pin_loading, setPin_loading] = useState("0");
  const [otp1_show, setOtp1_show] = useState(false);
  const [otp2_show, setOtp2_show] = useState(false);
  const [otp1, setOtp1] = useState("");
  const [suplier_list, setSuplier_list] = useState(
    service_list.filter((v) =>
      data[0]?.profile[0]?.supplier_text?.split(",").includes(v.label)
    )
  );
  const [b_loading, setB_loading] = useState(false);
  const [email_loading, setEmail_loading] = useState("0");
  const [otp_loading, setOtp_loading] = useState("0");
  const verifyEmail = async (email) => {
    verify_email(email, setEmail_loading);
  };
  const list = async (id, setDist) => {
    try {
      const body = new FormData();
      body.append("stateid", id);
      const response = await fetch(base_url + "completelist", {
        body: body,
        method: "POST",
      });
      const res = await response.json();
      if (res.success) {
        const data1 = [];
        for (let i = 0; i < res.data.reponselist.districts.length; i++) {
          const element = {
            value: res.data.reponselist.districts[i].id,
            label: res.data.reponselist.districts[i].name,
          };
          data1.push(element);
          setDist(data1);
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const pincode_verify = async (code, id, load) => {
    try {
      const body = new FormData();
      body.append("pincode", code);
      body.append("state", id);
      setPin_loading(load);
      const response = await fetch(base_url + "pincode_verify", {
        body: body,
        method: "POST",
      });
      const res = await response.json();
      // console.log("pincode", res);
      if (res.success) {
        toast.success(res.message);
        setPin_loading("0");
      } else {
        toast.error(res.message);
        setPin_loading("0");
      }
    } catch (error) {
      // console.log(error);
      setPin_loading("0");
    }
  };

  const getOtp = (no, load) => {
    setOtp_loading(load);
    get_otp(no).then((data) => {
      if (data.success) {
        setOtp_loading("0");
        load == "1" ? setOtp1_show(true) : setOtp2_show(true);
        toast.success(data.message);
      } else {
        toast.error(data.message);
        setOtp_loading("0");
      }
    });
  };
  useEffect(() => {
    setB_info(data[0]?.profile[0]);
    list(
      state_list?.find(
        (v) =>
          v.label.toUpperCase() == data[0]?.profile[0]?.state?.toUpperCase()
      )?.value,
      setState_dist1
    );
    list(
      state_list?.find(
        (v) =>
          v.label.toUpperCase() ==
          data[0]?.profile[0]?.state_business?.toUpperCase()
      )?.value,
      setState_dist2
    );
  }, [data]);
  const submit_business = async () => {
    try {
      if (!isValidEmail.test(b_info?.email_business)) {
        toast.info("Please enter a valid email");
        return;
      }
      if (b_info.mobile_business.length != 10) {
        toast.info("Please enter valid a mobile");
        return;
      }
      setB_loading(true);
      const body = new FormData();
      body.append("userid", user_id);
      body.append("mobile", b_info.mobile_business);
      body.append("email", b_info.email_business);
      body.append(
        "address",
        b_info.address_business +
          ",,," +
          b_info.state_business +
          ",,," +
          b_info.district_business +
          ",,," +
          b_info.pincode_business
      );
      body.append("tan", b_info.tan);
      body.append("address1", b_info.address);
      body.append("state", b_info.state);
      body.append("district", b_info.district);
      body.append("pincode", b_info.pincode);
      ingress == 1 &&
        body.append("pps", suplier_list.map((v) => v.value).toString());
      body.append("action", 1);
      body.append("website", b_info.website);
      const response = await fetch(
        base_url + `${ingress == 1 ? "update_profile" : "update_profile_dp"}`,
        { method: "POST", body: body }
      );
      const res = await response.json();
      // console.log("network", res);
      if (res.success) {
        toast.success(res.message);
        setB_loading(false);
        ingress == 1 ? get_vendor_profile() : get_da_profile();
      } else {
        toast.error(res.message);
        setB_loading(false);
      }
    } catch (error) {
      setB_loading(false);
    }
  };
  return (
    <>
      <div className={"df alc sb"}>
        <div className="h2_heading">Profile Information</div>
        {/* <div>Profile Information</div> */}
        <ButtonComp
          tittle={edit ? "Cancel" : "Edit"}
          icon={!edit ? <FiEdit2 /> : ""}
          onClick={() => {
            setEdit(!edit);
          }}
        />
      </div>
      <div>
        <CustomInput
          textonly={true}
          textonly1={true}
          label1={"GST IN"}
          disabled1
          disabled2
          value1={b_info?.gst}
          label2={"Pan Number"}
          value2={b_info?.pan}
        />
        <CustomInput
          textonly={true}
          textonly1={true}
          label1={"Trade Name"}
          value1={b_info?.tradename}
          label2={"Legal Name"}
          value2={b_info?.legalname}
        />
        <CustomInput
          textonly={!edit}
          textonly1={!edit}
          label1={"Address"}
          area1={edit}
          value1={b_info?.address}
          onChange1={(e) => {
            setB_info({ ...b_info, address: e.target.value });
          }}
          drop2={edit}
          label2={"State"}
          value2={
            !edit
              ? b_info?.state
              : // : state_list?.filter((option) =>
                //     b_info?.state.includes(option.label)
                //   )
                state_list?.filter(
                  (option) =>
                    option.label.toUpperCase() === b_info?.state?.toUpperCase()
                )
          }
          options2={state_list}
          onChange2={(e) => {
            list(e.value, setState_dist1);
            let updatedValue = {};
            updatedValue = { state: e.label };
            setB_info((pre) => ({ ...pre, ...updatedValue }));
          }}
        />
        <CustomInput
          textonly={!edit}
          textonly1={!edit}
          label1={"District"}
          drop1={edit}
          options1={state_dist1}
          value1={
            !edit
              ? b_info?.district
              : state_dist1.filter(
                  (option) => option.label === b_info?.district
                )
          }
          onChange1={(e) => {
            let updatedValue = {};
            updatedValue = { district: e.label };
            setB_info((pre) => ({ ...pre, ...updatedValue }));
          }}
          label2={"Pincode"}
          maxLength2={6}
          type2={"number"}
          value2={b_info?.pincode}
          onChange2={(e) => {
            e.target.value.length == 6 &&
              pincode_verify(
                e.target.value,
                state_list.find(
                  (v) => v.label.toUpperCase() == b_info?.state?.toUpperCase()
                )?.value,
                "1"
              );
            let updatedValue = {};
            updatedValue = {
              pincode: e.target.value.toString().substring(0, 6),
            };
            setB_info((pre) => ({ ...pre, ...updatedValue }));
          }}
          right2={
            pin_loading == "1" && (
              <Oval
                height={18}
                width={18}
                color="#169e49"
                wrapperStyle={{}}
                // wrapperClass={style.loader}
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#fff"
                strokeWidth={7}
                strokeWidthSecondary={7}
              />
            )
          }
        />
        {ingress == 1 && (
          <CustomInput
            textonly={!edit}
            textonly1={!edit}
            label1={"Principle Product Supplier"}
            value1={
              !edit
                ? b_info?.supplier_text?.length == 0
                  ? "Select"
                  : b_info?.supplier_text
                : suplier_list
            }
            drop1={edit}
            onChange1={(v, i) => {
              setSuplier_list(v);
            }}
            multi1
            options1={service_list}
            label2={"TAN"}
            placeholder2={"Enter TAN Number"}
            value2={
              b_info?.tan?.length == 0
                ? !edit
                  ? "Enter TAN Number"
                  : b_info?.tan
                : b_info?.tan
            }
            onChange2={(e) => {
              let updatedValue = {};
              updatedValue = { tan: e.target.value };
              setB_info((pre) => ({ ...pre, ...updatedValue }));
            }}
          />
        )}
        <CustomInput
          textonly={!edit}
          textonly1={!edit}
          label1={"Website"}
          placeholder1={"Enter website"}
          value1={b_info?.website}
          onChange1={(e) => {
            let updatedValue = {};
            updatedValue = { website: e.target.value };
            setB_info((pre) => ({ ...pre, ...updatedValue }));
          }}
          single={ingress == 1 ? true : false}
          label2={ingress == 1 ? "" : "TAN"}
          placeholder2={"Enter TAN Number"}
          value2={
            b_info?.tan?.length == 0
              ? !edit
                ? "Enter TAN Number"
                : b_info?.tan
              : b_info?.tan
          }
          onChange2={(e) => {
            let updatedValue = {};
            updatedValue = { tan: e.target.value };
            setB_info((pre) => ({ ...pre, ...updatedValue }));
          }}
        />
      </div>
      <h4 className="mt20">Business Information</h4>

      <CustomInput
        textonly={!edit}
        textonly1={!edit}
        label1={"Email ID"}
        value1={b_info?.email_business}
        onChange1={(e) => {
          let updatedValue = {};
          updatedValue = { email_business: e.target.value.toLowerCase() };
          setB_info((pre) => ({ ...pre, ...updatedValue }));
        }}
        label2={"Mobile Number"}
        value2={b_info?.mobile_business}
        onChange2={(e) => {
          let updatedValue = {};
          updatedValue = { mobile_business: e.target.value };
          setB_info((pre) => ({ ...pre, ...updatedValue }));
        }}
        right1={
          b_info?.email_verify1 === 1 && <MdOutlineVerified color="green" />
        }
        button1={
          b_info?.email_verify1 === 0 ? (
            <ButtonComp
              tittle={"Verify"}
              b_color={"green"}
              loading={email_loading == b_info?.email_business ? true : false}
              onClick={() => verifyEmail(b_info?.email_business)}
            ></ButtonComp>
          ) : (
            b_info?.email_business != b_info?.email_business && (
              <ButtonComp
                tittle={"Verify"}
                b_color={"green"}
                loading={email_loading == b_info?.email_business ? true : false}
                onClick={() => verifyEmail(b_info?.email_business)}
              ></ButtonComp>
            )
          )
        }
        right2={
          b_info?.mobile_verify1 === 1 && <MdOutlineVerified color="green" />
        }
        button2={
          b_info?.mobile_business !== b_info?.mobile_business && (
            <ButtonComp
              tittle={"Verify"}
              b_color={"green"}
              onClick={() => getOtp(b_info?.mobile_business, "1")}
            ></ButtonComp>
          )
        }
        bottom2={
          otp1_show && (
            <div className={"otp_container"}>
              <OtpInput
                value={otp1}
                onChange={(e) => setOtp1(e)}
                numInputs={4}
                inputStyle={"inputStyle"}
                separator={<span style={{ margin: 5 }}> </span>}
              />
            </div>
          )
        }
      />

      <CustomInput
        textonly={!edit}
        textonly1={!edit}
        area1
        label1={"Address"}
        value1={b_info?.address_business}
        onChange1={(e) => {
          let updatedValue = {};
          updatedValue = { address_business: e.target.value };
          setB_info((pre) => ({ ...pre, ...updatedValue }));
        }}
        label2={"State"}
        // value2={b_info.state_business}
        drop2={edit}
        value2={
          !edit
            ? b_info?.state_business
            : state_list.filter(
                (option) =>
                  option.label.toUpperCase() ===
                  b_info?.state_business?.toUpperCase()
              )
        }
        options2={state_list}
        onChange2={(e) => {
          list(e.value, setState_dist2);
          let updatedValue = {};
          updatedValue = { state_business: e.label };
          setB_info((pre) => ({ ...pre, ...updatedValue }));
        }}
      />

      <CustomInput
        textonly={!edit}
        textonly1={!edit}
        label1={"District"}
        drop1={edit}
        onChange1={(e) => {
          let updatedValue = {};
          updatedValue = { district_business: e.label };
          setB_info((pre) => ({ ...pre, ...updatedValue }));
        }}
        value1={
          !edit
            ? b_info?.district_business
            : state_dist2.filter(
                (option) => option.label === b_info?.district_business
              )
        }
        options1={state_dist2}
        maxLength2={6}
        type2={"number"}
        label2={"Pincode"}
        onChange2={(e) => {
          e.target.value.length == 6 &&
            pincode_verify(
              e.target.value,
              state_list.find(
                (v) =>
                  v.label.toUpperCase == b_info?.state_business.toUpperCase()
              )?.value,
              "2"
            );
          let updatedValue = {};
          updatedValue = {
            pincode_business: e.target.value.toString().substring(0, 6),
          };
          setB_info((pre) => ({ ...pre, ...updatedValue }));
        }}
        value2={b_info?.pincode_business}
        right2={
          pin_loading == "2" && (
            <Oval
              height={18}
              width={18}
              color="#169e49"
              wrapperStyle={{}}
              // wrapperClass={style.loader}
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#fff"
              strokeWidth={7}
              strokeWidthSecondary={7}
            />
          )
        }
      />

      {edit && (
        <div className="df alc jcc">
          <ButtonComp
            top={20}
            tittle={"Update"}
            loading={b_loading}
            onClick={submit_business}
          />
        </div>
      )}
    </>
  );
};

export default Bussiness_profile;

import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonComp from "../../components/ButtonComp";
import Check from "../../components/Check";
import CustomInput from "../../components/custom/CustomInput";
import {
  check_gst,
  check_pincode,
  get_dist_list,
  send_otp,
} from "../../utils/apis";
import { isValidEmail } from "../../utils/utils";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import "./registration.scss";
import { da_signup, vendor_signup } from "../../utils/api";
import DeliveryPartner from "./DeliveryPartner";

const Registration = () => {
  const navigate = useNavigate();
  const { state_list, service_list } = useSelector((state) => state.user);
  const [same_address, setSame_address] = useState(true);
  const [gst_data, setGst_data] = useState({});
  const [suplier_list, setSuplier_list] = useState([]);
  const [reg_data, setReg_data] = useState({
    p_email: "",
    b_email: "",
    p_address: "",
    p_state: "",
    p_district: "",
    p_pincode: "",
    b_address: "",
    b_state: "",
    b_district: "",
    b_pincode: "",
    website: "",
    password: "",
    p_mobile_no: "",
    b_mobile_no: "",
    otp1: "",
    otp2: "",
  });
  const [term, setTerm] = useState(true);
  const [gst_no, setGst_no] = useState("");
  const [loading, setLoading] = useState(false);
  const [pin_loading, setPin_loading] = useState("");
  const [otp_loading, setOtp_loading] = useState("");
  const [dist_list1, setDist_list1] = useState([]);
  const [dist_list2, setDist_list2] = useState([]);
  const [dist_list3, setDist_list3] = useState([]);
  const [pincode_verified1, setPincode_verified1] = useState(null);
  const [pincode_verified2, setPincode_verified2] = useState(null);
  const [pincode_verified3, setPincode_verified3] = useState(null);
  const [show_otp1, setShow_otp1] = useState(false);
  const [show_otp2, setShow_otp2] = useState(false);
  const [user1, setUser1] = useState("");
  const [user2, setUser2] = useState("");
  const [products, setProducts] = useState({
    lpg: "",
    industrial_gas: "",
    allied_products: "",
    services: "",
  });
  const [registeras, setRegisteras] = useState(1);
  const [showPass, setShowPass] = useState(false);
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const get_gstData = (no) => {
    check_gst(no, setLoading).then((data) => {
      setGst_data(data);
      get_dist_list(
        state_list.find(
          (v) => v.label.toUpperCase() == data.state.toUpperCase()
        )?.value,
        setDist_list1
      );
    });
  };
  const validateGST = (gstNumber) => {
    setGst_no(gstNumber.toUpperCase());
    if (gstNumber) {
      const code = gstNumber.replace(/[^a-zA-Z0-9]/g, "");
      setGst_no(code);
    }
    if (gstNumber.length < 15) {
      //   console.log("here");
      return;
    }
    const regex =
      /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1})$/;
    // return regex.test(gstNumber);
    if (regex.test(gstNumber)) {
      get_gstData(gstNumber.toUpperCase());
      return;
    } else {
      toast.error("Invalid gst number");
      return;
    }
  };
  const checkPincode = (s_id, v, load) => {
    if (s_id == undefined) {
      toast.info("please select state");
      return;
    }

    setPin_loading(load);
    check_pincode(s_id, v, setLoading).then((data) => {
      load == 1
        ? setPincode_verified1(data)
        : load == 2
        ? setPincode_verified2(data)
        : setPincode_verified3(data);
      setPin_loading("");
    });
  };
  const sendOtp = (no, load) => {
    if (no.length == 0) {
      toast.info("please enter mobile number");
      return;
    }
    setOtp_loading(load);
    send_otp(no).then((data) => {
      if (data.success) {
        if (load == 1) {
          setShow_otp1(true);
          setUser1(data.id);
        } else {
          setShow_otp2(true);
          setUser2(data.id);
        }
      } else {
        if (load == 1) {
          setShow_otp1(false);
          setUser1("");
        } else {
          setShow_otp2(false);
          setUser2("");
        }
      }
      setOtp_loading("");
    });
  };

  const formdata = new FormData();
  formdata.append("userid", user1 + "," + user2);
  formdata.append("gst", gst_no);
  formdata.append("pan", gst_data.pan);
  formdata.append("tradename", gst_data.tradename);
  formdata.append("legalname", gst_data.legalname);
  formdata.append("address", gst_data.address);
  formdata.append("district", gst_data.district);
  formdata.append("state", gst_data.state);
  formdata.append("pincode", gst_data.pincode);
  formdata.append("mobile1", reg_data.p_mobile_no);
  formdata.append("otp1", reg_data.otp1);
  formdata.append("mobile2", reg_data.b_mobile_no);
  formdata.append("otp2", reg_data.otp2);
  formdata.append("email1", reg_data.p_email);
  formdata.append("email2", reg_data.b_email);
  formdata.append(
    "address1",
    reg_data.p_address +
      ",,," +
      reg_data.p_state +
      ",,," +
      reg_data.p_district +
      ",,," +
      reg_data.p_pincode
  );
  formdata.append("sameas", same_address ? 1 : 0);
  formdata.append("password", reg_data.password);
  formdata.append(
    "address2",
    same_address
      ? gst_data.address +
          ",,," +
          gst_data.state +
          ",,," +
          gst_data.district +
          ",,," +
          gst_data.pincode
      : reg_data.b_address +
          ",,," +
          reg_data.b_state +
          ",,," +
          reg_data.b_district +
          ",,," +
          reg_data.b_pincode
  );
  formdata.append("website", reg_data.website);
  registeras == 1 &&
    formdata.append(
      "supplier",
      suplier_list.map((v, i) => suplier_list[i].value).toString()
    );
  registeras == 1 &&
    formdata.append(
      "products",
      Object.values(products)
        .filter((value) => value.length !== 0)
        .toString()
    );
  const handle_signup = async () => {
    if (
      reg_data.p_email == "" ||
      reg_data.b_email == "" ||
      reg_data.p_address == "" ||
      reg_data.p_state == "" ||
      reg_data.p_district == "" ||
      reg_data.p_pincode == "" ||
      reg_data.website == "" ||
      reg_data.password == "" ||
      reg_data.p_mobile_no == "" ||
      reg_data.b_mobile_no == "" ||
      reg_data.otp1 == "" ||
      reg_data.otp2 == ""
    ) {
      toast.info("Please fill all the fields");
      return;
    }
    if (!same_address) {
      if (
        reg_data.b_address == "" ||
        reg_data.b_state == "" ||
        reg_data.b_district == "" ||
        reg_data.b_pincode == false
      ) {
        toast.info("Please fill all the fields");
        return;
      }
      if (!pincode_verified3) {
        toast.info("please enter valid pincode");
        return;
      }
    }
    if (pincode_verified1 == false || pincode_verified2 == false) {
      toast.info("please enter valid pincode");
      return;
    }
    if (
      !reg_data.p_email.match(isValidEmail) ||
      !reg_data.b_email.match(isValidEmail)
    ) {
      toast.error("Invalid email address");
      return;
    }
    if (registeras == 1) {
      if (suplier_list.length == 0) {
        toast.info("Please select principle product suplier");
        return;
      }
      if (
        Object.values(products).filter((value) => value.length !== 0).length ==
        0
      ) {
        toast.info("Please select products");
        return;
      }
    }
    registeras == 1
      ? vendor_signup(formdata, setLoading).then((v) => {
          v && navigate("/");
          window.location.reload();
          toast.success(v.message);
        })
      : da_signup(formdata, setLoading).then((v) => {
          v && navigate("/");
          window.location.reload();
          toast.success(v.message);
        });
  };
  useEffect(() => {
    if (user != null) {
      navigate("/");
    }
  }, [localStorage]);

  return (
    <>
      <div className="regi_container">
        <div className="regi_inner_container card db padding-xy   mb10">
          <h1>Sign up</h1>
          <div className="custom_input_df_button alc jcse mt10">
            <Check
              tittle={"Vendor"}
              checked={registeras == 1 ? true : false}
              onchange={() => setRegisteras(1)}
            />
            <Check
              tittle={"Delivery Associates"}
              checked={registeras == 2 ? true : false}
              onchange={() => setRegisteras(2)}
            />
            <Check
              tittle={"Delivery Partner"}
              checked={registeras == 3 ? true : false}
              onchange={() => setRegisteras(3)}
            />
          </div>
        </div>
        {registeras == 3 ? (
          <div className="regi_inner_container card p20">
            <DeliveryPartner />
          </div>
        ) : (
          <div className="regi_inner_container card p20">
            <CustomInput
              label1={"GST IN"}
              placeholder1={"Enter GST Number"}
              value1={gst_no}
              onChange1={(e) =>
                e.target.value.length < 16 &&
                validateGST(
                  e.target.value.toUpperCase().toString().substring(0, 15)
                )
              }
              right1={
                loading == "gst" && (
                  <Oval
                    height={15}
                    width={15}
                    color="#169e49"
                    wrapperStyle={{}}
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#fff"
                    strokeWidth={7}
                    strokeWidthSecondary={7}
                  />
                )
              }
              label2={"PAN No"}
              placeholder2={"Enter pan number"}
              disabled2
              value2={gst_data.pan}
            />
            <CustomInput
              label1={"Trade Name"}
              disabled1
              placeholder1={"Enter Trade Name"}
              value1={gst_data.tradename}
              label2={"Legal Name"}
              placeholder2={"Enter Legal Name"}
              value2={gst_data?.legalname}
              onChange2={(e) => {
                let updatedValue = {};
                updatedValue = { legalname: e.target.value };
                setGst_data((pre) => ({ ...pre, ...updatedValue }));
              }}
            />
            <CustomInput
              label1={"Address"}
              placeholder1={"Enter Address"}
              value1={gst_data?.address}
              area1
              label2={"State"}
              options2={state_list}
              value2={state_list.filter(
                (option) =>
                  option.label?.toUpperCase() == gst_data?.state?.toUpperCase()
              )}
              onChange2={(e) => {
                let updatedValue = {};
                updatedValue = { state: e.label };
                setGst_data((pre) => ({ ...pre, ...updatedValue }));
                get_dist_list(e.value, setDist_list1);
              }}
              drop2
            />
            <CustomInput
              label1={"District"}
              //   borderColor={"#f00"}
              drop1
              options1={dist_list1}
              value1={dist_list1.filter(
                (option) => option.label == gst_data?.district
              )}
              disabled1={dist_list1.length == 0 ? true : false}
              onChange1={(e) => {
                let updatedValue = {};
                updatedValue = { district: e.label };
                setGst_data((pre) => ({ ...pre, ...updatedValue }));
              }}
              label2={"Pincode"}
              placeholder2={"Enter Pincode"}
              value2={gst_data.pincode}
              onChange2={(e) => {
                e.target.value.length == 6 &&
                  checkPincode(
                    state_list.find(
                      (v) =>
                        v.label.toUpperCase() == gst_data?.state.toUpperCase()
                    )?.value,
                    e.target.value,
                    1
                  );
                let updatedValue = {};
                updatedValue = {
                  pincode: e.target.value.toString().substring(0, 6),
                };
                setGst_data((pre) => ({ ...pre, ...updatedValue }));
              }}
              right2={
                pin_loading == "1" && (
                  <Oval
                    height={15}
                    width={15}
                    color="#169e49"
                    wrapperStyle={{}}
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#fff"
                    strokeWidth={7}
                    strokeWidthSecondary={7}
                  />
                )
              }
            />

            {registeras == 1 && (
              <CustomInput
                placeholder1={"Select Principle product supplier"}
                label1={"Principle product supplier"}
                options1={service_list}
                multi1={true}
                onChange1={(v, i) => {
                  setSuplier_list(v);
                }}
                single
                drop1
                label2={"Produts"}
                items={
                  <div className="flex df">
                    <div className="flex">
                      <Check
                        tittle={"LPG"}
                        checked={products.lpg == 1 ? true : false}
                        onchange={() =>
                          setProducts({
                            ...products,
                            lpg: products.lpg == "" ? 1 : "",
                          })
                        }
                      />
                      <Check
                        tittle={"Industrial Gas"}
                        checked={products.industrial_gas == 2 ? true : false}
                        onchange={() =>
                          setProducts({
                            ...products,
                            industrial_gas:
                              products.industrial_gas == "" ? 2 : "",
                          })
                        }
                      />
                    </div>
                    <div className="flex">
                      <Check
                        tittle={"Allied products"}
                        checked={products.allied_products == 3 ? true : false}
                        onchange={() =>
                          setProducts({
                            ...products,
                            allied_products:
                              products.allied_products == "" ? 3 : "",
                          })
                        }
                      />
                      <Check
                        tittle={"Services"}
                        checked={products.services == 4 ? true : false}
                        onchange={() =>
                          setProducts({
                            ...products,
                            services: products.services == "" ? 4 : "",
                          })
                        }
                      />
                    </div>
                  </div>
                }
              />
            )}

            <CustomInput
              label1={"Personal Email ID"}
              placeholder1={"Enter Personal Email ID"}
              value1={reg_data.p_email}
              onChange1={(e) => {
                setReg_data({ ...reg_data, p_email: e.target.value });
              }}
              label2={"Business Email ID"}
              placeholder2={"Enter Business Email ID"}
              value2={reg_data.b_email}
              onChange2={(e) => {
                setReg_data({ ...reg_data, b_email: e.target.value });
              }}
            />
            <div className="regi_header mt10">Personal Address</div>
            <CustomInput
              label1={"Address"}
              placeholder1={"Enter Address"}
              value1={reg_data.p_address}
              onChange1={(e) => {
                setReg_data({ ...reg_data, p_address: e.target.value });
              }}
              area1
              label2={"State"}
              options2={state_list}
              drop2
              onChange2={(e) => {
                setReg_data({ ...reg_data, p_state: e.label });
                get_dist_list(e.value, setDist_list2);
              }}
            />
            <CustomInput
              label1={"District"}
              options1={dist_list2}
              disabled1={dist_list2.length == 0 ? true : false}
              onChange1={(e) => {
                setReg_data({ ...reg_data, p_district: e.label });
              }}
              drop1
              label2={"Pincode"}
              placeholder2={"Enter Pincode"}
              value2={reg_data.p_pincode}
              onChange2={(e) => {
                e.target.value.length == 6 &&
                  checkPincode(
                    state_list.find((v) => v.label == reg_data?.p_state)?.value,
                    e.target.value,
                    2
                  );
                setReg_data({
                  ...reg_data,
                  p_pincode: e.target.value.toString().substring(0, 6),
                });
              }}
              right2={
                pin_loading == "2" && (
                  <Oval
                    height={15}
                    width={15}
                    color="#169e49"
                    wrapperStyle={{}}
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#fff"
                    strokeWidth={7}
                    strokeWidthSecondary={7}
                  />
                )
              }
            />
            <div className="regi_header mt10">Business Address</div>
            <Check
              tittle={"Same as GST IN address "}
              onchange={() => setSame_address(!same_address)}
              checked={same_address}
            />
            {!same_address && (
              <>
                <CustomInput
                  label1={"Address"}
                  placeholder1={"Enter Address"}
                  area1
                  value1={reg_data.b_address}
                  onChange1={(e) => {
                    setReg_data({ ...reg_data, b_address: e.target.value });
                  }}
                  label2={"State"}
                  options2={state_list}
                  onChange2={(e) => {
                    setReg_data({ ...reg_data, b_state: e.label });
                    get_dist_list(e.value, setDist_list3);
                  }}
                  drop2
                />
                <CustomInput
                  label1={"District"}
                  disabled1={dist_list3.length == 0 ? true : false}
                  options1={dist_list3}
                  onChange1={(e) => {
                    setReg_data({ ...reg_data, b_district: e.label });
                  }}
                  drop1
                  label2={"Pincode"}
                  placeholder2={"Enter Pincode"}
                  value2={reg_data.b_pincode}
                  onChange2={(e) => {
                    e.target.value.length == 6 &&
                      checkPincode(
                        state_list.find((v) => v.label == reg_data?.b_state)
                          ?.value,
                        e.target.value,
                        3
                      );
                    setReg_data({
                      ...reg_data,
                      b_pincode: e.target.value.toString().substring(0, 6),
                    });
                  }}
                  right2={
                    pin_loading == "3" && (
                      <Oval
                        height={15}
                        width={15}
                        color="#169e49"
                        wrapperStyle={{}}
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#fff"
                        strokeWidth={7}
                        strokeWidthSecondary={7}
                      />
                    )
                  }
                />
              </>
            )}
            <CustomInput
              label1={"Website"}
              placeholder1={"Enter Website"}
              value1={reg_data.website}
              onChange1={(e) => {
                setReg_data({ ...reg_data, website: e.target.value });
              }}
              label2={"Password"}
              placeholder2={"Enter Password"}
              value2={reg_data.password}
              type2={showPass ? "text" : "password"}
              onChange2={(e) => {
                setReg_data({ ...reg_data, password: e.target.value });
              }}
              right2={
                showPass ? (
                  <BsEyeSlash
                    size={16}
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPass(!showPass)}
                  />
                ) : (
                  <BsEye
                    size={16}
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPass(!showPass)}
                  />
                )
              }
            />
            <CustomInput
              label1={"Personal Mobile Number"}
              placeholder1={"Enter Personal Mobile Number"}
              value1={reg_data.p_mobile_no}
              type1={"number"}
              onChange1={(e) => {
                // e.target.value.length == 10 && sendOtp(e.target.value, 1);
                setReg_data({
                  ...reg_data,
                  p_mobile_no: e.target.value.toString().substring(0, 10),
                });
              }}
              button1={
                <ButtonComp
                  tittle={"Get Otp"}
                  onClick={() => sendOtp(reg_data.p_mobile_no, 1)}
                  loading={otp_loading == 1 ? true : false}
                />
              }
              bottom1={
                show_otp1 && (
                  <div className={"otp_container"}>
                    <OtpInput
                      value={reg_data.otp1}
                      onChange={(e) => setReg_data({ ...reg_data, otp1: e })}
                      numInputs={4}
                      inputStyle={"inputStyle"}
                      separator={<span style={{ margin: 5 }}> </span>}
                    />
                  </div>
                )
              }
              label2={"Business Mobile Number"}
              placeholder2={"Enter Business Mobile Number"}
              value2={reg_data.b_mobile_no}
              type2={"number"}
              onChange2={(e) => {
                setReg_data({
                  ...reg_data,
                  b_mobile_no: e.target.value.toString().substring(0, 10),
                });
              }}
              button2={
                <ButtonComp
                  tittle={"Get Otp"}
                  onClick={() => sendOtp(reg_data.b_mobile_no, 2)}
                  loading={otp_loading == 2 ? true : false}
                />
              }
              bottom2={
                show_otp2 && (
                  <div className={"otp_container"}>
                    <OtpInput
                      value={reg_data.otp2}
                      onChange={(e) => setReg_data({ ...reg_data, otp2: e })}
                      numInputs={4}
                      inputStyle={"inputStyle"}
                      separator={<span style={{ margin: 5 }}> </span>}
                    />
                  </div>
                )
              }
            />
            <div className="df alc mt10">
              <Check checked={term} onchange={() => setTerm(!term)} />
              <div className="footer_div">
                <div className={"font1 mr10"}>by signing up, I accept </div>
                <div className="">
                  <Link
                    style={{ color: "#007BC9", fontWeight: "initial" }}
                    to={"/signup"}
                  >
                    Term & Condition
                  </Link>
                </div>
              </div>
            </div>
            <div className="df alc jcc">
              <ButtonComp
                tittle={"Register"}
                onClick={handle_signup}
                loading={loading}
                height={35}
                top={20}
              />
            </div>
            <div className={"df alc jcc mt20 gap10 font1"}>
              Already have an account ?
              <Link
                style={{ color: "#007BC9", fontWeight: "bold" }}
                to={"/login"}
              >
                Login
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Registration;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
const user_id = user?.userid;
const ingress = user?.access;

export const get_vehicle_list = createAsyncThunk("vehicle", async () => {
  const body = new FormData();
  body.append("userid", user_id);
  body.append("ingress", ingress);

  const response = await fetch(base_url + "vehiclelist", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  console.log("vehicle", res);
  return res;
});

const vehicleSlice = createSlice({
  initialState: {
    v_loading: false,
    vehicle_list: [],
  },
  name: "vehicle",
  extraReducers: (builder) => {
    builder.addCase(get_vehicle_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.v_loading = false;
      if (data.success) {
        state.vehicle_list = data.data;
      } else {
        state.vehicle_list = [];
      }
    });
    builder.addCase(get_vehicle_list.pending, (state, action) => {
      state.v_loading = true;
    });
  },
});

export default vehicleSlice.reducer;

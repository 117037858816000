import { toast } from "react-toastify";
import { get_new_order_list } from "../redux/slice/orderSlice";
import { set_profile_data } from "../redux/slice/userSlice";
import store from "../redux/store";
import { base_url } from "./utils";
import { get_new_indent_list } from "../redux/slice/indentSlice";

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
const user_id = user?.userid;
const ingress = user?.access;
export const stocktransfer_verify = async (
  id,
  action,
  otp = "",
  setloading
) => {
  try {
    setloading(action);
    const body = new FormData();
    body.append("userid", user_id);
    body.append("id", id);
    body.append("action", action);
    body.append("otp", otp);
    body.append("ingress", ingress);
    const response = await fetch(base_url + "stocktransfer-verify", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    setloading("");
    if (res.success) {
      toast.success(res.message);
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    setloading("");
  }
};

export const change_Password = async (pass, newpass, setloading) => {
  try {
    setloading(true);
    const body = new FormData();
    body.append("userid", user_id);
    body.append("password", pass);
    body.append("newpassword", newpass);
    body.append("ingress", ingress);
    const response = await fetch(base_url + "resetpassword", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    setloading(false);
    if (res.success) {
      toast.success(res.message);
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    setloading(false);
  }
};
export const vendor_signup = async (formdata, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "register", {
      body: formdata,
      method: "POST",
    });

    const res = await response.json();

    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      localStorage.setItem("user", JSON.stringify(res.data));
      get_vendor_profile(res.data.userid);
      return true;
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    setLoading(false);
  }
};
export const da_signup = async (formdata, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "dpregister", {
      body: formdata,
      method: "POST",
    });

    const res = await response.json();

    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      localStorage.setItem("user", JSON.stringify(res.data));
      get_da_profile(res.data.userid);
      return true;
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    setLoading(false);
  }
};

export const report_api = async (formdata, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "report", {
      body: formdata,
      method: "POST",
    });

    const res = await response.json();
    console.log("report ", res);
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const contactus_api = async (formdata, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "contactus", {
      body: formdata,
      method: "POST",
    });

    const res = await response.json();

    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    setLoading(false);
  }
};
export const get_p_data = async (u_id, access) => {
  try {
    const body = new FormData();
    body.append("userid", u_id);

    const response = await fetch(
      base_url + `${access == 1 ? "profile" : "profile_dp"}`,
      { body: body, method: "POST" }
    );

    const res = await response.json();
    // console.log("api", res);
    if (res.success) {
      if (res.data.profile.length > 0) {
        return res.data.profile[0];
      }
      return {};
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {}
};

export const send_token = async (token) => {
  try {
    const body = new FormData();
    body.append("userid", user_id);
    body.append("ingress", ingress);
    body.append("medium", "web");
    body.append("token", token);

    const response = await fetch(base_url + "updatetoken", {
      body: body,
      method: "POST",
    });

    const res = await response.json();
    // console.log("token", res, user_id);
    if (res.success) {
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {}
};

export const partner_signup = async (formdata, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "pilotregister", {
      body: formdata,
      method: "POST",
    });

    const res = await response.json();
    // console.log("si", res);
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      localStorage.setItem("user", JSON.stringify(res.data));
      get_partner_profile(res.data.userid);
      return true;
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    setLoading(false);
  }
};

export const get_partner_profile = async (id = user_id) => {
  try {
    const body = new FormData();
    body.append("userid", id);
    const response = await fetch(base_url + "profile_pilot", {
      body: body,
      method: "POST",
    });
    const res = await response.json();
    // console.log("p", res);
    if (res.success) {
      toast.success(res.message);
      store.dispatch(set_profile_data(res.data));
      // return res;
    } else {
      toast.error(res.message);
    }
  } catch (error) {}
};
export const get_vendor_profile = async (id = user_id) => {
  try {
    const body = new FormData();
    body.append("userid", id);
    const response = await fetch(base_url + "profile", {
      body: body,
      method: "POST",
    });
    const res = await response.json();
    // console.log("v", res);
    if (res.success) {
      toast.success(res.message);
      store.dispatch(set_profile_data([res.data]));
      // return true;
    } else {
      toast.error(res.message);
    }
  } catch (error) {}
};

export const get_da_profile = async (id = user_id) => {
  try {
    const body = new FormData();
    body.append("userid", id);
    const response = await fetch(base_url + "profile_dp", {
      body: body,
      method: "POST",
    });

    const res = await response.json();
    // console.log("da", res);
    if (res.success) {
      toast.success(res.message);
      store.dispatch(set_profile_data([res.data]));
      // return true;
    } else {
      toast.error(res.message);
    }
  } catch (error) {}
};

export const update_partner_profile = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "pilotprofileupdate", {
      body: body,
      method: "POST",
    });

    const res = await response.json();

    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      get_partner_profile();
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const order_action = async (body, setLoading, id) => {
  try {
    setLoading(id);
    const response = await fetch(base_url + "update-order-status", {
      body: body,
      method: "POST",
    });
    const res = await response.json();
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      store.dispatch(get_new_order_list());
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    setLoading(false);
  }
};
export const pilot_order_action = async (body, setLoading, id) => {
  try {
    setLoading(id);
    const response = await fetch(base_url + "pilot-accept-orders", {
      body: body,
      method: "POST",
    });
    const res = await response.json();
    console.log("action", res);
    setLoading(false);
    if (res.success) {
      toast.success(res.message);

      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    setLoading(false);
  }
};
export const indent_action = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "indent-update", {
      body: body,
      method: "POST",
    });
    const res = await response.json();
    // console.log("indent-update", res);
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      store.dispatch(get_new_indent_list());
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const get_referal_code = async (id, ing, setLoading) => {
  try {
    setLoading(true);
    const body = new FormData();
    body.append("userid", id);
    body.append("ingress", ing);
    const response = await fetch(base_url + "referral", {
      body: body,
      method: "POST",
    });
    const res = await response.json();
    // console.log("referral", res);
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
export const services_action = async (id, ing, ids, setLoading) => {
  try {
    setLoading(true);
    const body = new FormData();
    body.append("userid", id);
    body.append("ingress", ing);
    body.append("services", ids);
    const response = await fetch(base_url + "create-service", {
      body: body,
      method: "POST",
    });
    const res = await response.json();
    // console.log("referral", res);
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};

export const create_inventory = async (body, setLoading) => {
  try {
    setLoading(true);

    const response = await fetch(base_url + "createinventory", {
      body: body,
      method: "POST",
    });
    const res = await response.json();
    console.log("createinventory", res);
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};
export const get_pilot_order_detail = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "pilot-orderdetails", {
      body: body,
      method: "POST",
    });
    const res = await response.json();
    console.log("pilot-orderdetails", res);
    setLoading(false);
    return res;
  } catch (error) {
    setLoading(false);
  }
};

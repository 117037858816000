import React from "react";

const Add_Doc = ({
  onChange,
  title,
  filename,
  single,
  placeholder,
  right,
  top,
  value,
  onChange1,
}) => {
  const inputOpenFileRef = React.createRef();
  const showOpenFileDlg = () => {
    inputOpenFileRef.current.click();
  };
  return (
    <div className="flex input-df gap20" style={{ marginTop: top }}>
      <div className="flex gap10">
        {title && <div className="label mt10">{title}</div>}
        {placeholder && (
          <div className="custom-input-style">
            <input
              type="text"
              value={value}
              onChange={onChange1}
              className="input-title input flex"
              placeholder={placeholder}
            />
          </div>
        )}
        <div
          className="df flex alc gap10 "
          style={{
            marginTop: placeholder ? 10 : 0,
          }}
        >
          <div className="border flex ">
            <div className="flex input-text p5 ph">
              {filename || "Choose file"}
            </div>
            <input
              ref={inputOpenFileRef}
              type="file"
              onChange={onChange}
              style={{ display: "none" }}
            />
            <div className="choose-button" onClick={showOpenFileDlg}>
              {"Browse"}
            </div>
          </div>
          {right}
        </div>
      </div>
      {single && <div style={{ flex: 1 }}></div>}
    </div>
  );
};

export default Add_Doc;

import { toast } from "react-toastify";
import { base_url } from "../../utils/utils";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
const user_id = user?.userid;
const ingress = user?.access;
export const getall_list = createAsyncThunk("list", async (id = "") => {
  const body = new FormData();
  body.append("stateid", id);
  const response = await fetch(base_url + "completelist", {
    method: "POST",
    body: body,
  });
  const res = await response.json();

  if (res.success) {
    return res;
  } else {
    toast.error(res.message);
  }
});

export const get_vendorproducts_list = createAsyncThunk(
  "vendorproducts",
  async () => {
    const body = new FormData();
    body.append("userid", user_id);
    body.append("ingress", ingress);
    const response = await fetch(base_url + "vendorproducts", {
      method: "POST",
      body: body,
    });
    const res = await response.json();

    return res;
  }
);

export const get_profile = createAsyncThunk("profile", async () => {
  const body = new FormData();
  body.append("userid", user_id);
  const response = await fetch(
    base_url + `${ingress == 1 ? "profile" : "profile_dp"}`,
    {
      method: "POST",
      body: body,
    }
  );
  const res = await response.json();
  // console.log("profile data", res);
  return res;
});

export const get_documents = createAsyncThunk("document", async () => {
  const body = new FormData();
  body.append("userid", user_id);
  body.append("action", 3);
  const response = await fetch(base_url + "document", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  // console.log("document==>", res);
  return res;
});
export const get_services_data = createAsyncThunk("services", async () => {
  const body = new FormData();
  body.append("userid", user_id);
  body.append("ingress", ingress);
  const response = await fetch(base_url + "services-list", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  // console.log("services-list==>", res);
  return res;
});

const UserSlice = createSlice({
  name: "user",
  initialState: {
    data: [],
    profile_data: {},
    state_list: [],
    service_list: [],
    lpg_services: [],
    other_services: [],
    vendorProducts: [],
    bank_acc_list: [],
    documents_list: [],
    check_net: false,
    isLoading: false,
    services_data: "",
    services_loading: false,
    active_tab: "1",
  },
  reducers: {
    check_net_info: (state, action) => {
      state.check_net = action.payload;
    },
    set_data: (state, action) => {
      state.profile_data = action.payload;
    },
    set_profile_data: (state, action) => {
      state.data = action.payload;
    },
    remove_profile_data: (state, action) => {
      state.data = [];
    },
    setActive_Tab: (state, action) => {
      state.active_tab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getall_list.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getall_list.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getall_list.fulfilled, (state, action) => {
      const res = action.payload;
      state.isLoading = false;
      state.state_list = [];
      const data = [];
      for (let i = 0; i < res.data.reponselist.states.length; i++) {
        const element = {
          value: res.data.reponselist.states[i].code,
          label: res.data.reponselist.states[i].name,
        };
        data.push(element);
        state.state_list = data;
      }
      const data1 = [];
      for (let i = 0; i < res.data.reponselist.services.length; i++) {
        const element = {
          value: res.data.reponselist.services[i].id,
          label: res.data.reponselist.services[i].name,
        };
        data1.push(element);
        state.service_list = data1;
      }
      state.lpg_services = res.data.reponselist.lpgservices;
      state.other_services = res.data.reponselist.otherservices;
    });

    builder.addCase(get_vendorproducts_list.fulfilled, (state, action) => {
      const res = action.payload;
      state.isLoading = false;
      state.vendorProducts = [];
      const data = [];
      for (let i = 0; i < res.data.length; i++) {
        const element = {
          value: res.data[i].uuid,
          label:
            res.data[i].description + "  " + "(" + `${res.data[i].type}` + ")",
        };
        data.push(element);
        state.vendorProducts = data;
      }
    });

    builder.addCase(get_profile.fulfilled, (state, action) => {
      const data = action.payload;
      state.isLoading = false;
      if (data.success) {
        // state.profile_data =
        // data.data?.profile?.length == 0 ? {} : data.data.profile[0];
        state.profile_data = data.data.profile[0];
        state.bank_acc_list = data.data.bankdetails;
      } else {
        state.profile_data = {};
      }
    });

    builder.addCase(get_documents.fulfilled, (state, action) => {
      const data = action.payload;
      state.isLoading = false;
      if (data.success) {
        state.documents_list = data.data;
      } else {
        state.documents_list = [];
      }
    });

    // loading

    builder.addCase(get_documents.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(get_vendorproducts_list.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(get_services_data.pending, (state, action) => {
      state.services_loading = true;
    });
    builder.addCase(get_services_data.rejected, (state, action) => {
      state.services_loading = false;
    });
    builder.addCase(get_services_data.fulfilled, (state, action) => {
      const res = action.payload;
      state.services_loading = false;
      if (res.success) {
        state.services_data = res.data[0].services.split(",");
      } else {
        state.services_data = {};
      }
    });
  },
});

export const {
  check_net_info,
  set_data,
  set_profile_data,
  remove_profile_data,
  setActive_Tab,
} = UserSlice.actions;
export default UserSlice.reducer;

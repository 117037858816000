import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
const user_id = user?.userid;
const ingress = user?.access;

export const get_inventory_list = createAsyncThunk("inventory", async () => {
  const body = new FormData();
  body.append("userid", user_id);
  body.append("ingress", ingress);
  const response = await fetch(base_url + "inventory_sales", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  // console.log(res);
  return res;
});

const inventorySlice = createSlice({
  initialState: {
    inventory_list: [],
    i_loading: false,
  },
  name: "godown",
  extraReducers: (builder) => {
    builder.addCase(get_inventory_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.i_loading = false;
      if (data.success) {
        state.inventory_list = data.data;
      } else {
        state.inventory_list = [];
      }
    });
    builder.addCase(get_inventory_list.pending, (state, action) => {
      state.i_loading = true;
    });
  },
});

export default inventorySlice.reducer;

import React, { useState } from "react";
import style from "./styles.module.scss";
import { Oval, TailSpin } from "react-loader-spinner";

const ButtonComp = ({
  tittle,
  icon,
  onClick,
  loading,
  b_color,
  top,
  height,
}) => {
  return (
    <button
      onClick={onClick}
      className={style.button_style}
      disabled={loading ? true : false}
      style={{
        backgroundColor: b_color || "#169e49",
        marginTop: top,
        height: height || "30px",
        alignSelf: "center",
      }}
    >
      {loading || icon}
      {loading ? (
        <Oval
          height={20}
          width={20}
          color="#fff"
          wrapperStyle={{}}
          wrapperClass={style.loader}
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#fff"
          strokeWidth={5}
          strokeWidthSecondary={5}
        />
      ) : (
        tittle
      )}
    </button>
  );
};

export default ButtonComp;

import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import ButtonComp from "../../components/ButtonComp";
import Add_Doc from "../../components/custom/Add_doc";
import { document_action } from "../../utils/apis";
import { user_id } from "../../utils/utils";

const Add_v_Documents = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [docList, setDocList] = useState([
    { title: "Supply Agreement (DSC signed initially)", docData: {} },
    { title: "Company/Firm Registration", docData: {} },
    { title: "Principle Supplier Agreement", docData: {} },
    {
      title: "PAN Copy (Firm & Partners)/Diorector/Administrators",
      docData: {},
    },
    {
      title:
        "Address Proof of Proprietor/All Partners/All Directors/Administrators",
      docData: {},
    },
    { title: "Authorisation letter other than proprietary firms", docData: {} },
    { title: "GST Registration", docData: {} },
    { title: "Service Agreement", docData: {} },
    { title: "Signature", docData: {} },
    { title: "Stamp", docData: {} },
  ]);
  const imp = ["Supply Agreement (DSC signed initially)", "GST Registration"];
  const filter_data = docList.filter((v) => !location.state?.includes(v.title));
  const filter_docList = docList.filter((v, i) => v.docData.name && v);

  const add_doc = () => {
    if (imp.filter((v) => location.state.includes(v)).length == 1) {
      if (filter_docList.filter((v) => imp.includes(v.title)).length != 1) {
        toast.info("Please select * marked document");
        return;
      }
    } else {
      if (imp.filter((v) => location.state.includes(v)).length != 2) {
        if (filter_docList.filter((v) => imp.includes(v.title)).length !== 2) {
          toast.info("Please select * marked document");
          return;
        }
      }
    }

    if (filter_docList.length === 0) {
      toast.info("Please select document");
      return;
    }
    const formdata = new FormData();
    formdata.append("userid", user_id);
    formdata.append("action", "1");
    formdata.append("documentcount", filter_docList.length);
    formdata.append("names", filter_docList.map((v) => v.title).join(",,,"));
    {
      filter_docList.forEach((v, i) =>
        formdata.append(`${"document" + i}`, v.docData)
      );
    }
    document_action(formdata, setLoading).then((data) => data && navigate(-1));
  };

  return (
    <>
      <div className={"df alc sb"}>
        <h3>Add Documents</h3>
        <ButtonComp
          icon={<BiArrowBack size={16} />}
          tittle={"Back"}
          b_color={"#000"}
          onClick={() => navigate(-1)}
        />
      </div>
      <div className={"card mt10 padding-xy"}>
        {filter_data.map((v, i) => (
          <Add_Doc
            key={i}
            title={
              <div>
                {v.title}
                {v.title == "Supply Agreement (DSC signed initially)" && (
                  <span> *</span>
                )}
                {v.title == "GST Registration" && <span> *</span>}
              </div>
            }
            onChange={(e) =>
              setDocList((pre) =>
                pre.map((val) =>
                  val.title == v.title
                    ? { ...val, docData: e.target.files[0] }
                    : { ...val }
                )
              )
            }
            filename={v.docData.name}
            right={
              v.docData?.name && (
                <ButtonComp
                  tittle={"Delete"}
                  b_color={"#f00"}
                  onClick={() =>
                    setDocList((pre) =>
                      pre.map((val) =>
                        val.title == v.title
                          ? { ...val, docData: {} }
                          : { ...val }
                      )
                    )
                  }
                />
              )
            }
          />
        ))}
        <div className="df jcc mt10">
          <ButtonComp tittle={"Upload"} onClick={add_doc} loading={loading} />
        </div>
      </div>
    </>
  );
};

export default Add_v_Documents;

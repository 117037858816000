import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ButtonComp from "../../components/ButtonComp";
import { Loader, ingress, user_id } from "../../utils/utils";
import store from "../../redux/store";
import { get_services_data } from "../../redux/slice/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { services_action } from "../../utils/api";
import { toast } from "react-toastify";

const Services = () => {
  const {
    lpg_services,
    other_services,
    isLoading,
    services_data,
    services_loading,
  } = useSelector((state) => state.user);

  const [type, setType] = useState("lpg");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setData(services_data);
    store
      .dispatch(get_services_data())
      .then(unwrapResult)
      .then((v) => {
        v.success && setData(v.data[0].services.split(","));
        console.log(v.data[0].services.split(","));
      });
  }, []);

  const update = () => {
    services_action(user_id, ingress, data.toString(), setLoading).then((v) => {
      if (v.success) {
        toast.success(v.message);
        store
          .dispatch(get_services_data())
          .then(unwrapResult)
          .then((v) => v.success && setData(v.data[0].services.split(",")));
      } else {
        toast.error(v.message);
      }
    });
  };
  return (
    <>
      <div className="card p20">
        <div className="heading-text">Type of Service</div>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={type == "lpg" ? true : false}
                onChange={() => setType("lpg")}
              />
            }
            label="LPG"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={type == "other" ? true : false}
                onChange={() => setType("other")}
              />
            }
            label="Other Industrial gases"
          />
        </FormGroup>
      </div>
      {services_loading || data.length == 0 ? (
        <div className="card p20 alc jcc df">
          <Loader />
        </div>
      ) : (
        <div className="card p20 mt20">
          <div className="heading-text">Select Services</div>
          <FormGroup>
            {type == "lpg" &&
              lpg_services.map((v, i) => (
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      // value={data.find((el) => (el == v.id ? "on" : "off"))}
                      checked={data?.find((el) => el == v.id)}
                      onChange={() =>
                        setData((pre) =>
                          pre.find((el) => el == v.id)
                            ? pre.filter((el) => el != v.id)
                            : [...pre, v.id]
                        )
                      }
                    />
                  }
                  label={v.name}
                />
              ))}
            {type == "other" &&
              other_services.map((v, i) => (
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      checked={data?.find((el) => el == v.id)}
                      onChange={() =>
                        setData((pre) =>
                          pre.find((el) => el == v.id)
                            ? pre.filter((el) => el != v.id)
                            : [...pre, v.id]
                        )
                      }
                    />
                  }
                  label={v.name}
                />
              ))}
          </FormGroup>
          <div className="df alc jcc">
            <ButtonComp tittle={"Update"} loading={loading} onClick={update} />
          </div>
        </div>
      )}
    </>
  );
};

export default Services;

import { Dialog } from "@mui/material";
import React, { useState } from "react";
import { GrClose } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonComp from "../../components/ButtonComp";
import CustomInput from "../../components/custom/CustomInput";
import { get_all_products } from "../../redux/slice/productSlice";
import { base_url, user_id } from "../../utils/utils";

const LpgIndustrial = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, service_list } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [suplier_list, setSuplier_list] = useState(
    service_list.filter((v) =>
      data[0]?.profile[0]?.supplier_text?.split(",").includes(v.label)
    )
  );

  const handleClose = () => setOpen(false);
  const add_products = async () => {
    if (suplier_list.length == 0) {
      toast.error("Please select product");
      return;
    }
    try {
      const body = new FormData();
      body.append("userid", user_id);
      body.append("action", 2);
      body.append("products", suplier_list.map((v) => v.value).toString());
      setLoading(true);
      const response = await fetch(base_url + "update-products", {
        method: "POST",
        body: body,
      });
      const res = await response.json();
      console.log(res);
      setLoading(false);
      if (res.success) {
        // setAllProducts(res.data);
        dispatch(get_all_products());
        toast.success(res.message);
        handleClose();
        // navigate(-1);
      } else {
        //  toast.error("added successfully");
        toast.error(res.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth={"md"} style={{}}>
      <div className="db flex ph20 mt20">
        <div className="df alc sb ">
          <div className="title-text">LPG Product</div>

          <GrClose
            onClick={() => handleClose()}
            size={20}
            className={"cursor"}
          />
        </div>
        <div className="ph10 mb20 flex">
          <CustomInput
            label1={"LPG Produts"}
            value1={suplier_list}
            drop1
            onChange1={(v, i) => {
              setSuplier_list(v);
            }}
            multi1
            menuPlacement1={"bottom"}
            options1={service_list}
            single
          />

          <div className="df jcc mt20">
            <ButtonComp
              tittle={"Update"}
              loading={loading}
              onClick={() => add_products()}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default LpgIndustrial;

import React, { useEffect, useState } from "react";
import ButtonComp from "../../components/ButtonComp";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomInput from "../../components/custom/CustomInput";
import { BiArrowBack } from "react-icons/bi";
import "./inventory.scss";
import { get_vendorproducts_list } from "../../redux/slice/userSlice";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { base_url, ingress, isSmallScreen, user_id } from "../../utils/utils";
import { toast } from "react-toastify";
import { IoTrashOutline } from "react-icons/io5";

const AddInventory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { vendorProducts } = useSelector((state) => state.user);
  const [p_list, setP_list] = useState([]);
  const [selected_product, setSelected_Product] = useState([]);
  const [show, setShow] = useState(true);
  const [inv, setInv] = useState({
    id: "",
    status: "1",
    type: "1",
    loading: false,
    v_no: "",
  });
  const [v_inv, setV_inv] = useState({
    d_id: "",
    v_no: "",
    loading: false,
    // status: "1",
  });
  const filter_data = selected_product.filter((v) => v?.qty);

  useEffect(() => {
    dispatch(get_vendorproducts_list());
  }, []);

  const create_inventory = async () => {
    if (Object.keys(inv.id).length == 0) {
      toast.info("Please Enter uuid");
      return;
    }
    if (filter_data.length == 0) {
      toast.info("Please select a product");
      return;
    }
    try {
      setInv({ ...inv, loading: true });
      const body = new FormData();
      body.append("userid", user_id);
      body.append("productid", filter_data.map((v) => v.value).toString());
      body.append("qty", filter_data.map((v) => v.qty).toString());
      body.append("pilotid", inv.id);
      body.append("status", inv.status);
      body.append("type", inv.type);
      body.append("vehicleno", inv.v_no);
      const response = await fetch(base_url + "createpilotinventory", {
        method: "POST",
        body: body,
      });
      const res = await response.json();
      // console.log("inven", res);
      setInv({ ...inv, loading: false });
      if (res.success) {
        toast.success(res.message);
        navigate(-1);
      } else {
        toast.error(res.message);
      }
    } catch (error) {}
    setInv({ ...inv, loading: false });
  };
  const create_v_inventory = async () => {
    if (
      Object.keys(v_inv.d_id).length == 0 ||
      Object.keys(v_inv.v_no).length == 0
    ) {
      toast.info("Please fill all fields");
      return;
    }
    if (filter_data.length == 0) {
      toast.info("Please select a product");
      return;
    }
    try {
      setInv({ ...inv, loading: true });
      const body = new FormData();
      body.append("userid", user_id);
      body.append("productid", filter_data.map((v) => v.value).toString());
      body.append("qty", filter_data.map((v) => v.qty).toString());
      body.append("pilotid", v_inv.d_id);
      body.append("status", 2);
      // body.append("type", inv.type);
      body.append("ingress", ingress);
      body.append("vehicleno", v_inv.v_no);
      const response = await fetch(base_url + "createinventory", {
        method: "POST",
        body: body,
      });
      const res = await response.json();
      console.log("inven", res);
      setInv({ ...inv, loading: false });
      if (res.success) {
        toast.success(res.message);
        navigate(-1);
      } else {
        toast.error(res.message);
      }
    } catch (error) {}
    setInv({ ...inv, loading: false });
  };
  return (
    <>
      <div className="card padding-xy mb10 df alc sb">
        <div className="heading-text">Add Inventory</div>
        <ButtonComp
          b_color={"black"}
          icon={<BiArrowBack color="#fff" size={16} />}
          onClick={() => navigate(-1)}
          tittle={"Back"}
        />
      </div>
      <div className={"card p20 "}>
        {ingress == 4 && (
          <RadioGroup
            row
            sx={{ gap: isSmallScreen ? 1 : 10 }}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={inv.status}
            onChange={(v) => setInv({ ...inv, status: v.target.value })}
          >
            <FormControlLabel
              value="1"
              control={<Radio color="success" />}
              label="Filled"
            />
            <FormControlLabel
              value="2"
              control={<Radio color="success" />}
              label="Empty"
            />
          </RadioGroup>
        )}
        {/* {ingress == 4 || (
          <RadioGroup
            row
            sx={{ gap: isSmallScreen ? 1 : 10 }}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={v_inv.status}
            onChange={(v) => setV_inv({ ...v_inv, status: v.target.value })}
          >
            <FormControlLabel
              value="1"
              control={<Radio color="success" />}
              label="IN"
            />
            <FormControlLabel
              value="2"
              control={<Radio color="success" />}
              label="OUT"
            />
          </RadioGroup>
        )} */}
        {ingress == 4 && (
          <RadioGroup
            row
            sx={{ gap: isSmallScreen ? 1.8 : 10.8 }}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={inv.type}
            onChange={(v) => setInv({ ...inv, type: v.target.value })}
          >
            <FormControlLabel
              value="1"
              control={<Radio color="success" />}
              label="Pilot"
            />
            <FormControlLabel
              value="2"
              control={<Radio color="success" />}
              label="Sales Person"
            />
          </RadioGroup>
        )}
        {ingress == 4 && (
          <CustomInput
            label1={"Uuid"}
            placeholder1={"Enter Uuid"}
            value1={inv.id}
            onChange1={(e) => setInv({ ...inv, id: e.target.value })}
            label2={"Vehicle Number"}
            placeholder2={"Enter vehicle number"}
            value2={inv.v_no}
            onChange2={(e) => setInv({ ...inv, v_no: e.target.value })}
          />
        )}
        {ingress == 4 || (
          <CustomInput
            label1={"Driver id"}
            placeholder1={"Enter Driver id"}
            value1={v_inv.d_id}
            onChange1={(e) => setV_inv({ ...v_inv, d_id: e.target.value })}
            label2={"Vehicle Number"}
            placeholder2={"Enter vehicle number"}
            value2={v_inv.v_no}
            onChange2={(e) => setV_inv({ ...v_inv, v_no: e.target.value })}
          />
        )}

        <div style={{ height: 10 }} />
        <div className={"df alc sb"}>
          <p>Products</p>
          {/* <ButtonComp onClick={() => setShow(true)} tittle={"Add"} /> */}
        </div>
        {selected_product.length == 0 || (
          <div className="df alc jcc mt10 " style={{ maxWidth: "70%" }}>
            <table className="inv_table">
              <tr className="inv_tr">
                <th className="inv_td">S.No.</th>
                <th className="inv_td">Name</th>
                <th className="inv_td">Quantity</th>
                {/* <th className="inv_td">Action</th> */}
              </tr>
              {selected_product?.map((v, i) => {
                return (
                  <tr className="inv_tr" key={i}>
                    <td className="inv_td">{i + 1}</td>
                    <td className="inv_td"> {v.label}</td>
                    <td className="inv_td">
                      <input
                        className="text_bg"
                        value={v?.qty || ""}
                        placeholder="Enter Quntity"
                        onChange={(val) => {
                          setSelected_Product((pre) =>
                            pre.find((item, ind) => i === ind)
                              ? pre.map((el, index) =>
                                  i === index
                                    ? {
                                        ...el,
                                        qty: val.target.value.replace(
                                          /\D/g,
                                          ""
                                        ),
                                      }
                                    : el
                                )
                              : [...pre]
                          );
                        }}
                      />
                    </td>
                    {/* <td className="inv_td">
                      <IoTrashOutline
                        color="red"
                        size={20}
                        className="cursor"
                        onClick={() =>
                          setSelected_Product((pre) =>
                            pre.find((item, ind) => i === ind)
                              ? pre.filter((el, index) => i !== index)
                              : [...pre]
                          )
                        }
                      />
                    </td> */}
                  </tr>
                );
              })}
            </table>
          </div>
        )}
        {show && (
          <CustomInput
            label1={"Products list"}
            drop1
            options1={vendorProducts}
            single
            multi1
            value1={p_list}
            menuPlacement1={"auto"}
            // value1={vendorProducts.filter((v) => entries.includes(v.value))}
            onChange1={(v) => {
              setP_list(v);
              setSelected_Product(v);
              // setShow(false);
            }}
          />
        )}
        <div className="df alc jcc mt20">
          <ButtonComp
            tittle={"Submit"}
            loading={inv.loading}
            onClick={() =>
              ingress == 4 ? create_inventory() : create_v_inventory()
            }
          />
        </div>
      </div>
    </>
  );
};

export default AddInventory;

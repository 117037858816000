import { useState, useEffect, useCallback } from "react";
import { useNavigate, Route, Routes, Outlet } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import Home from "./pages/home/Home";
import Orders from "./pages/orders/Order";
import Inventory from "./pages/inventory/Inventory";
import AddProducts from "./pages/products/AddProducts";
import Products from "./pages/products/Products";
import Vehicle from "./pages/vehicle/Vehicle";
import Driver from "./pages/driver/Driver";
import DriverDetail from "./pages/driver/DriverDetail";
import AddVehicle from "./pages/vehicle/AddVehicle";
import VehicleDetail from "./pages/vehicle/VehicleDetail";
import Quotation from "./pages/quatation/Quotation";
import Services from "./pages/services/Services";
import Login from "./pages/auth/Login";
import "react-toastify/dist/ReactToastify.css";
import Settings from "./pages/p_profile/Settings";
import NotificationPage from "./pages/notification/NotificationPage";
import "./App.css";
import Allotment from "./pages/allotment/Allotment";
import Godown from "./pages/godown/Godown";
import AddGodown from "./pages/godown/AddGodown";
import GodownDetail from "./pages/godown/GodownDetail";
import Registration from "./pages/auth/Registration";
import ProtectedRoute from "./ProtectedRoute";
import AddDriver from "./pages/driver/AddDriver";
import { useDispatch, useSelector } from "react-redux";
import { getall_list, get_profile } from "./redux/slice/userSlice";
import AddInventory from "./pages/inventory/AddInventory";
import NotificationDetail from "./pages/notification/NotificationDetail";
import AddAllotments from "./pages/allotment/AddAllotments";
import { ingress, Page_not_found } from "./utils/utils";
import { onMessageListener, requestPermission } from "./firebase";
import { NotificationManager } from "react-notifications";
import ProfileInfo from "./pages/p_profile/ProfileInfo";
import PartnerDoc from "./pages/p_profile/PartnerDoc";
import P_BankInfo from "./pages/p_profile/P_BankInfo";
import Add_P_Bank from "./pages/p_profile/Add_P_Bank";
import Profile from "./pages/p_profile/Profile";
import V_DA_Profile from "./pages/p_profile/V_DA_Profile";
import Add_v_Documents from "./pages/p_profile/Add_v_Documents";
import ChangePassword from "./pages/p_profile/ChangePassword";
import Payments from "./pages/p_profile/Payments";
import Add_partner_Doc from "./pages/p_profile/Add_partner_Doc";
import Indents from "./pages/indents";
import OrderDetail from "./pages/indents/OrderDetail";
import StockTransfer from "./pages/indents/StockTransfer";
import OrderDetail1 from "./pages/orders/OrderDetail1";
import Referral from "./pages/p_profile/Referral";
import PilotOrder from "./pages/pilotOrders";
import OrderDetail2 from "./pages/pilotOrders/OrderDetail2";

function App() {
  const dispatch = useDispatch();
  const [notification, setNotification] = useState({ title: "", body: "" });
  useEffect(() => {
    requestPermission();
    dispatch(getall_list());
    dispatch(get_profile());
  }, []);
  onMessageListener()
    .then((payload) => {
      // console.log(payload);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      NotificationManager.success(
        payload.notification.body,
        payload.notification.title
      );
    })
    .catch((err) => console.log("failed: ", err));

  // Online state
  // const [isOnline, setIsOnline] = useState(navigator.onLine);
  // // console.log("check_net", check_net);
  // useEffect(() => {
  //   // Update network status
  //   const handleStatusChange = () => {
  //     setIsOnline(navigator.onLine);
  //     dispatch(check_net_info(navigator.onLine));
  //   };

  //   // Listen to the online status
  //   window.addEventListener("online", handleStatusChange);

  //   // Listen to the offline status
  //   window.addEventListener("offline", handleStatusChange);

  //   // Specify how to clean up after this effect for performance improvment
  //   return () => {
  //     window.removeEventListener("online", handleStatusChange);
  //     window.removeEventListener("offline", handleStatusChange);
  //   };
  // }, [isOnline]);

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Sidebar />}>
          <Route index element={<Home />} />
          {ingress == 1 && (
            <Route path="orders" element={<Outlet />}>
              <Route index element={<Orders />} />
              <Route path="order-detail" element={<OrderDetail1 />} />
            </Route>
          )}
          {ingress == 1 && (
            <Route path="indents" element={<Outlet />}>
              <Route index element={<Indents />} />
              <Route path="order-detail" element={<OrderDetail />} />
              <Route path="stock-transfer" element={<StockTransfer />} />
            </Route>
          )}
          {ingress == 4 && (
            <Route path="orders" element={<Outlet />}>
              <Route index element={<PilotOrder />} />
              <Route path="order-detail" element={<OrderDetail2 />} />
            </Route>
          )}

          {(ingress == 1 || ingress == 4) && (
            <Route path="inventory" element={<Outlet />}>
              <Route index element={<Inventory />} />
              <Route path="add-inventory" element={<AddInventory />} />
            </Route>
          )}

          {ingress == 1 && (
            <Route path="products" element={<Outlet />}>
              <Route index element={<Products />} />
              <Route path="add-product" element={<AddProducts />} />
            </Route>
          )}
          {ingress == 4 || (
            <Route path="allotment" element={<Outlet />}>
              <Route index element={<Allotment />} />
              <Route path="add-allotment" element={<AddAllotments />} />
            </Route>
          )}
          <Route path="notifications" element={<NotificationPage />} />
          <Route path="notificationdetail" element={<NotificationDetail />} />
          <Route path="notifications" element={<Outlet />}>
            <Route index element={<NotificationPage />} />
            <Route
              path="notification-detail"
              element={<NotificationDetail />}
            />
          </Route>
          {ingress == 1 && (
            <Route path="godowns" element={<Outlet />}>
              <Route index element={<Godown />} />
              <Route path="add-godown" element={<AddGodown />} />
              <Route path="godown-detail/:id" element={<GodownDetail />} />
            </Route>
          )}
          {ingress == 4 || (
            <Route path="driver" element={<Outlet />}>
              <Route index element={<Driver />} />
              <Route path="add-driver" element={<AddDriver />} />
              <Route path="driver-detail" element={<DriverDetail />} />
            </Route>
          )}
          {ingress == 4 || (
            <Route path="vehicle" element={<Outlet />}>
              <Route index element={<Vehicle />} />
              <Route path="add-vehicle" element={<AddVehicle />} />
              <Route path="vehicle-detail" element={<VehicleDetail />} />
            </Route>
          )}

          <Route path="/profile" element={<Profile />}>
            {ingress == 4 ? (
              <Route path="/profile/information" element={<ProfileInfo />} />
            ) : (
              <Route path="/profile/information" element={<V_DA_Profile />} />
            )}
            <Route path="/profile/bank-information" element={<P_BankInfo />} />
            <Route path="/profile/add-bank-account" element={<Add_P_Bank />} />
            {ingress == 1 && (
              <Route path="/profile/setting" element={<Settings />} />
            )}
            <Route
              path="/profile/documents-information"
              element={<PartnerDoc />}
            />
            <Route
              path="/profile/change-password"
              element={<ChangePassword />}
            />
            <Route path="/profile/payment-information" element={<Payments />} />
            {ingress == 4 ? (
              <Route
                path="/profile/add-documnets"
                element={<Add_partner_Doc />}
              />
            ) : (
              <Route
                path="/profile/add-documnets"
                element={<Add_v_Documents />}
              />
            )}
            <Route path="/profile/referral" element={<Referral />} />
          </Route>
          {ingress == 1 && <Route path="quotation" element={<Quotation />} />}
          {ingress == 1 && <Route path="services" element={<Services />} />}
          <Route path="/*" element={<Page_not_found />} />
        </Route>
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Registration />} />
      <Route path="*" element={<Page_not_found />} />
    </Routes>
  );
}

export default App;

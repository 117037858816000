import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonComp from "../../components/ButtonComp";
import { Loader, No_data, user_id } from "../../utils/utils";
import { Popup } from "../../utils/DialogBox";
import { toast } from "react-toastify";
import { indent_action } from "../../utils/api";
import SingleInput from "../../components/custom/SingleInput";
import { Card, Typography } from "@mui/material";
import { get_new_indent_list } from "../../redux/slice/indentSlice";

const New = () => {
  const { new_indents_list, n_indent_loading } = useSelector(
    (state) => state.indents
  );
  const dispatch = useDispatch();
  const [accept, setAccept] = useState(false);
  const [reject, setReject] = useState(false);
  const [id, setId] = useState("");
  const [qty, setQty] = useState("");
  const [mess, setMess] = useState("");
  const [loading, setLoading] = useState("");
  const [view, setView] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    dispatch(get_new_indent_list());
  }, []);
  const acceptOrder = () => {
    const body = new FormData();
    body.append("userid", user_id);
    body.append("action", 1);
    body.append("id", new_indents_list[id].id);
    body.append("message", "");
    body.append("acceptqty", qty);
    indent_action(body, setLoading).then((v) => {
      v && setAccept(false);
      v && setQty("");
    });
  };
  const rejectOrder = () => {
    if (!mess) {
      toast.info("Please enter a message");
      return;
    }
    const body = new FormData();
    body.append("userid", user_id);
    body.append("action", 2);
    body.append("id", new_indents_list[id].id);
    body.append("message", mess);
    body.append("acceptqty", "");
    indent_action(body, setLoading, id).then((v) => {
      v && setReject(false);
      v && setMess("");
    });
  };
  return (
    <div>
      {new_indents_list.length == 0 && n_indent_loading ? (
        <div className="card p20 df alc jcc">
          <Loader />
        </div>
      ) : new_indents_list.length == 0 ? (
        <No_data />
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: window.matchMedia("(max-width: 500px)").matches
              ? "repeat(1, 1fr)"
              : window.matchMedia("(max-width: 900px)").matches
              ? "repeat(2, 1fr)"
              : "repeat(3, 1fr)",
            gridGap: 20,
            // marginTop: 20,
          }}
        >
          {new_indents_list.map((v, i) => (
            <div className={"card p20"} key={i}>
              <div style={{ justifyContent: "space-between", display: "flex" }}>
                <div className="fs16 fw600 black pb6">{v.description}</div>
                <div className="fs14 fw600 orange cursor">{v.indentid}</div>
              </div>
              <div className="df alc gap20 pb6">
                <div className="df alc gap10">
                  <div className="fs14 fw400 black">Price:</div>
                  <div className="fs14 fw400 black">{v.price}</div>
                </div>
                <div className="df alc gap10">
                  <div className="fs14 fw400 black">Quantity:</div>
                  <div className="fs14 fw400 black">{v.quantity}</div>
                </div>
              </div>
              <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Address :</div>
                <div className="fs14 fw400 black">{v.deliveryaddress}</div>
              </div>
              <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Datetime:</div>
                <div className="fs14 fw400 black">{v.created_at}</div>
              </div>
              <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Amount:</div>
                <div className="fs14 fw400 view">{v.totalamount}</div>
              </div>

              <div className={"df jcc gap20"}>
                <ButtonComp
                  tittle={"Reject"}
                  b_color={"red"}
                  height={30}
                  onClick={() => {
                    setId(i);
                    setQty(v.quantity);
                    setReject(true);
                  }}
                />
                <ButtonComp
                  tittle={"Accept"}
                  b_color={"green"}
                  height={30}
                  onClick={() => {
                    setId(i);
                    // acceptOrder(v.orderid);
                    setQty(v.quantity);
                    setAccept(true);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      <Popup
        open={accept}
        message={<div className="green title-text">Accept Order</div>}
        body={
          <>
            <div className="title-text">
              {new_indents_list[id]?.description}
            </div>
            <div className="df alc gap10 pb6 mt5">
              <div className="fs14 fw400 black">Require Quantity -</div>
              <div className="fs14 fw400 black ">
                {new_indents_list[id]?.quantity}
              </div>
            </div>
            <div className="df alc gap10 pb6 mt5">
              <div className="fs14 fw400 black">Quantity:</div>
              <input
                placeholder="Enter Quantity "
                type={"number"}
                className={"flex input-text input-border"}
                value={qty}
                onChange={(e) => {
                  if (e.target.value > new_indents_list[id]?.quantity) {
                    toast.info("Quantity must be less than Require Quantity");
                    return;
                  }
                  setQty(e.target.value);
                }}
              />
            </div>
          </>
        }
        setOpen={setAccept}
        b_name={"Confirm"}
        onClick={() => acceptOrder()}
        loading={loading}
      />
      <Popup
        open={reject}
        message={<div className="title-text">Reject Order</div>}
        body={
          <>
            <SingleInput
              area1={true}
              label1={"Message"}
              placeholder1={"Write reject message"}
              value1={mess}
              onChange1={(e) => setMess(e.target.value)}
            />
          </>
        }
        setOpen={setReject}
        b_name={"Confirm"}
        onClick={() => rejectOrder()}
        loading={loading}
      />
    </div>
  );
};

export default New;

import React, { useState } from "react";
import ButtonComp from "../../components/ButtonComp";
import { get_referal_code } from "../../utils/api";
import { toast } from "react-toastify";
import { ingress, user_id } from "../../utils/utils";
import { Popup } from "../../utils/DialogBox";
import { WhatsappIcon, WhatsappShareButton } from "react-share";

const Referral = () => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [open, setOpen] = useState(false);
  const get = () => {
    get_referal_code(user_id, ingress, setLoading).then((v) => {
      if (v.success) {
        toast.success(v.message);
        setCode(v.referralcode);
      } else {
        toast.error(v.message);
      }
    });
  };
  const copyToClipboard = (text) => {
    toast.success("Text copied");
    var textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };
  return (
    <div>
      <div className="card p20">
        <div className="heading-text">Refer to</div>
        {code.length != 0 && (
          <div className="df alc fs16 fw400 black pb6 gap10">
            Your referral code is
            <div className="green heading-text">{code}</div>
          </div>
        )}
        <div className="df alc jcc gap10">
          <ButtonComp
            tittle={"Get Referral code"}
            loading={loading}
            onClick={() => get()}
          />
          {code.length != 0 && (
            <ButtonComp
              tittle={"Share"}
              loading={loading}
              onClick={() => setOpen(true)}
            />
          )}
          {code.length != 0 && (
            <ButtonComp
              tittle={"Copy"}
              loading={loading}
              onClick={() => copyToClipboard(code)}
            />
          )}
        </div>
      </div>
      <Popup
        open={open}
        message={<div className="fs16 fw400 black ">Share with</div>}
        body={
          <>
            <div className="title-text">
              <WhatsappShareButton url={code}>
                <WhatsappIcon />
              </WhatsappShareButton>
            </div>
          </>
        }
        setOpen={setOpen}
        one
      />
    </div>
  );
};

export default Referral;

import React from "react";
import Select from "react-select";

const CustomInput = ({
  label1,
  label2,
  placeholder1,
  placeholder2,
  type1,
  type2,
  right1,
  right2,
  value1,
  value2,
  onChange1,
  onChange2,
  single,
  disabled1,
  disabled2,
  area1,
  area2,

  options1,
  multi1,
  closeMenuOnSelect1,

  options2,
  multi2,
  closeMenuOnSelect2,
  drop1,
  drop2,
  button1,
  button2,
  bottom1,
  bottom2,
  textonly,
  textonly1,
  maxLength1,
  maxLength2,
  borderColor,
  items,
  min1,
  max1,
  min2,
  max2,
  id1,
  id2,
  menuPlacement1,
  menuPlacement2,
  autoCapitalize1,
  autoCapitalize2,
}) => {
  return (
    <div className={"input-df  gap20"}>
      <div className={"flex"}>
        {label1 && <div className="label mt10">{label1}</div>}
        {drop1 ? (
          <Select
            placeholder={"Select"}
            menuPlacement={menuPlacement1 || "auto"}
            isMulti={multi1}
            value={value1}
            onChange={onChange1}
            options={options1}
            isDisabled={disabled1}
            closeMenuOnSelect={closeMenuOnSelect1}
          />
        ) : area1 ? (
          <div className={"flex df alc"}>
            {textonly ? (
              <div className={"flex text_bg "}>
                <div style={{ flex: 1 }}>{value1}</div>
                {right1}
              </div>
            ) : (
              <textarea
                className={"input-area flex input-border input-text"}
                placeholder={placeholder1}
                value={value1}
                rows={3}
                onChange={onChange1}
              />
            )}
          </div>
        ) : (
          <div className={`custom_input_df_button alc flex gap10`}>
            <div className={`flex df alc ${textonly || "input-border"}`}>
              {textonly ? (
                // <div className={"flex text_bg "}>
                <div className={"flex text_bg df alc"}>
                  <div className="flex">{value1}</div>

                  {textonly && right1}
                </div>
              ) : (
                <input
                  type={type1}
                  // type={"email"}
                  id={id1}
                  // autoCapitalize=
                  autoCapitalize={autoCapitalize1}
                  maxLength={maxLength1}
                  className={"flex input-text"}
                  placeholder={placeholder1}
                  value={value1}
                  onChange={onChange1}
                  disabled={disabled1}
                  min={min1}
                  max={max1}
                />
              )}
              {textonly || right1}
            </div>
            {button1}
          </div>
        )}
        {bottom1}
      </div>
      {single ? (
        <div className="flex">
          {label2 && <div className="label mt10">{label2}</div>}
          {items}
        </div>
      ) : (
        <div className={"flex"}>
          {label2 && <div className="label mt10">{label2}</div>}
          {drop2 ? (
            <Select
              placeholder={"Select"}
              isMulti={multi2}
              menuPlacement={menuPlacement2 || "auto"}
              value={value2}
              onChange={onChange2}
              options={options2}
              isDisabled={disabled2}
              closeMenuOnSelect={closeMenuOnSelect2}
            />
          ) : area2 ? (
            <div className={"flex df alc"}>
              {textonly1 ? (
                <div className={"flex text_bg "}>
                  <div className="flex">{value2}</div>
                  {right2}
                </div>
              ) : (
                <textarea
                  className={"input-area flex input-border input-text"}
                  placeholder={placeholder2}
                  value={value2}
                  rows={3}
                  onChange={onChange2}
                />
              )}
            </div>
          ) : (
            <div className={`custom_input_df_button alc flex gap10`}>
              <div className={`flex df alc ${textonly1 || "input-border"} `}>
                {textonly1 ? (
                  <div className={"flex text_bg df alc"}>
                    <div style={{ flex: 1 }}>{value2}</div>
                    {textonly1 && right2}
                  </div>
                ) : (
                  <input
                    type={type2}
                    id={id2}
                    maxLength={maxLength2}
                    // maxLength={6}
                    autoCapitalize={autoCapitalize2}
                    className={"flex input-text"}
                    placeholder={placeholder2}
                    value={value2}
                    onChange={onChange2}
                    disabled={disabled2}
                    min={min2}
                    max={max2}
                  />
                )}
                {textonly1 || right2}
              </div>
              {button2}
            </div>
          )}
          {bottom2}
        </div>
      )}
    </div>
  );
};

export default CustomInput;

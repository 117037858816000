import React, { useEffect, useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { toast } from "react-toastify";
import ButtonComp from "../../components/ButtonComp";
import { Link, useNavigate } from "react-router-dom";
import css from "./login.module.scss";
import logoImg from "./logo.png";
import { base_url } from "../../utils/utils";
import SingleInput from "../../components/custom/SingleInput";
import {
  get_da_profile,
  get_partner_profile,
  get_vendor_profile,
} from "../../utils/api";
import { useSelector } from "react-redux";
const Login = () => {
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const { data } = useSelector((state) => state.user);
  
  const onClick_login = async () => {
    if (!name || !password) {
      toast.info("Please fill all fields");
      return;
    }
    setLoading(true);
    try {
      const body = new FormData();
      body.append("userid", name);
      body.append("password", password);
      const response = await fetch(base_url + "login", {
        body: body,
        method: "POST",
      });
      const res = await response.json();

      setLoading(false);
      if (res.success) {
        toast.success(res.message);
        localStorage.setItem("user", JSON.stringify(res.data));
        if (res.data.access === 1) {
          get_vendor_profile(res.data.userid).then(() => navigate("/"));
          window.location.reload();
          return;
        }
        if (res.data.access === 2) {
          get_da_profile(res.data.userid).then(() => navigate("/"));
          window.location.reload();
          return;
        }
        if (res.data.access === 4) {
          get_partner_profile(res.data.userid).then(() => navigate("/"));
          window.location.reload();
          return;
        }
        // ((await res.data.access) === 4 &&
        //   get_partner_profile(res.data.userid).then(() => navigate("/"))) ||
        //   ((await res.data.access) === 1 &&
        //     get_vendor_profile(res.data.userid).then(() => navigate("/"))) ||
        //   ((await res.data.access) === 2 &&
        //     get_da_profile(res.data.userid).then(() => navigate("/")));
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (user != null) {
      navigate("/");
    }
  }, [localStorage]);

  return (
    <div className={css.container}>
      <div className={css.main}>
        <div className={css.img_div}>
          <div className={css.heading}>
            <div>
              <img className={css.logo} src={logoImg} alt="logo" />
            </div>
          </div>
        </div>
        <div className="p20">
          <SingleInput
            type1="text"
            placeholder1={"Enter user id"}
            value1={name}
            onChange1={(e) => setName(e.target.value)}
          />
          <div className="mt20" />
          <SingleInput
            single
            placeholder1="Enter Password"
            value1={password}
            onChange1={(e) => setPassword(e.target.value)}
            type1={showPass ? "text" : "password"}
            right1={
              showPass ? (
                <BsEyeSlash
                  size={16}
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowPass(!showPass)}
                />
              ) : (
                <BsEye
                  size={16}
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowPass(!showPass)}
                />
              )
            }
          />

          <div className={"df alc jce mt20 cursor"}>Forgot password</div>

          <div className="df alc jcc mt20">
            <ButtonComp
              tittle={"Login"}
              onClick={onClick_login}
              loading={loading}
            />
          </div>
          <div className={"df alc jcc mt20 gap10 font1"}>
            New to our platform ?
            <Link
              style={{ color: "#007BC9", fontWeight: "bold" }}
              to={"/signup"}
            >
              Register
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

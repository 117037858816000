import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const TableView = ({ headerData = [], bodyData = [] }) => {
  const navigate = useNavigate();
  return (
    <Paper sx={{ width: "100%", mb: 2, borderRadius: 3, overflow: "hidden" }}>
      <TableContainer
        // component={Paper}
        // elevation={3}
        style={{
          maxHeight: "auto",
        }}
      >
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              {headerData.map((v, i) => (
                <TableCell
                  key={i}
                  align="center"
                  // className="bg-gray-50"
                  sx={{ backgroundColor: "#f9fafb" }}
                >
                  {v}
                  {/* <div style={{ color: "#000", fontSize: 16 }}>{v}</div> */}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{bodyData}</TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        // rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={10}
        rowsPerPage={10}
        page={0}
        // onPageChange={handleChangePage}
        // onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
};

export default TableView;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { BsEye, BsEyeSlash, BsFillCaretDownFill } from "react-icons/bs";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonComp from "../../components/ButtonComp";
import Check from "../../components/Check";
import Add_Doc from "../../components/custom/Add_doc";
import SingleInput from "../../components/custom/SingleInput";
import { partner_signup } from "../../utils/api";
import { send_otp } from "../../utils/apis";
import { isValidEmail } from "../../utils/utils";

const DeliveryPartner = () => {
  const navigate = useNavigate();
  const [term, setTerm] = useState(true);
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  const [tab, setTab] = useState("1");
  const [show, setShow] = useState(false);
  const [personal_detail, setPersonal_detail] = useState({
    name: "",
    email: "",
    mobile1: "",
    mobile2: "",
    address: "",
    pan: "",
    license: "",
    aadhar: "",
    password: "",
    otp1: "",
    otp2: "",
    id1: "",
    id2: "",
    validity: "",
  });
  const [vehicle_detail, setVehicle_detail] = useState({
    make: "",
    c_no: "",
    v_no: "",
    capacity: "",
  });
  const [docList, setDocList] = useState([
    { title: "Driving License", docData: {} },
    { title: "Aadhaar", docData: {} },
    { title: "Pan", docData: {} },
    {
      title: "RC",
      docData: {},
    },
    {
      title: "Photo",
      docData: {},
    },
  ]);
  const [docList1, setDocList1] = useState([]);
  const [otp_loading, setOtp_loading] = useState("");
  const [show_otp1, setShow_otp1] = useState(false);
  const [show_otp2, setShow_otp2] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendOtp = (no, load) => {
    if (no.length == 0) {
      toast.info("please enter mobile number");
      return;
    }
    setOtp_loading(load);
    send_otp(no).then((data) => {
      if (data.success) {
        if (load == 1) {
          setShow_otp1(true);
          setPersonal_detail({ ...personal_detail, id1: data.id });
        } else {
          setShow_otp2(true);
          setPersonal_detail({ ...personal_detail, id2: data.id });
        }
      } else {
        if (load == 1) {
          setShow_otp1(false);
          setPersonal_detail({ ...personal_detail, id1: "" });
        } else {
          setShow_otp2(false);
          setPersonal_detail({ ...personal_detail, id2: "" });
        }
      }
      setOtp_loading("");
    });
  };
  const new_docList1 = docList1.filter(
    (value) => Object.keys(value).length !== 0
  );
  const new_docList = docList.concat(new_docList1);
  const empty = docList.filter((v, i) => !v.docData?.name);
  console.log("vehicle_detail", vehicle_detail.v_no, personal_detail);
  console.log("pass", personal_detail.password.trim());
  const register = () => {
    if (
      !personal_detail.aadhar ||
      !personal_detail.address ||
      !personal_detail.email ||
      !personal_detail.mobile1 ||
      !personal_detail.mobile2 ||
      !personal_detail.pan ||
      !personal_detail.name ||
      !personal_detail.license ||
      !personal_detail.password ||
      !personal_detail.validity
    ) {
      toast.info("Please fill in your personal details");
      return;
    }
    if (
      !vehicle_detail.make ||
      !vehicle_detail.v_no ||
      !vehicle_detail.c_no ||
      !vehicle_detail.capacity
    ) {
      toast.info("Please fill fields in your vehicle details");
      return;
    }
    if (empty.length != 0) {
      toast.info("Please select documnents");
      return;
    }
    if (!personal_detail.email.match(isValidEmail)) {
      toast.error("Invalid email address");
      return;
    }
    const body = new FormData();
    body.append("id", personal_detail.id1 + "," + personal_detail.id2);
    body.append("name", personal_detail.name);
    body.append("email", personal_detail.email);
    body.append("mobile1", personal_detail.mobile1);
    body.append("mobile2", personal_detail.mobile2);
    body.append("address", personal_detail.address);
    body.append("drivinglicensevalidity", personal_detail.validity);
    body.append("pan", personal_detail.pan);
    body.append("drivinglicense", personal_detail.license);
    body.append("aadhar", personal_detail.aadhar);
    body.append("otp1", personal_detail.otp1);
    body.append("otp2", personal_detail.otp2);
    body.append("password", personal_detail.password.trim());
    body.append("make", vehicle_detail.make);
    body.append("vehicleno", vehicle_detail.v_no);
    body.append("chassisno", vehicle_detail.c_no);
    body.append("capacity", vehicle_detail.capacity);
    body.append("documentscount", new_docList.length);
    body.append("names", new_docList.map((v) => v.title).join(",,,"));
    new_docList.forEach((v, i) => body.append(`${"document" + i}`, v.docData));
    partner_signup(body, setLoading).then((v) => {
      v && navigate("/");
      window.location.reload();
      toast.success(v.message);
    });
  };
  useEffect(() => {
    if (user != null) {
      navigate("/");
    }
  }, [localStorage]);
  return (
    <>
      <div className=" card  mb10">
        <div
          className="cursor text_bg df alc sb"
          onClick={() => setTab((pre) => (pre == 1 ? "" : 1))}
        >
          <div className="heading-text">Personal Details</div>
          <BsFillCaretDownFill
            style={{
              transform: tab == 1 ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </div>
        {tab == 1 && (
          <div className="padding-xy">
            <SingleInput
              label1={"Name"}
              placeholder1={"Enter Name"}
              value1={personal_detail.name}
              onChange1={(e) =>
                setPersonal_detail({ ...personal_detail, name: e.target.value })
              }
            />
            <SingleInput
              label1={"Email"}
              placeholder1={"Enter Email Address"}
              value1={personal_detail.email}
              onChange1={(e) =>
                setPersonal_detail({
                  ...personal_detail,
                  email: e.target.value,
                })
              }
            />
            <SingleInput
              label1={"Mobile Number"}
              placeholder1={"Enter Mobile Number"}
              value1={personal_detail.mobile1}
              type1={"number"}
              onChange1={(e) =>
                setPersonal_detail({
                  ...personal_detail,
                  mobile1: e.target.value,
                })
              }
              button={
                <ButtonComp
                  tittle={"Get Otp"}
                  loading={otp_loading == 1 ? true : false}
                  onClick={() => sendOtp(personal_detail.mobile1, 1)}
                />
              }
              bottom1={
                show_otp1 && (
                  <div className={"otp_container"}>
                    <OtpInput
                      value={personal_detail.otp1}
                      onChange={(e) =>
                        setPersonal_detail({ ...personal_detail, otp1: e })
                      }
                      numInputs={4}
                      inputStyle={"inputStyle"}
                      separator={<span style={{ margin: 5 }}> </span>}
                    />
                  </div>
                )
              }
            />
            <SingleInput
              label1={"Mobile Number(secondary)"}
              placeholder1={"Enter Mobile Number"}
              type1={"number"}
              value1={personal_detail.mobile2}
              onChange1={(e) =>
                setPersonal_detail({
                  ...personal_detail,
                  mobile2: e.target.value,
                })
              }
              button={
                <ButtonComp
                  tittle={"Get Otp"}
                  loading={otp_loading == 2 ? true : false}
                  onClick={() => sendOtp(personal_detail.mobile2, 2)}
                />
              }
              bottom1={
                show_otp2 && (
                  <div className={"otp_container"}>
                    <OtpInput
                      value={personal_detail.otp2}
                      onChange={(e) =>
                        setPersonal_detail({ ...personal_detail, otp2: e })
                      }
                      numInputs={4}
                      inputStyle={"inputStyle"}
                      separator={<span style={{ margin: 5 }}> </span>}
                    />
                  </div>
                )
              }
            />
            <SingleInput
              label1={"Address"}
              area1
              placeholder1={"Enter Address"}
              value1={personal_detail.address}
              onChange1={(e) =>
                setPersonal_detail({
                  ...personal_detail,
                  address: e.target.value,
                })
              }
            />
            <SingleInput
              label1={"Pan"}
              placeholder1={"Enter Pan Number"}
              value1={personal_detail.pan}
              onChange1={(e) =>
                setPersonal_detail({ ...personal_detail, pan: e.target.value })
              }
            />
            <SingleInput
              label1={"Driving License"}
              placeholder1={"Enter Driving License Number"}
              value1={personal_detail.license}
              onChange1={(e) =>
                setPersonal_detail({
                  ...personal_detail,
                  license: e.target.value,
                })
              }
            />
            <SingleInput
              label1={"Driving License validity"}
              // placeholder1={"Enter Driving License Number"}
              type1={"date"}
              value1={personal_detail.validity}
              min={new Date().toISOString().split("T")[0]}
              onChange1={(e) =>
                setPersonal_detail({
                  ...personal_detail,
                  validity: e.target.value,
                })
              }
            />
            <SingleInput
              label1={"Aadhaar"}
              placeholder1={"Enter Aadhaar Number"}
              type1={"number"}
              value1={personal_detail.aadhar}
              onChange1={(e) =>
                setPersonal_detail({
                  ...personal_detail,
                  aadhar: e.target.value,
                })
              }
            />
            <SingleInput
              label1={"Password"}
              placeholder1={"Enter Password"}
              value1={personal_detail.password}
              type1={show ? "text" : "password"}
              onChange1={(e) =>
                setPersonal_detail({
                  ...personal_detail,
                  password: e.target.value,
                })
              }
              right1={
                show ? (
                  <BsEyeSlash
                    size={16}
                    style={{ cursor: "pointer" }}
                    onClick={() => setShow(!show)}
                  />
                ) : (
                  <BsEye
                    size={16}
                    style={{ cursor: "pointer" }}
                    onClick={() => setShow(!show)}
                  />
                )
              }
            />
          </div>
        )}
      </div>
      <div className=" card  mb10">
        <div
          className="cursor text_bg df alc sb"
          onClick={() => setTab((pre) => (pre == 2 ? "" : 2))}
        >
          <div className="heading-text">Vehicle Details</div>
          <BsFillCaretDownFill
            style={{
              transform: tab == 2 ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </div>
        {tab == 2 && (
          <div className="padding-xy">
            <SingleInput
              label1={"Make"}
              placeholder1={"Enter Make"}
              value1={vehicle_detail.make}
              onChange1={(e) =>
                setVehicle_detail({ ...vehicle_detail, make: e.target.value })
              }
            />
            <SingleInput
              label1={"Chassis Number"}
              placeholder1={"Enter Chassis Number"}
              value1={vehicle_detail.c_no}
              onChange1={(e) =>
                setVehicle_detail({ ...vehicle_detail, c_no: e.target.value })
              }
            />
            <SingleInput
              label1={"Vehicle Number"}
              placeholder1={"Enter Vehicle Number"}
              value1={vehicle_detail.v_no}
              onChange1={(e) =>
                setVehicle_detail({ ...vehicle_detail, v_no: e.target.value })
              }
            />
            <SingleInput
              label1={"Carrying Capacity (19 kgs)"}
              placeholder1={"Enter Carrying Capacity"}
              type1={"number"}
              value1={vehicle_detail.capacity}
              onChange1={(e) =>
                setVehicle_detail({
                  ...vehicle_detail,
                  capacity: e.target.value,
                })
              }
            />
          </div>
        )}
      </div>
      <div className=" card  ">
        <div
          className="cursor text_bg df alc sb"
          onClick={() => setTab((pre) => (pre == 3 ? "" : 3))}
        >
          <div className="heading-text">Documents Details</div>
          <BsFillCaretDownFill
            style={{
              transform: tab == 3 ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </div>
        {tab == 3 && (
          <div className="padding-xy">
            {docList.map((v, i) => (
              <Add_Doc
                key={i}
                title={
                  <div>
                    {v.title}
                    <span className="trash"> *</span>
                  </div>
                }
                onChange={(e) =>
                  setDocList((pre) =>
                    pre.map((val) =>
                      val.title == v.title
                        ? { ...val, docData: e.target.files[0] }
                        : { ...val }
                    )
                  )
                }
                filename={v.docData.name}
                right={
                  v.docData?.name && (
                    <ButtonComp
                      tittle={"Delete"}
                      b_color={"#f00"}
                      onClick={() =>
                        setDocList((pre) =>
                          pre.map((val) =>
                            val.title == v.title
                              ? { ...val, docData: {} }
                              : { ...val }
                          )
                        )
                      }
                    />
                  )
                }
              />
            ))}
            <div className="df alc sb mt15">
              <div>Others</div>
              <ButtonComp
                tittle={"Add"}
                onClick={() => setDocList1((pre) => [...pre, {}])}
              />
            </div>
            {docList1.map((v, i) => (
              <div className="mt10" key={i}>
                <Add_Doc
                  placeholder={"Enter file name"}
                  value={Object.entries(v).length === 0 ? "" : v?.title}
                  filename={v?.docData?.name}
                  onChange1={(e) =>
                    setDocList1((pre) =>
                      pre.map((val, ind) =>
                        i == ind
                          ? { ...val, title: e.target.value, docData: {} }
                          : val
                      )
                    )
                  }
                  onChange={(e) => {
                    if (Object.entries(v).length === 0) {
                      toast.info("please add file name");
                      return;
                    }
                    setDocList1((pre) =>
                      pre.map((val, ind) =>
                        i == ind ? { ...val, docData: e.target.files[0] } : val
                      )
                    );
                  }}
                  right={
                    <ButtonComp
                      tittle={"Delete"}
                      b_color={"#f00"}
                      onClick={() =>
                        setDocList1((pre) =>
                          pre.find((val, ind) => ind == i)
                            ? pre.filter((val, ind) => ind !== i)
                            : [...pre]
                        )
                      }
                    />
                  }
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="df alc mt10">
        <Check checked={term} onchange={() => setTerm(!term)} />
        <div className="footer_div">
          <div className={"font1 mr10"}>by signing up, I accept</div>
          <div>
            <Link
              style={{ color: "#007BC9", fontWeight: "initial" }}
              to={"/signup"}
            >
              Term & Condition
            </Link>
          </div>
        </div>
      </div>
      <div className="df alc jcc mt20">
        <ButtonComp tittle={"Register"} onClick={() => register()} />
      </div>
      <div className={"df alc jcc mt20 gap10 font1"}>
        Already have an account ?
        <Link style={{ color: "#007BC9", fontWeight: "bold" }} to={"/login"}>
          Login
        </Link>
      </div>
    </>
  );
};

export default DeliveryPartner;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ButtonComp from "../../components/ButtonComp";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { isSmallScreen, Loader, No_data } from "../../utils/utils";
import TableView from "../../components/custom/TableView";
import { TableCell, TableRow } from "@mui/material";
import { get_allotment } from "../../redux/slice/allotmentSlice";

const headerData = ["S.No.", "Vehicle", "Name", "Mobile No.", "Datetime"];
const Allotment = () => {
  const navigate = useNavigate();
  const { allotment_list, a_loading } = useSelector((state) => state.allotment);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(get_allotment());
  }, []);

  return (
    <>
      <div className={"card df padding-xy sb mb10 alc"}>
        <div
          className={
            "border-gray-200 bg-gray-100 padding-xy alc df br search-bar "
          }
        >
          <input
            className="flex"
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <SearchOutlinedIcon />
        </div>
        <div className="flex"></div>

        <ButtonComp
          onClick={() => navigate("/allotment/add-allotment")}
          tittle={isSmallScreen ? "Add" : "Add Allotment"}
          icon={<AddIcon />}
        />
      </div>
      {allotment_list.length == 0 && a_loading ? (
        <div className="card p20 df alc jcc">
          <Loader />
        </div>
      ) : allotment_list.length == 0 ? (
        <No_data />
      ) : (
        <TableView
          headerData={headerData}
          bodyData={allotment_list
            .filter((item) => {
              return (
                item.name.toLowerCase().includes(search.toLowerCase()) ||
                item.vehicle.toLowerCase().includes(search.toLowerCase())
              );
            })
            .map((v, i) => (
              <TableRow key={i}>
                <TableCell align="center">{i + 1}</TableCell>
                <TableCell align="center">{v.vehicle}</TableCell>
                <TableCell align="center">{v.name}</TableCell>
                <TableCell align="center">{v.mobile1}</TableCell>
                <TableCell align="center">{v.datetime}</TableCell>
              </TableRow>
            ))}
        />
      )}
      {/* <Delete_dialog
        open={open}
        setOpen={setOpen}
        onClick_delete={() => handle_deleteVehicle()}
      /> */}
    </>
  );
};

export default Allotment;

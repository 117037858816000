import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";
import ButtonComp from "../components/ButtonComp";

export const Delete_dialog = ({ open, setOpen, onClick_delete }) => {
  //   const [open, setOpen] = React.useState(openBox);

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Are you sure you want to delete?"}
      </DialogTitle>
      {/* <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Let Google help apps determine location. This means sending anonymous
          location data to Google, even when no apps are running.
        </DialogContentText>
      </DialogContent> */}
      <DialogActions>
        <Button onClick={handleClose}>cancle</Button>
        <Button onClick={onClick_delete} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const useNet_coonection = ({ open, setOpen, onClick_delete }) => {
  const { check_net } = useSelector((state) => state.user);

  return (
    <Dialog
      open={!check_net}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"No inertnet connection"}
      </DialogTitle>
      <DialogActions>
        {/* <Button onClick={handleClose}>cancle</Button> */}
        <Button onClick={onClick_delete} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const Delete_popup = ({
  open,
  setOpen,
  onClick_delete,
  message,
  d_name,
  body,
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      // className="db p20"
    >
      <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
      {body && <DialogContent>{body}</DialogContent>}
      <DialogActions className="gap20">
        <ButtonComp onClick={handleClose} tittle={"Cancle"} />
        <ButtonComp onClick={onClick_delete} tittle={d_name} b_color={"#f00"} />
      </DialogActions>
    </Dialog>
  );
};

export const Popup = ({
  open,
  setOpen,
  onClick,
  message,
  b_name,
  body,
  loading,
  one,
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
      {body && <DialogContent>{body}</DialogContent>}
      <DialogActions className="">
        {one || (
          <ButtonComp
            tittle={"Cancle"}
            onClick={handleClose}
            b_color={"#808080"}
          />
        )}
        {b_name && (
          <ButtonComp tittle={b_name} onClick={onClick} loading={loading} />
        )}
      </DialogActions>
    </Dialog>
  );
};

import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const loggedInUser = localStorage.getItem("user");
  const user = JSON.parse(loggedInUser);
  return user != null ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;

import React from "react";

const SingleInput = ({
  label1,
  type1,
  maxLength1,
  placeholder1,
  value1,
  onChange1,
  disabled1,
  min,
  max,
  area1,
  right1,
  required,
  button,
  bottom1,
}) => {
  return (
    <div className={" gap20"}>
      <div className={"flex"}>
        {label1 && <div className="label mt10">{label1}</div>}
        <div className="df alc gap10 ">
          <div className={`flex df alc ${area1 || "input-border"}`}>
            {area1 ? (
              <textarea
                className={"input-area input-border flex input-text "}
                // style={{ backgroundColor: "green" }}
                placeholder={placeholder1}
                value={value1}
                rows={3}
                onChange={onChange1}
              />
            ) : (
              <input
                type={type1}
                // type={"datetime-local"}
                maxLength={maxLength1}
                className={"flex input-text"}
                placeholder={placeholder1}
                value={value1}
                onChange={onChange1}
                disabled={disabled1}
                min={min}
                max={max}
                required={required}
              />
            )}
            {right1}
          </div>
          {button}
        </div>
      </div>
      {bottom1}
    </div>
  );
};

export default SingleInput;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader, No_data } from "../../utils/utils";
import store from "../../redux/store";
import { get_i_processsing_list } from "../../redux/slice/indentSlice";
import ButtonComp from "../../components/ButtonComp";
import { useLocation, useNavigate } from "react-router-dom";

const Processing = () => {
  const navigate = useNavigate();
  const { processing_list, processing_loading } = useSelector(
    (state) => state.indents
  );

  const location = useLocation();
  console.log(location.state);
  useEffect(() => {
    store.dispatch(get_i_processsing_list());
  }, []);
  return (
    <div>
      {processing_list.length == 0 && processing_loading ? (
        <div className="card p20 df alc jcc">
          <Loader />
        </div>
      ) : processing_list.length == 0 ? (
        <No_data />
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: window.matchMedia("(max-width: 500px)").matches
              ? "repeat(1, 1fr)"
              : window.matchMedia("(max-width: 900px)").matches
              ? "repeat(2, 1fr)"
              : "repeat(3, 1fr)",
            gridGap: 20,
          }}
        >
          {processing_list.map((v, i) => (
            <div className={"card p20"} key={i}>
              <div style={{ justifyContent: "space-between", display: "flex" }}>
                <div className="fs16 fw600 black pb6">{v.description}</div>
                <div className="fs14 fw600 orange">{v.indentid}</div>
              </div>
              <div className="df alc gap20 pb6">
                <div className="df alc gap10 ">
                  <div className="fs14 fw400 black">Price:</div>
                  <div className="fs14 fw400 black">{v.price}</div>
                </div>
                <div className="df alc gap10 ">
                  <div className="fs14 fw400 black">Quantity:</div>
                  <div className="fs14 fw400 black">{v.quantity}</div>
                </div>
              </div>
              <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Address :</div>
                <div className="fs14 fw400 black">{v.deliveryaddress}</div>
              </div>
              <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Datetime:</div>
                <div className="fs14 fw400 black">{v.created_at}</div>
              </div>
              <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Amount:</div>
                <div className="fs14 fw400 view">{v.totalamount}</div>
              </div>

              <div className={"df jcc gap20"}>
                <ButtonComp
                  tittle={"View Detail"}
                  height={30}
                  onClick={() => {
                    navigate("/indents/order-detail", { state: v });
                  }}
                />
                <ButtonComp
                  tittle={"Stock Transfer"}
                  height={30}
                  onClick={() =>
                    navigate("/indents/stock-transfer", { state: v })
                  }
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Processing;

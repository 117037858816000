import React, { useEffect, useState } from "react";
import ButtonComp from "../../components/ButtonComp";
import CustomInput from "../../components/custom/CustomInput";
import { Delete_popup } from "../../utils/DialogBox";
import { base_url } from "../../utils/utils";
import { stocktransfer_verify } from "../../utils/api";
import { toast } from "react-toastify";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const NotificationDetail = () => {
  const navigate = useNavigate();
  const [detail, setDetail] = useState({});
  const [otp, setOtp] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState("");
  const get_notification = async () => {
    try {
      const body = new FormData();
      body.append("userid", 4);
      body.append("id", 1);
      const response = await fetch(base_url + "notification-details", {
        method: "POST",
        body: body,
      });
      const res = await response.json();

      if (res.success) {
        setDetail(res.data[0]);
      } else {
        setDetail({});
      }
    } catch (error) {}
  };
  useEffect(() => {
    get_notification();
  }, []);
  const accept_reject = (act) => {
    if (act == 1) {
      if (!otp) {
        toast.info("please enter translation code");
        return;
      }
    }
    setOpen(false);
    stocktransfer_verify(1, act, act == 1 ? otp : "", setLoading)
      .then((v) => console.log(v))
      .catch((error) => console.log(error));
  };
  return (
    <div>
      <div className="card padding-xy mb10 df alc sb">
        <div className="heading-text">Notification Detail</div>
        <ButtonComp
          b_color={"black"}
          icon={<BiArrowBack color="#fff" size={16} />}
          onClick={() => navigate(-1)}
          tittle={"Back"}
        />
      </div>

      {Object.keys(detail).length == 0 || (
        <div className="card p20">
          <div className="input-df alc sb ">
            <div className="title-text">{detail.name}</div>
            <div className="medium-text light-green padding-xy br5">
              GW - {detail.senderid}
            </div>
          </div>
          <div className="medium-text mt5">Vehicle no - {detail.vehicleno}</div>
          <div className="medium-text mt5">Datetime - {detail.datetime}</div>
          <div className="df alc sb mt5" style={{ maxWidth: "65%" }}>
            <div className="medium-text">Products</div>
            <div
              className={`heading-text uppercase ${
                detail.type_name == "filled"
                  ? "green"
                  : detail.type_name == "Empty"
                  ? "yellow"
                  : "trash"
              }`}
            >
              {detail.type_name}
            </div>
          </div>
          <table className="inv_table mt10">
            <thead>
              <tr>
                <th className="inv_td">Name</th>
                <th className="inv_td">Type</th>
                <th className="inv_td">QTY</th>
              </tr>
            </thead>
            <tbody>
              {detail.products.map((val, key) => {
                return (
                  <tr key={key}>
                    <td className="inv_td">{val.description}</td>
                    <td className="inv_td">{val.type}</td>
                    <td className="inv_td">{val.qty}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <CustomInput
            label1={"Transaction Code"}
            placeholder1={"Enter Tansaction code"}
            value1={otp}
            onChange1={(e) => setOtp(e.target.value)}
            single
          />
          <div className="df alc gap20 mt20">
            <ButtonComp
              tittle={"Reject"}
              b_color={"#f00"}
              onClick={() => setOpen(true)}
            />
            <ButtonComp
              tittle={"Accept"}
              loading={loading == 1 ? true : false}
              onClick={() => accept_reject(1)}
            />
          </div>
        </div>
      )}
      <Delete_popup
        message={"Are you sure, you want to reject stocktransfer"}
        open={open}
        setOpen={setOpen}
        onClick_delete={() => accept_reject(2)}
        d_name={"Reject"}
      />
    </div>
  );
};

export default NotificationDetail;

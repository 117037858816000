import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
const user_id = user?.userid;
const ingress = user?.access;

export const get_notification_data = createAsyncThunk(
  "notification",
  async () => {
    const body = new FormData();
    body.append("userid", user_id);
    body.append("ingress", ingress);

    const response = await fetch(base_url + "notifications", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    // console.log("list", res);
    return res;
  }
);

const orderSlice = createSlice({
  initialState: {
    n_loading: false,
    notification_data: [],
  },
  name: "notification",
  extraReducers: (builder) => {
    builder.addCase(get_notification_data.fulfilled, (state, action) => {
      const data = action.payload;
      state.n_loading = false;
      if (data.success) {
        state.notification_data = data.data;
      } else {
        state.notification_data = [];
      }
    });
    builder.addCase(get_notification_data.pending, (state, action) => {
      state.n_loading = true;
    });
  },
});

export default orderSlice.reducer;

import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../header/Header";
import style from "./sidebar.module.scss";
import Footer from "../footer/Footer";
import logo from "../../assets/logoname.png";
import { useDispatch } from "react-redux";
import {
  get_all_products,
  get_product_list,
} from "../../redux/slice/productSlice";
import { ingress, isSmallScreen } from "../../utils/utils";
import {
  get_da_profile,
  get_partner_profile,
  get_vendor_profile,
} from "../../utils/api";
import { get_documents } from "../../redux/slice/userSlice";
import SidebarItems from "./SidebarItems";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(get_product_list());
    dispatch(get_all_products());
    (ingress == 1 || ingress == 2) && dispatch(get_documents());
    ingress == 4 && get_partner_profile();
    ingress == 1 && get_vendor_profile();
    ingress == 2 && get_da_profile();
  }, []);

  return (
    <div className={style.sidebar_wrapper}>
      <div className={style.sidebar_container}>
        <div className={style.logo_div}>
          <img src={logo} className={style.logo} />
        </div>
        <SidebarItems />
      </div>

      <div className={style.main_container}>
        <div className={style.header_container}>
          <Header />
        </div>
        <div
          className={style.main}
          style={{ padding: isSmallScreen ? "5px" : "20Px" }}
        >
          <Outlet />
        </div>
        <div className={style.footer_container}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
const user_id = user?.userid;
const ingress = user?.access;

export const get_allotment = createAsyncThunk("allotment", async () => {
  const body = new FormData();
  body.append("userid", user_id);
  body.append("ingress", ingress);
  body.append("action", 2);
  const response = await fetch(base_url + "pilot_manage", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});

const allotmentSlice = createSlice({
  initialState: {
    allotment_list: [],
    a_loading: false,
  },
  name: "allotment",
  extraReducers: (builder) => {
    builder.addCase(get_allotment.fulfilled, (state, action) => {
      const data = action.payload;
      state.a_loading = false;
      if (data.success) {
        state.allotment_list = data.data;
      } else {
        state.allotment_list = [];
      }
    });
    builder.addCase(get_allotment.pending, (state, action) => {
      state.a_loading = true;
    });
  },
});

export default allotmentSlice.reducer;

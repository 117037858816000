import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { base_url, ingress, user_id } from "../utils/utils";

const body = new FormData();
body.append("userid", user_id);
body.append("ingress", ingress);

const list_body = new FormData();
list_body.append("stateid", "");
export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://gaswala.vensframe.com/api/" }),
  endpoints: (builder) => ({
    getAllList: builder.query({
      query: () => ({
        url: "completelist",
        method: "POST",
        body: list_body,
      }),
    }),
    getVehicleList: builder.query({
      query: () => ({
        url: "vehiclelist",
        method: "POST",
        body: body,
      }),
    }),

    getProfile: builder.query({
      query: () => ({
        url: "profile",
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetProfileQuery,
  useGetAllListQuery,
  useGetVehicleListQuery,
} = userApi;

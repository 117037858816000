import React, { useEffect, useState } from "react";
import { MdOutlineVerified } from "react-icons/md";
import { FiEdit2 } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { get_otp, verify_email } from "../../utils/apis";
import { Oval } from "react-loader-spinner";
import OtpInput from "react-otp-input";
import { base_url, ingress, isValidEmail, user_id } from "../../utils/utils";
import { get_da_profile, get_vendor_profile } from "../../utils/api";
import ButtonComp from "../../components/ButtonComp";
import CustomInput from "../../components/custom/CustomInput";

const Personal_profile = () => {
  const dispatch = useDispatch();
  const { data, state_list } = useSelector((state) => state.user);
  const [p_data, setP_data] = useState({});
  const [state_dist3, setState_dist3] = useState([]);
  const [pin_loading, setPin_loading] = useState("0");
  const [edit, setEdit] = useState(false);
  const [otp2, setOtp2] = useState("");
  const [otp1_show, setOtp1_show] = useState(false);
  const [otp2_show, setOtp2_show] = useState(false);
  const [otp_loading, setOtp_loading] = useState("0");
  const [email_loading, setEmail_loading] = useState("0");
  const [p_loading, setP_loading] = useState(false);
  const verifyEmail = async (email) => {
    verify_email(email, setEmail_loading);
  };
  const list = async (id, setDist) => {
    try {
      const body = new FormData();
      body.append("stateid", id);
      const response = await fetch(base_url + "completelist", {
        body: body,
        method: "POST",
      });
      const res = await response.json();
      // console.log(res);

      if (res.success) {
        const data1 = [];
        for (let i = 0; i < res.data.reponselist.districts.length; i++) {
          const element = {
            value: res.data.reponselist.districts[i].id,
            label: res.data.reponselist.districts[i].name,
          };
          data1.push(element);
          setDist(data1);
        }
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const pincode_verify = async (code, id, load) => {
    try {
      const body = new FormData();
      body.append("pincode", code);
      body.append("state", id);
      setPin_loading(load);
      const response = await fetch(base_url + "pincode_verify", {
        body: body,
        method: "POST",
      });
      const res = await response.json();
      // console.log("pincode", res);
      if (res.success) {
        toast.success(res.message);
        setPin_loading("0");
      } else {
        toast.error(res.message);
        setPin_loading("0");
      }
    } catch (error) {
      // console.log(error);
      setPin_loading("0");
    }
  };

  const submit_personal = async () => {
    try {
      if (!isValidEmail.test(p_data.email_personal)) {
        toast.info("Please enter a valid email");
        return;
      }
      if (p_data.mobile_personal.length != 10) {
        toast.info("Please enter valid a mobile");
        return;
      }
      setP_loading(true);
      const body = new FormData();
      body.append("userid", user_id);
      body.append("mobile", p_data.mobile_personal);
      body.append("website", p_data.website);
      body.append("email", p_data.email_personal);
      body.append(
        "address",
        p_data.address_personal +
          ",,," +
          p_data.state_personal +
          ",,," +
          p_data.district_personal +
          ",,," +
          p_data.pincode_personal
      );

      body.append("action", 2);
      const response = await fetch(
        base_url + `${ingress == 1 ? "update_profile" : "update_profile_dp"}`,
        { method: "POST", body: body }
      );
      const res = await response.json();
      if (res.success) {
        toast.success(res.message);
        setP_loading(false);
        ingress == 1 ? get_vendor_profile() : get_da_profile();
      } else {
        toast.error(res.message);
        setP_loading(false);
      }
    } catch (error) {
      // console.log("network", error);
      setP_loading(false);
    }
  };

  useEffect(() => {
    setP_data(data[0]?.profile[0]);
    list(
      state_list?.find(
        (v) =>
          v.label.toUpperCase() ==
          data[0]?.profile[0]?.state_personal?.toUpperCase()
      )?.value,
      setState_dist3
    );
  }, [data]);

  const getOtp = (no, load) => {
    setOtp_loading(load);
    get_otp(no).then((data) => {
      if (data.success) {
        setOtp_loading("0");
        load == "1" ? setOtp1_show(true) : setOtp2_show(true);
        toast.success(data.message);
      } else {
        toast.error(data.message);
        setOtp_loading("0");
      }
    });
  };
  return (
    <>
      {/* <h4 style={{ marginTop: "20px" }}>Personal Information</h4> */}
      <div className={"df alc sb"}>
        <h4>Personal Information</h4>
        <ButtonComp
          tittle={edit ? "Cancel" : "Edit"}
          icon={!edit ? <FiEdit2 /> : ""}
          onClick={() => {
            setEdit(!edit);
          }}
        />
      </div>
      <CustomInput
        textonly={!edit}
        textonly1={!edit}
        label1={"Email ID"}
        type1={"email"}
        // autoCapitalize1={"none"}
        onChange1={(e) => {
          let updatedValue = {};
          updatedValue = { email_personal: e.target.value.toLowerCase() };
          setP_data((pre) => ({ ...pre, ...updatedValue }));
        }}
        value1={p_data?.email_personal}
        label2={"Mobile Number"}
        value2={p_data?.mobile_personal}
        onChange2={(e) => {
          let updatedValue = {};
          updatedValue = {
            mobile_personal: e.target.value.toString().substring(0, 10),
          };
          setP_data((pre) => ({ ...pre, ...updatedValue }));
        }}
        right1={
          p_data?.email_verify2 === 1 && <MdOutlineVerified color="green" />
        }
        button1={
          p_data?.email_verify2 === 0 ? (
            <ButtonComp
              tittle={"Verify"}
              b_color={"green"}
              loading={email_loading == p_data?.email_personal ? true : false}
              onClick={() => verifyEmail(p_data?.email_personal)}
            ></ButtonComp>
          ) : (
            p_data?.email_personal != p_data?.email_personal && (
              <ButtonComp
                tittle={"Verify"}
                b_color={"green"}
                loading={email_loading == p_data?.email_personal ? true : false}
                onClick={() => verifyEmail(p_data?.email_personal)}
              ></ButtonComp>
            )
          )
        }
        right2={
          p_data?.mobile_verify2 === 1 && <MdOutlineVerified color="green" />
        }
        button2={
          p_data?.mobile_personal !== p_data?.mobile_personal && (
            <ButtonComp
              tittle={"Verify"}
              b_color={"green"}
              loading={otp_loading == "1" ? true : false}
              onClick={() => getOtp(p_data.mobile_personal, "2")}
            ></ButtonComp>
          )
        }
        bottom2={
          otp2_show && (
            <div className={"otp_container"}>
              <OtpInput
                value={otp2}
                onChange={(e) => setOtp2(e)}
                numInputs={4}
                inputStyle={"inputStyle"}
                separator={<span style={{ margin: 5 }}> </span>}
              />
            </div>
          )
        }
      />

      <CustomInput
        textonly={!edit}
        textonly1={!edit}
        area1
        label1={"Address"}
        onChange1={(e) => {
          let updatedValue = {};
          updatedValue = { address_personal: e.target.value };
          setP_data((pre) => ({ ...pre, ...updatedValue }));
        }}
        value1={p_data?.address_personal}
        label2={"State"}
        // value2={p_data.state_personal}
        menuPlacement2={"top"}
        drop2={edit}
        value2={
          !edit
            ? p_data?.state_personal
            : state_list.filter(
                (option) => option.label === p_data.state_personal
              )
        }
        options2={state_list}
        onChange2={(e) => {
          list(e.value, setState_dist3);
          let updatedValue = {};
          updatedValue = { state_personal: e.label };
          setP_data((pre) => ({ ...pre, ...updatedValue }));
        }}
      />

      <CustomInput
        textonly={!edit}
        textonly1={!edit}
        label1={"District"}
        menuPlacement1={"top"}
        drop1={edit}
        onChange1={(e) => {
          let updatedValue = {};
          updatedValue = { district_personal: e.label };
          setP_data((pre) => ({ ...pre, ...updatedValue }));
        }}
        value1={
          !edit
            ? p_data?.district_personal
            : state_dist3.filter(
                (option) =>
                  option.label.toUpperCase() ===
                  p_data?.district_personal.toUpperCase()
              )
        }
        options1={state_dist3}
        maxLength2={6}
        type2={"number"}
        label2={"Pincode"}
        onChange2={(e) => {
          e.target.value.length == 6 &&
            pincode_verify(
              e.target.value,
              state_list.find(
                (v) =>
                  v.label.toUpperCase() == p_data?.state_personal.toUpperCase()
              )?.value,
              "3"
            );
          let updatedValue = {};
          updatedValue = {
            pincode_personal: e.target.value.toString().substring(0, 6),
          };
          setP_data((pre) => ({ ...pre, ...updatedValue }));
        }}
        value2={!edit ? p_data?.pincode_personal : p_data?.pincode_personal}
        right2={
          pin_loading == "3" && (
            <Oval
              height={18}
              width={18}
              color="#169e49"
              wrapperStyle={{}}
              // wrapperClass={style.loader}
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#fff"
              strokeWidth={7}
              strokeWidthSecondary={7}
            />
          )
        }
      />

      {edit && (
        <div className="df alc jcc">
          <ButtonComp
            top={20}
            tittle={"Update"}
            loading={p_loading}
            onClick={submit_personal}
          />
        </div>
      )}
    </>
  );
};

export default Personal_profile;

import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { AiOutlinePlus } from "react-icons/ai";
import ButtonComp from "../../components/ButtonComp";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TableView from "../../components/custom/TableView";
import { TableCell, TableRow } from "@mui/material";
import { FiEdit2, FiEye } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
import { isSmallScreen, Loader, No_data, user_id } from "../../utils/utils";
import { delete_godown, get_godown_list } from "../../redux/slice/godownSlice";
import { Delete_dialog } from "../../utils/DialogBox";
const headerData = [
  "S. No.",
  "Name",
  "Address",
  "License No.",
  "Capacity (kgs)",
  "Validity",
  "Actions",
];
const Godown = () => {
  const { godown_list, g_loading } = useSelector((state) => state.godown);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");

  useEffect(() => {
    dispatch(get_godown_list());
  }, []);
  console.log(id);
  const del_godown = () => {
    setOpen(false);
    const body = new FormData();
    body.append("userid", user_id);
    body.append("godownid", id);
    body.append("action", 1);
    dispatch(delete_godown(body));
  };
  return (
    <>
      <div className={"card df padding-xy sb alc mb10"}>
        <div
          className={
            "border-gray-200 bg-gray-100 padding-xy alc df br search-bar "
          }
        >
          <input
            className="flex"
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <BiSearch />
        </div>
        <div className="flex"></div>
        <ButtonComp
          //   onClick={() => navigate("/godowndetail")}
          onClick={() => navigate("/godowns/add-godown")}
          tittle={isSmallScreen ? "Add" : "Add Godown"}
          icon={<AiOutlinePlus />}
        />
      </div>

      {godown_list.length == 0 && g_loading ? (
        <div className="card p20 df alc jcc">
          <Loader />
        </div>
      ) : godown_list.length == 0 ? (
        <No_data />
      ) : (
        <TableView
          headerData={headerData}
          bodyData={godown_list
            .filter((item) => {
              return item.name.toLowerCase().includes(search.toLowerCase());
            })
            .map((v, i) => (
              <TableRow key={i}>
                <TableCell align="center">{i + 1}</TableCell>
                <TableCell align="center">{v.name}</TableCell>
                <TableCell align="center">{v.address}</TableCell>
                <TableCell align="center">{v.license}</TableCell>
                <TableCell align="center">{v.capacity}</TableCell>
                <TableCell align="center">{v.validity}</TableCell>
                <TableCell align="center">
                  <FiEye
                    size={20}
                    className={"icons black"}
                    onClick={() =>
                      navigate("/godowns/godown-detail/" + v.id, {
                        state: { edit: false },
                      })
                    }
                  />
                  <FiEdit2
                    className={"icons view"}
                    size={20}
                    onClick={() =>
                      navigate("/godowns/godown-detail/" + v.id, {
                        state: { edit: true },
                      })
                    }
                  />
                  <IoTrashOutline
                    className={"icons trash"}
                    onClick={() => {
                      setId(v.id);
                      setOpen(true);
                    }}
                    size={20}
                  />
                </TableCell>
              </TableRow>
            ))}
        />
      )}
      <Delete_dialog
        open={open}
        setOpen={setOpen}
        onClick_delete={() => del_godown()}
      />
    </>
  );
};

export default Godown;

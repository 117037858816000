import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  get_deliverd_list,
  get_new_order_list,
  get_processsing_list,
  get_rejected_list,
} from "../../redux/slice/orderSlice";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import New_order from "./New_order";
import Processing from "./Processing";
import Delivered from "./Delivered";
import Reject from "./Reject";
import store from "../../redux/store";
import { setActive_Tab } from "../../redux/slice/userSlice";

const Orders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { active_tab } = useSelector((state) => state.user);
  const [value, setValue] = React.useState("1");
  const [activeIndex, setActiveIndex] = useState(1);
  const handleChange = (event, newValue) => {
    // setValue(newValue);
    store.dispatch(setActive_Tab(newValue.toString()));
  };
  useEffect(() => {
    dispatch(get_new_order_list());
    dispatch(get_processsing_list());
    dispatch(get_deliverd_list());
    dispatch(get_rejected_list());
  }, []);
  const tabStyle = {
    default_tab: {
      color: "#000",
      backgroundColor: "#FFFFFF",
      fontSize: 15,
    },
    active_tab: {
      color: "#169e49",
      backgroundColor: "#FFFFFF",
      fontSize: 15,
    },
  };
  const getStyle = (isActive) => {
    return isActive ? tabStyle.active_tab : tabStyle.default_tab;
  };
  return (
    <>
      <div className="over_hidden " style={{ padding: 1 }}>
        <TabContext value={active_tab}>
          <TabList
            className="card"
            onChange={handleChange}
            variant="scrollable"
            // aria-label="lab API tabs example"
            // visibleScrollbar
            allowScrollButtonsMobile
            TabIndicatorProps={{
              style: {
                backgroundColor: "#169e49",
              },
            }}
          >
            <Tab
              style={getStyle(active_tab == 1)}
              label="New"
              // onClick={() => setActiveIndex(1)}
              value={"1"}
            />
            <Tab
              style={getStyle(active_tab == 2)}
              label="Processing"
              // onClick={() => setActiveIndex(2)}
              value={"2"}
            />
            <Tab
              style={getStyle(active_tab == 3)}
              label="Delivered"
              // onClick={() => setActiveIndex(3)}
              value={"3"}
            />

            <Tab
              style={getStyle(active_tab == 4)}
              label="Reject"
              // onClick={() => setActiveIndex(4)}
              value={"4"}
            />
          </TabList>
          <div className="mt20">
            <TabPanel style={{ padding: 0 }} value="1">
              <New_order />
            </TabPanel>
            <TabPanel style={{ padding: 0 }} value="2">
              <Processing />
            </TabPanel>
            <TabPanel style={{ padding: 0 }} value="3">
              <Delivered />
            </TabPanel>
            <TabPanel style={{ padding: 0 }} value="4">
              <Reject />
            </TabPanel>
          </div>
        </TabContext>
      </div>
    </>
  );
};

export default Orders;

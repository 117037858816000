import moment from "moment";
import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonComp from "../../components/ButtonComp";
import CustomInput from "../../components/custom/CustomInput";
import { base_url, ingress, user_id } from "../../utils/utils";

const AddAllotments = () => {
  const navigate = useNavigate();
  const { driver_list } = useSelector((state) => state.driver);
  const { vehicle_list } = useSelector((state) => state.vehicle);

  const [a_data, setA_data] = useState({
    d_id: "",
    v_no: "",
    datetime: "",
  });
  // console.log(moment(a_data.datetime).format("YYYY-MM-DD HH:mm:ss"));
  const driver_dropdown = [];
  for (let i = 0; i < driver_list.length; i++) {
    const element = {
      value: driver_list[i].id,
      label: driver_list[i].name,
    };
    driver_dropdown.push(element);
  }
  const vehicle_dropdown = [];
  for (let i = 0; i < vehicle_list.length; i++) {
    const element = {
      value: vehicle_list[i].vehicle,
      label: vehicle_list[i].vehicle,
    };
    vehicle_dropdown.push(element);
  }

  const add_allotment = async () => {
    if (!a_data.d_id || !a_data.v_no || !a_data.datetime) {
      toast.info("Please fill all the fields");
      return;
    }
    try {
      const body = new FormData();
      body.append("userid", user_id);
      body.append("pilotid", a_data.d_id);
      body.append("vehicle", a_data.v_no);
      body.append(
        "datetime",
        moment(a_data.datetime).format("YYYY-MM-DD HH:mm:ss")
      );
      body.append("action", 1);
      body.append("ingress", ingress);
      const response = await fetch(base_url + "pilot_manage", {
        method: "POST",
        body: body,
      });
      const res = await response.json();
      // console.log(res);
      if (res.success) {
        toast.success(res.message);
        navigate(-1);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  // var today = new Date().toISOString().split("T")[0];

  return (
    <>
      <div className="card padding-xy mb10 df alc sb">
        <div className="heading-text">Add Allotment</div>
        <ButtonComp
          b_color={"black"}
          icon={<BiArrowBack color="#fff" size={16} />}
          onClick={() => navigate(-1)}
          tittle={"Back"}
        />
      </div>

      <div className={"card padding-xy"}>
        <CustomInput
          label1={"Driver"}
          drop1
          options1={driver_dropdown}
          onChange1={(e) => setA_data({ ...a_data, d_id: e.value })}
          label2={"Vehicle Number"}
          drop2
          options2={vehicle_dropdown}
          onChange2={(e) => setA_data({ ...a_data, v_no: e.value })}
        />
        <CustomInput
          label1={"Date time"}
          id1={"datePickerId"}
          type1={"datetime-local"}
          placeholder1={"Enter date time"}
          value1={a_data.datetime}
          min1={new Date().toISOString().slice(0, -8)}
          onChange1={(e) => setA_data({ ...a_data, datetime: e.target.value })}
          single
        />
        <div className="df jcc mt20">
          <ButtonComp tittle={"Submit"} onClick={() => add_allotment()} />
        </div>
      </div>
    </>
  );
};

export default AddAllotments;

import { Dialog } from "@mui/material";
import React, { useState } from "react";
import { GrClose } from "react-icons/gr";
import { toast } from "react-toastify";
import ButtonComp from "../../components/ButtonComp";
import Add_Doc from "../../components/custom/Add_doc";
import CustomInput from "../../components/custom/CustomInput";
import { base_url, user_id } from "../../utils/utils";

const AlliedProduct = ({ open, setOpen }) => {
  const [pro, setPro] = useState({
    name: "",
    hsn: "",
    gst: "",
    category: "",
    capacity: "",
    aop: "",
    specification: "",
    uom: "",
    top_img: {},
    side_img: {},
    front_img: {},
    loading: false,
  });
  const handleClose = () => setOpen(false);
  const add_allied_products = async () => {
    if (
      !pro.name ||
      !pro.hsn ||
      !pro.specification ||
      !pro.aop ||
      !pro.capacity ||
      !pro.category ||
      !pro.uom ||
      !pro.side_img ||
      !pro.front_img ||
      !pro.top_img ||
      !pro.gst
    ) {
      toast.info("Please fill all fields");
      return;
    }
    setPro({ ...pro, loading: true });
    try {
      const body = new FormData();
      body.append("userid", user_id);
      body.append("name", pro.name);
      body.append("hsncode", pro.hsn);
      body.append("gst", pro.gst);
      body.append("category", pro.category);
      body.append("capacity", pro.capacity);
      body.append("units", pro.uom);
      body.append("aop", pro.aop);
      body.append("specification", pro.specification);
      body.append("document0", pro.front_img);
      body.append("document1", pro.top_img);
      body.append("document2", pro.side_img);

      const response = await fetch(base_url + "create-alliedproducts", {
        body: body,
        method: "POST",
      });
      const res = await response.json();
      setPro({ ...pro, loading: false });

      if (res.success) {
        handleClose();
        setPro({
          ...pro,
          name: "",
          hsn: "",
          gst: "",
          category: "",
          capacity: "",
          aop: "",
          specification: "",
          uom: "",
          top_img: {},
          side_img: {},
          front_img: {},
        });
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      setPro({ ...pro, loading: false });
    }
  };
  return (
    <div>
      <Dialog open={open} fullWidth maxWidth={"md"}>
        <div className="db flex ">
          <div className="df alc sb p20">
            <div className="title-text">Allied Product</div>

            <GrClose
              onClick={() => handleClose()}
              size={20}
              className={"cursor"}
            />
          </div>
          <div className=" p20">
            <CustomInput
              label1={"Product name"}
              placeholder1={"Product name"}
              value1={pro.name}
              onChange1={(e) => setPro({ ...pro, name: e.target.value })}
              label2={"HSN code"}
              placeholder2={"Enter HSM code"}
              value2={pro.hsn}
              onChange2={(e) => setPro({ ...pro, hsn: e.target.value })}
            />
            <CustomInput
              label1={"GST Percentage"}
              placeholder1={"Enater GST Percentage"}
              value1={pro.gst}
              onChange1={(e) => setPro({ ...pro, gst: e.target.value })}
              label2={"Category"}
              placeholder2={"Enter Category"}
              value2={pro.category}
              onChange2={(e) => setPro({ ...pro, category: e.target.value })}
            />
            <CustomInput
              label1={"Capacity"}
              placeholder1={"Enter Capacity"}
              type1={"number"}
              value1={pro.capacity}
              onChange1={(e) => setPro({ ...pro, capacity: e.target.value })}
              label2={"Units"}
              placeholder2={"Enter Product uom"}
              value2={pro.uom}
              onChange2={(e) => setPro({ ...pro, uom: e.target.value })}
            />
            <CustomInput
              label1={"Specifications or Description"}
              placeholder1={"Enter Specifications or Description"}
              area1
              value1={pro.specification}
              onChange1={(e) =>
                setPro({ ...pro, specification: e.target.value })
              }
              area2
              label2={"Application of the Product"}
              placeholder2={"Enter Application of the Product"}
              value2={pro.aop}
              onChange2={(e) => setPro({ ...pro, aop: e.target.value })}
            />

            <div style={{ marginTop: 10 }}>Upload Images</div>
            <div className="input-df flex gap20">
              <Add_Doc
                filename={pro.top_img.name}
                onChange={(e) => setPro({ ...pro, top_img: e.target.files[0] })}
                title={"Top View"}
              />
              <Add_Doc
                filename={pro.side_img.name}
                onChange={(e) =>
                  setPro({ ...pro, side_img: e.target.files[0] })
                }
                title={"Side View"}
              />
            </div>
            <div className="input-df flex gap20">
              <Add_Doc
                filename={pro.front_img.name}
                onChange={(e) =>
                  setPro({ ...pro, front_img: e.target.files[0] })
                }
                title={"Front View"}
              />
              <div className="flex"></div>
            </div>
            <div className="df jcc mt20">
              <ButtonComp
                tittle={"Submit"}
                loading={pro.loading == true ? true : false}
                onClick={() => add_allied_products()}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AlliedProduct;

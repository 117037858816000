import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { base_url } from "../../utils/utils";
import store from "../store";

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
const user_id = user?.userid;
const ingress = user?.access;

export const get_godown_list = createAsyncThunk("godown", async () => {
  const body = new FormData();
  body.append("userid", user_id);
  body.append("ingress", ingress);

  const response = await fetch(base_url + "godownlist", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});
export const delete_godown = createAsyncThunk("godown", async (body) => {
  const response = await fetch(base_url + "delete_godown", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  console.log("del", res);
  if (res.success) {
    toast.success(res.message);
    store.dispatch(get_godown_list());
  } else {
    toast.error(res.message);
  }
});

export const create_godown = createAsyncThunk(
  "create",
  async (body, setloading) => {
    setloading("create");
    const response = await fetch(base_url + "creategodown", {
      body: body,
      method: "POST",
    });
    const res = await response.json();
    console.log(res);
    setloading("");
    if (res.success) {
      toast.success(res.message);
      store.dispatch(get_godown_list());
      return true;
    } else {
      toast.error(res.message || "Something went wrong");
      return false;
    }
  }
);

const godownSlice = createSlice({
  initialState: {
    godown_list: [],
    g_loading: false,
  },
  name: "godown",
  extraReducers: (builder) => {
    builder.addCase(get_godown_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.g_loading = false;
      if (data.success) {
        state.godown_list = data.data;
      } else {
        state.godown_list = [];
      }
    });
    builder.addCase(get_godown_list.pending, (state, action) => {
      state.g_loading = true;
    });
  },
});

export default godownSlice.reducer;

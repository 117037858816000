import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
const user_id = user?.userid;
const ingress = user?.access;

export const get_product_list = createAsyncThunk("product", async () => {
  const body = new FormData();
  body.append("userid", user_id);
  // body.append("ingress", ingress);

  const response = await fetch(base_url + "productlistbyid", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});
export const get_all_products = createAsyncThunk("productall", async () => {
  const body = new FormData();
  body.append("userid", user_id);
  body.append("ingress", ingress);

  const response = await fetch(base_url + "productlist", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});
const productSlice = createSlice({
  initialState: {
    product_list: [],
    all_products: [],
    p_loading: false,
  },
  name: "godown",
  extraReducers: (builder) => {
    builder.addCase(get_product_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.p_loading = false;
      if (data.success) {
        state.product_list = data.data;
      } else {
        state.product_list = [];
      }
    });
    builder.addCase(get_all_products.fulfilled, (state, action) => {
      const res = action.payload;
      const data = [];
      for (let i = 0; i < res.data.length; i++) {
        const element = {
          value: res.data[i].uuid,
          label:
            res.data[i].description + "  " + "(" + `${res.data[i].type}` + ")",
        };
        data.push(element);
        state.all_products = data;
      }
    });
    builder.addCase(get_product_list.pending, (state, action) => {
      state.p_loading = true;
    });
  },
});

export default productSlice.reducer;

import React, { useEffect, useState } from "react";
import { BiSupport } from "react-icons/bi";
import { Drawer } from "@mui/material";
import { GiHamburgerMenu } from "react-icons/gi";
import css from "./header.module.scss";
import Notification from "./Notification";
import ContactUs from "./ContactUs";
import Report from "./Report";
import SidebarItems from "../sidebar/SidebarItems";
import ProfileIcon from "./ProfileIcon";
import { GrBug } from "react-icons/gr";

const Header = () => {
  const [open_con, setOpen_con] = useState(false);
  const [open_rep, setOpen_rep] = useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={css.container}>
      <div className={css.left}>
        <GiHamburgerMenu
          size={25}
          color={"#0175c2"}
          className={css.menu_icon}
          onKeyDown={() => handleDrawerToggle()}
          onClick={() => handleDrawerToggle()}
        />
      </div>
      <div style={{ flex: 1 }}></div>

      <div className={" alc gap20 show_p"}>
        <Notification />
        <ContactUs open={open_con} setOpen={setOpen_con} />
        <BiSupport
          className="cursor"
          size={20}
          onClick={() => setOpen_con(true)}
        />
        <Report open={open_rep} setOpen={setOpen_rep} />
        <GrBug className="cursor" size={20} onClick={() => setOpen_rep(true)} />
        <ProfileIcon />
      </div>
      <div className="hide_p">
        <ProfileIcon />
      </div>

      <Drawer
        draggable
        sx={{
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: "13rem" },
        }}
        open={mobileOpen}
        onClose={handleDrawerToggle}
      >
        <SidebarItems
          setClose={handleDrawerToggle}
          onclick={handleDrawerToggle}
        />
      </Drawer>
    </div>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import ButtonComp from "../../components/ButtonComp";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { get_pilot_order_detail } from "../../utils/api";
import { Loader, ingress, user_id } from "../../utils/utils";
import SingleInput from "../../components/custom/SingleInput";
import { FiEye } from "react-icons/fi";
import TableView from "../../components/custom/TableView";

const OrderDetail2 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const get_detail = () => {
    const body = new FormData();
    body.append("userid", user_id);
    body.append("ingress", ingress);
    body.append("orderid", location.state.orderid);
    get_pilot_order_detail(body, setLoading).then((v) => {
      if (v.success) {
        setData(v.data[0]);
      } else {
      }
    });
  };
  useEffect(() => {
    get_detail();
  }, []);

  const total_price = () =>
    data?.products?.reduce((sum, product) => sum + Number(product.rsp), 0);
  const total_deposit = () =>
    data?.products?.reduce(
      (sum, product) =>
        sum +
        Number(product.deposit[0]?.amount) * Number(product.deposit[0]?.qty),
      0
    );
  const total = () =>
    data?.products?.reduce(
      (sum, product) =>
        sum +
        (Number(product?.deposit[0]?.amount) *
          Number(product?.deposit[0]?.qty) +
          Number(product?.rsp)),
      0
    );

  return (
    <>
      <div className="card padding-xy mb10 df alc sb">
        <div className="heading-text">Order Detail</div>
        <ButtonComp
          b_color={"black"}
          icon={<BiArrowBack color="#fff" size={16} />}
          onClick={() => navigate(-1)}
          tittle={"Back"}
        />
      </div>
      {loading ? (
        <div className="card p20 alc jcc df">
          <Loader />
        </div>
      ) : (
        Object.keys(data).length != 0 && (
          <div className={"input-df gap10 flex"}>
            <div className={"card p20 ass mb10"} style={{ flex: 1 / 2 }}>
              <div className="fs16 fw600 orange pb6">{data?.orderid}</div>
              <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Name :</div>
                <div className="fs14 fw400 black">{data?.address[0]?.name}</div>
              </div>
              <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Mobile :</div>
                <div className="fs14 fw400 black">
                  {data?.address[0]?.mobile}
                </div>
              </div>
              <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Schedule Date :</div>
                <div className="fs14 fw400 orange">{data?.scheduledate}</div>
              </div>
              <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Status :</div>
                <div className="fs14 fw400 orange">{data?.orderstatustext}</div>
              </div>
              <div className="df alc gap10 pb6">
                <div
                  className="df fs14 fw400 black gap5"
                  style={{ alignSelf: "start" }}
                >
                  Address <div> :</div>
                </div>
                <div className="fs14 fw400 black">
                  {data?.address[0]?.address +
                    "," +
                    data?.address[0]?.landmark +
                    "," +
                    data?.address[0]?.district +
                    "," +
                    data?.address[0]?.pincode +
                    "," +
                    data?.address[0]?.state}
                </div>
              </div>
              <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Datetime :</div>
                <div className="fs14 fw400 black">{data?.datetime}</div>
              </div>
              <div className="df alc sb pb6">
                <div className="fs14 fw400 black">Delivery Challan </div>
                <FiEye className="cursor" size={18} />
              </div>
              <div className="df alc sb pb6">
                <div className="fs14 fw400 black">E-Invoice</div>
                <FiEye className="cursor" size={18} />
              </div>
              <div className="df alc sb pb6">
                <div className="fs14 fw400 black">E-Way Bill</div>
                <FiEye className="cursor" size={18} />
              </div>
            </div>
            <div className="flex">
              <div className={"card p20 flex"}>
                <div className="fs16 fw600 black pb6">Delivery Check</div>
                <table className="inv_table">
                  <tr className="inv_tr">
                    <th className="inv_td">Name</th>
                    <th className="inv_td">Qty</th>
                    <th className="inv_td">Dp.cyln</th>
                    <th className="inv_td">Price</th>
                    <th className="inv_td">Deposit</th>
                    <th className="inv_td">Total</th>
                  </tr>
                  {data?.products?.map((v, i) => {
                    return (
                      <tr className="inv_tr" key={i}>
                        <td className="inv_td"> {v.description}</td>
                        <td className="inv_td">{v.count}</td>
                        <td className="inv_td">{v?.deposit[0]?.qty}</td>
                        <td className="inv_td">{Number(v?.rsp).toFixed(1)}</td>
                        <td className="inv_td">{v?.deposit[0]?.amount}</td>
                        <td className="inv_td">
                          {(
                            Number(v?.deposit[0]?.amount) *
                              Number(v?.deposit[0]?.qty) +
                            Number(v?.rsp)
                          ).toFixed(1)}
                        </td>
                      </tr>
                    );
                  })}

                  <tr className="inv_tr">
                    <th className="inv_td">Total</th>
                    <th className="inv_td">{}</th>
                    <th className="inv_td">{}</th>
                    <th className="inv_td">{total_price().toFixed(1)}</th>
                    <th className="inv_td">{total_deposit().toFixed(1)}</th>
                    <th className="inv_td">{total().toFixed(1)}</th>
                  </tr>
                </table>
                <div className="df alc sb gap10 pb6 mt10">
                  <div className="fs16 fw600 black">Total Amount </div>
                  <div className="fs16 fw700 green">₹ {total().toFixed(1)}</div>
                </div>
                <SingleInput
                  label1={"Transaction ID"}
                  placeholder1={"Enter Transaction ID"}
                />
                <SingleInput label1={"OTP"} placeholder1={"Enter OTP"} />
                <div className="df alc jcc mt10">
                  <ButtonComp tittle={"Get OTP"} />
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default OrderDetail2;

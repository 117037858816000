import React, { useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { toast } from "react-toastify";
import ButtonComp from "../../components/ButtonComp";
import CustomInput from "../../components/custom/CustomInput";
import { change_Password } from "../../utils/api";

const ChangePassword = () => {
  const [show, setShow] = useState({
    pass1: false,
    pass2: false,
    pass3: false,
    password: "",
    new_password: "",
    confirm_password: "",
  });
  const [loading, setLoading] = useState(false);

  const change = () => {
    if (!show.password || !show.new_password || !show.confirm_password) {
      toast.info("Please fill all fields");
      return;
    }
    if (show.new_password !== show.confirm_password) {
      toast.info("new password does not match confirm password");
      return;
    }
    change_Password(show.password, show.new_password, setLoading);
  };
  return (
    <div>
      <div className="h2_heading">Change Password</div>
      <CustomInput
        label1={"Old Password"}
        placeholder1={"Enter your old password"}
        type1={show.pass1 ? "text" : "password"}
        value1={show.password}
        onChange1={(e) => setShow({ ...show, password: e.target.value })}
        right1={
          show.pass1 ? (
            <BsEyeSlash
              size={16}
              style={{ cursor: "pointer" }}
              onClick={() => setShow({ ...show, pass1: !show.pass1 })}
            />
          ) : (
            <BsEye
              size={16}
              style={{ cursor: "pointer" }}
              onClick={() => setShow({ ...show, pass1: !show.pass1 })}
            />
          )
        }
        label2={"New Password"}
        type2={show.pass2 ? "text" : "password"}
        placeholder2={"Enter your new password"}
        value2={show.new_password}
        onChange2={(e) => setShow({ ...show, new_password: e.target.value })}
        right2={
          show.pass2 ? (
            <BsEyeSlash
              size={16}
              style={{ cursor: "pointer" }}
              onClick={() => setShow({ ...show, pass2: !show.pass2 })}
            />
          ) : (
            <BsEye
              size={16}
              style={{ cursor: "pointer" }}
              onClick={() => setShow({ ...show, pass2: !show.pass2 })}
            />
          )
        }
      />
      <CustomInput
        label1={"Confirm Password"}
        placeholder1={"Enter your confirm password"}
        type1={show.pass3 ? "text" : "password"}
        value1={show.confirm_password}
        onChange1={(e) =>
          setShow({ ...show, confirm_password: e.target.value })
        }
        right1={
          show.pass3 ? (
            <BsEyeSlash
              size={16}
              style={{ cursor: "pointer" }}
              onClick={() => setShow({ ...show, pass3: !show.pass3 })}
            />
          ) : (
            <BsEye
              size={16}
              style={{ cursor: "pointer" }}
              onClick={() => setShow({ ...show, pass3: !show.pass3 })}
            />
          )
        }
        single
      />
      <div className="df alc mt20 jcc">
        <ButtonComp
          tittle={"Upade"}
          loading={loading}
          onClick={() => change()}
        />
      </div>
    </div>
  );
};

export default ChangePassword;

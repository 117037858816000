import React, { useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import { IoTrashOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonComp from "../../components/ButtonComp";
import { get_partner_profile, get_vendor_profile } from "../../utils/api";
import { bank_action } from "../../utils/apis";
import { Delete_dialog } from "../../utils/DialogBox";
import { ingress, No_data, user_id } from "../../utils/utils";

const P_BankInfo = () => {
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.user);
  const [bank, setBank] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const delete_bank = () => {
    setOpen(false);
    bank_action(3, "", "", "", "", "", "", id, setLoading).then((data) => {
      ingress == 4 && get_partner_profile();
      ingress == 1 && get_vendor_profile();
      (ingress == 1 || ingress == 2) && setBank(data[0]?.bankdetails);
    });
  };
  useEffect(() => {
    // ingress == 4 && get_partner_profile();
    // ingress == 1 && get_vendor_profile();
    ingress == 4 && setBank(data[0]?.bankdetails);
    (ingress == 1 || ingress == 2) && setBank(data[0]?.bankdetails);
  }, [data]);
  return (
    <div className={""}>
      <div className={"df alc sb mb10"}>
        <div className="h2_heading">Bank Information</div>
        <ButtonComp
          tittle={"Add"}
          icon={<IoMdAdd />}
          onClick={() =>
            navigate("/profile/add-bank-account", { state: { edit: false } })
          }
        />
      </div>
      {data == null ? (
        <No_data />
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: window.matchMedia("(max-width: 500px)").matches
              ? "repeat(1, 1fr)"
              : "repeat(2, 1fr)",
            gridGap: 20,
            marginTop: 20,
          }}
        >
          {bank?.map((v, i) => (
            <div className="card p20" key={i}>
              <div className={"df float-r gap10"}>
                <FiEdit2
                  size={20}
                  className="cursor icons view"
                  onClick={() =>
                    navigate("/profile/add-bank-account", {
                      state: { data: v, edit: true },
                    })
                  }
                />
                <IoTrashOutline
                  size={20}
                  className="cursor icons trash"
                  onClick={() => {
                    setId(v.id);
                    setOpen(true);
                  }}
                />
              </div>
              <div className="fs16 fw600 black  pb6">{v.name}</div>
              <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Bank Name:</div>
                <div className="fs14 fw400 black">{v.bankname}</div>
              </div>
              <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Account Number:</div>
                <div className="fs14 fw400 black">{v.account}</div>
              </div>
              <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Branch Name:</div>
                <div className="fs14 fw400 black">{v.branch}</div>
              </div>
              <div className="df alc gap10 ">
                <div className="fs14 fw400 black">IFSC Code:</div>
                <div className="fs14 fw400 black">{v.ifsccode}</div>
              </div>
            </div>
          ))}
        </div>
      )}
      <Delete_dialog
        open={open}
        setOpen={setOpen}
        onClick_delete={() => delete_bank()}
      />
    </div>
  );
};

export default P_BankInfo;

import { async } from "@firebase/util";
import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonComp from "../../components/ButtonComp";
import Add_Doc from "../../components/custom/Add_doc";
import { base_url, user_id } from "../../utils/utils";

const Add_partner_Doc = () => {
  const navigate = useNavigate();
  const [docList, setDocList] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleFileChange = (e, i) => {
    if (Object.entries(docList[i]).length == 0) {
      toast.error("please enter document name");
      return;
    }
    let newitem_detail = docList.map((el, ind) =>
      ind === i
        ? {
            ...el,
            name: e.target.files[0].name,
            docData: e.target.files[0],
          }
        : el
    );
    setDocList(newitem_detail);
  };

  const filter_data = docList.filter(
    (v, i) => Object.entries(docList[i]).length != 0 && v?.name !== ""
  );

  const add_doc = async () => {
    try {
      setLoading(true);
      if (filter_data.length == 0) {
        toast.info("Please select documents");
        return;
      }
      const body = new FormData();
      body.append("userid", user_id);
      body.append("documentscount", filter_data.length);
      // body.append("ingress", ingress);
      body.append("names", filter_data.map((v) => v.filename).join(",,,"));
      body.append("action", 3);

      {
        filter_data.forEach((v, i) =>
          body.append(`${"document" + i}`, v.docData)
        );
      }
      const response = await fetch(base_url + "pilotprofileupdate", {
        method: "POST",
        body: body,
      });
      const res = await response.json();
      setLoading(true);
      console.log(res);
    } catch (error) {
      setLoading(true);
    }
  };
  return (
    <div>
      <div className={"df alc sb"}>
        <h3>Add Documents</h3>
        <ButtonComp
          icon={<BiArrowBack size={16} />}
          tittle={"Back"}
          b_color={"#000"}
          onClick={() => navigate(-1)}
        />
      </div>
      <div className={"df alc sb mt10"}>
        <div>Documents</div>
        <ButtonComp
          tittle={"Add"}
          onClick={() => setDocList((pre) => [...pre, {}])}
        />
      </div>
      {docList.map((v, i) => (
        <Add_Doc
          key={i}
          top={5}
          value={Object.entries(v).length === 0 ? "" : v.filename}
          onChange1={(e) => {
            let newitem_detail = docList.map((el, ind) =>
              ind === i
                ? {
                    ...el,
                    filename: e.target.value,
                    name: "",
                  }
                : el
            );
            setDocList(newitem_detail);
          }}
          placeholder="Enter document name"
          single
          onChange={(e) => handleFileChange(e, i)}
          filename={v.name}
          right={
            <ButtonComp
              top={4}
              tittle={"Delete"}
              b_color={"#f00"}
              onClick={() =>
                setDocList((pre) =>
                  pre.find((v, ind) => ind === i)
                    ? pre.filter((v, index) => index !== i)
                    : [...pre]
                )
              }
            />
          }
        />
      ))}
      <div className="df jcc p20">
        <ButtonComp
          tittle={"Submit"}
          loading={loading}
          //   onClick={() => add_doc()}
        />
      </div>
    </div>
  );
};

export default Add_partner_Doc;

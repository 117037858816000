import React, { useState } from "react";
import { toast } from "react-toastify";
import ButtonComp from "../../components/ButtonComp";
import { useNavigate } from "react-router-dom";
import { base_url, ingress, user_id } from "../../utils/utils";
import { BiArrowBack } from "react-icons/bi";
import CustomInput from "../../components/custom/CustomInput";
import Check from "../../components/Check";
import Add_Doc from "../../components/custom/Add_doc";

const AddVehicle = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [show_add_doc, setShow_add_doc] = useState(true);
  const [add_no, setAdd_no] = useState(0);
  const [c_input, setC_input] = useState("");
  const [docList, setDocList] = useState([]);
  const [vehicle_no, setVehicle_no] = useState("");
  const [chassis_no, setChassis_no] = useState("");
  const [make, setMake] = useState("");
  const [capacity, setCapacity] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e, i) => {
    if (Object.entries(docList[i]).length == 0) {
      toast.error("please enter document name");
      return;
    }
    let newitem_detail = docList.map((el, ind) =>
      ind === i
        ? {
            ...el,
            name: e.target.files[0].name,
            docData: e.target.files[0],
          }
        : el
    );
    setDocList(newitem_detail);
  };
  const filter_data = docList.filter(
    (v, i) => Object.entries(docList[i]).length != 0 && v?.name !== ""
  );

  const add_vehicle = async () => {
    if (!make || !capacity || !status || !chassis_no || !vehicle_no) {
      toast.info("Please fill all fields");
      return;
    }
    if (filter_data.length == 0) {
      toast.info("Please select documents");
      return;
    }
    try {
      const body = new FormData();
      body.append("userid", user_id);
      body.append("ingress", ingress);
      body.append("vehicle", vehicle_no);
      body.append("chassis", chassis_no);
      body.append("make", make);
      body.append("capacity", capacity);
      body.append("documentscount", filter_data.length);
      body.append("names", filter_data.map((v) => v.filename).join(",,,"));
      body.append("supply", status === "supply" ? 2 : 1);
      body.append("delivery", status == "delivery" ? 2 : 1);
      {
        filter_data.forEach((v, i) =>
          body.append(`${"document" + i}`, v.docData)
        );
      }

      setLoading(true);
      const response = await fetch(base_url + "createvehicle", {
        body: body,
        method: "POST",
      });
      const res = await response.json();

      setLoading(false);
      if (res.success) {
        toast.success(res.message);
        navigate(-1);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="card padding-xy mb10 df alc sb">
        <div className="heading-text">Add Vehicle</div>
        <ButtonComp
          b_color={"black"}
          icon={<BiArrowBack color="#fff" size={16} />}
          onClick={() => navigate(-1)}
          tittle={"Back"}
        />
      </div>

      <div className={"card padding-xy"}>
        <CustomInput
          label1={"Make"}
          type1="text"
          placeholder1={"Enter Make"}
          value1={make}
          onChange1={(e) => setMake(e.target.value)}
          label2={"Vehicle Number"}
          type2="text"
          placeholder2={"Enter vehicle number"}
          value2={vehicle_no}
          onChange2={(e) => setVehicle_no(e.target.value)}
        />
        <CustomInput
          label1={"Chassis Number"}
          type1="text"
          placeholder1={"Enter chassis number"}
          value1={chassis_no}
          onChange1={(e) => setChassis_no(e.target.value)}
          label2={"Carrier Capacity (19kgs)"}
          type2="number"
          placeholder2={"Enter capacity"}
          value2={capacity}
          onChange2={(e) => setCapacity(e.target.value)}
        />
        <div className="df alc gap20 p10 flex ">
          <Check
            tittle={"Supply"}
            onchange={() => setStatus("supply")}
            checked={status === "supply" ? true : false}
          />
          <Check
            tittle={"Delivery"}
            onchange={() => setStatus("delivery")}
            checked={status === "delivery" ? true : false}
          />
        </div>
        <div className={"df alc sb"}>
          <div>Documents</div>
          <ButtonComp
            tittle={"Add"}
            onClick={() => setDocList((pre) => [...pre, {}])}
          />
        </div>
        {docList.map((v, i) => (
          <Add_Doc
            key={i}
            top={5}
            value={v.filename}
            onChange1={(e) => {
              let newitem_detail = docList.map((el, ind) =>
                ind === i
                  ? {
                      ...el,
                      filename: e.target.value,
                      name: "",
                    }
                  : el
              );
              setDocList(newitem_detail);
            }}
            placeholder="Enter document name"
            single
            onChange={(e) => handleFileChange(e, i)}
            filename={v.name}
            right={
              <ButtonComp
                top={4}
                tittle={"Delete"}
                b_color={"#f00"}
                onClick={() =>
                  setDocList((pre) =>
                    pre.find((v, ind) => ind === i)
                      ? pre.filter((v, index) => index !== i)
                      : [...pre]
                  )
                }
              />
            }
          />
        ))}

        <div className="df jcc p10">
          <ButtonComp
            tittle={"Submit"}
            loading={loading}
            onClick={() => add_vehicle()}
          />
        </div>
      </div>
    </>
  );
};
export default AddVehicle;

import React, { useEffect, useState } from "react";
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import ButtonComp from "../../components/ButtonComp";
import { BiArrowBack } from "react-icons/bi";
import { FiEdit2 } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
import CustomInput from "../../components/custom/CustomInput";
import {
  check_pincode,
  create_godown,
  get_dist_list,
  godown_delete,
  godown_detail,
} from "../../utils/apis";
import { useDispatch, useSelector } from "react-redux";
import Add_Doc from "../../components/custom/Add_doc";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import { Loader, user_id } from "../../utils/utils";
import { Delete_dialog } from "../../utils/DialogBox";

const GodownDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { state_list } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState("");
  const [godown_data, setGodown_data] = useState({});
  const [godown_doc, setGodown_doc] = useState([]);
  const [doc_list, setDoc_list] = useState([]);
  const [dist_list, setDist_list] = useState([]);
  const [license_list, setLicense_list] = useState([]);
  const [edit, setEdit] = useState(location?.state?.edit);
  const [s_id, setS_id] = useState("");
  const [pincode_verified, setPincode_verified] = useState(null);
  const [act, setAct] = useState("");
  const [id, setId] = useState("");
  const new_doc_data = doc_list.filter(
    (value) => Object.keys(value).length !== 0
  );
  const new_license_data = license_list.filter(
    (value) => Object.keys(value).length !== 0
  );
  const delete_godown = () => {
    setOpen(false);
    godown_delete(user_id, act, id, setLoading).then((v) => {
      v ? navigate(-1) : get_godown();
    });
  };
  console.log(location);
  const checkPincode = (v) => {
    if (godown_data?.state == null) {
      toast.info("please select state");
      return;
    }
    check_pincode(
      state_list
        ?.find((val) => val.label.toUpperCase() == godown_data.state)
        ?.value.toUpperCase(),
      v,
      setLoading
    ).then((data) => setPincode_verified(data));
  };

  const update_godown = () => {
    if (pincode_verified == false) {
      toast.info("Please enter valid Pin Code");
      return;
    }
    // if (new_doc_data.length == 0 && new_license_data.length == 0) {
    //   toast.info("Please select license or documents");
    //   return;
    // }
    const body = new FormData();
    body.append("userid", user_id);
    body.append("name", godown_data.name);
    body.append("license", godown_data.license);
    body.append("capacity", godown_data.capacity);
    body.append(
      "address",
      godown_data.address +
        ",,," +
        godown_data.state +
        ",,," +
        godown_data.district +
        ",,," +
        godown_data.pincode
    );
    body.append("latitude", godown_data.latitude);
    body.append("longitude", godown_data.longitude);
    body.append("validity", godown_data.validity);
    body.append("documentscount", new_doc_data.length);

    body.append(
      "otherlicense",
      new_license_data.map((v) => v.l_no).join(",,,")
    );

    body.append(
      "othervalidity",
      new_license_data.map((v) => v.val).join(",,,")
    );
    new_doc_data.forEach((v, i) => body.append(`${"document" + i}`, v.docData));
    body.append("names", new_doc_data.map((v) => v.filename).join(",,,"));
    body.append("godownid", params.id);
    create_godown(body, setLoading).then((v) => v && navigate(-1));
  };

  const get_godown = () => {
    godown_detail(user_id, params.id, setLoading).then((v) => {
      v && setGodown_data(v.details[0]);
      setGodown_doc(v.documents);
      get_dist_list(
        state_list?.find(
          (val) => val.label.toUpperCase() == v.details[0].state.toUpperCase()
        )?.value,
        setDist_list
      );
    });
  };
  useEffect(() => {
    get_godown();
    // dispatch(getall_list());
  }, []);

  return (
    <>
      <div className={"card custom_input_df_button padding-xy sb alc mb10"}>
        <div className="heading-text">Godown Detail</div>
        <div className="df gap20">
          <ButtonComp
            onClick={() => setEdit(!edit)}
            tittle={edit ? "Cancel" : "Edit"}
            icon={<FiEdit2 />}
          />
          <ButtonComp
            onClick={() => {
              setAct(1);
              setId(params.id);
              setOpen(true);
            }}
            tittle={"Delete"}
            loading={loading == "all" ? true : false}
            b_color={"#f00"}
            icon={<IoTrashOutline />}
          />
          <ButtonComp
            onClick={() => navigate(-1)}
            tittle={"Back"}
            b_color={"#000"}
            icon={<BiArrowBack color="#fff" />}
          />
        </div>
      </div>
      {loading == "detail" ? (
        <div className="df jcc card p20">
          <Loader />
        </div>
      ) : (
        <div className="card ph20 ">
          <CustomInput
            textonly={!edit}
            textonly1={!edit}
            placeholder1={"Enter name"}
            placeholder2={"Enter license number"}
            label1={"Name"}
            value1={godown_data?.name}
            onChange1={(e) => {
              let updatedValue = {};
              updatedValue = { name: e.target.value };
              setGodown_data((pre) => ({ ...pre, ...updatedValue }));
            }}
            label2={"License Number"}
            value2={godown_data?.license}
            onChange2={(e) => {
              let updatedValue = {};
              updatedValue = { license: e.target.value };
              setGodown_data((pre) => ({ ...pre, ...updatedValue }));
            }}
          />
          <CustomInput
            textonly={!edit}
            textonly1={!edit}
            placeholder1={"Enter validity"}
            placeholder2={"Enter capacity"}
            label1={"Validity"}
            value1={godown_data?.validity}
            type1={"date"}
            onChange1={(e) => {
              let updatedValue = {};
              updatedValue = { validity: e.target.value };
              setGodown_data((pre) => ({ ...pre, ...updatedValue }));
            }}
            label2={"Storage capacity (kgs)"}
            value2={godown_data?.capacity}
            type2={"number"}
            onChange2={(e) => {
              let updatedValue = {};
              updatedValue = { capacity: e.target.value };
              setGodown_data((pre) => ({ ...pre, ...updatedValue }));
            }}
          />
          <CustomInput
            textonly={!edit}
            textonly1={!edit}
            placeholder1={"Enter address"}
            label1={"Address"}
            value1={godown_data?.address}
            onChange1={(e) => {
              let updatedValue = {};
              updatedValue = { address: e.target.value };
              setGodown_data((pre) => ({ ...pre, ...updatedValue }));
            }}
            area1={edit}
            label2={"State"}
            value2={
              !edit
                ? godown_data?.state
                : state_list?.filter(
                    (option) => option.label === godown_data?.state
                  )
            }
            drop2={edit}
            options2={state_list}
            onChange2={(e) => {
              get_dist_list(e.value, setDist_list);
              setS_id(e.value);
              let updatedValue = {};
              updatedValue = { state: e.label, pincode: "" };
              setGodown_data((pre) => ({ ...pre, ...updatedValue }));
            }}
          />
          <CustomInput
            textonly={!edit}
            textonly1={!edit}
            placeholder2={"Enter pincode"}
            options1={dist_list}
            disabled1={dist_list.length == 0 ? true : false}
            label1={"District"}
            value1={
              !edit
                ? godown_data?.district
                : dist_list.filter(
                    (option) =>
                      option.label.toUpperCase() ===
                      godown_data?.district.toUpperCase()
                  )
            }
            onChange1={(e) => {
              let updatedValue = {};
              updatedValue = { district: e.label };
              setGodown_data((pre) => ({ ...pre, ...updatedValue }));
            }}
            drop1={edit}
            label2={"Pincode"}
            value2={godown_data?.pincode}
            type2={"number"}
            right2={
              loading == "pin" && (
                <Oval
                  height={15}
                  width={15}
                  color="#747bff"
                  wrapperStyle={{}}
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#fff"
                  strokeWidth={7}
                  strokeWidthSecondary={7}
                />
              )
            }
            onChange2={(e) => {
              e.target.value.length == 6 && checkPincode(e.target.value);
              let updatedValue = {};
              updatedValue = {
                pincode: e.target.value.toString().substring(0, 6),
              };
              setGodown_data((pre) => ({ ...pre, ...updatedValue }));
            }}
          />
          <CustomInput
            textonly={!edit}
            textonly1={!edit}
            placeholder1={"Enter latitude"}
            placeholder2={"Enter longitute"}
            label1={"Latitute"}
            value1={godown_data?.latitude}
            onChange1={(e) => {
              let updatedValue = {};
              updatedValue = { latitude: e.target.value };
              setGodown_data((pre) => ({ ...pre, ...updatedValue }));
            }}
            label2={"Longitute"}
            value2={godown_data?.longitude}
            onChange2={(e) => {
              let updatedValue = {};
              updatedValue = { longitude: e.target.value };
              setGodown_data((pre) => ({ ...pre, ...updatedValue }));
            }}
          />
          <div className="mt20 df alc sb">
            <div>Documents</div>
            {edit && (
              <ButtonComp
                tittle={"Add"}
                onClick={() => setDoc_list((pre) => [...pre, {}])}
              />
            )}
          </div>
          <div>
            {godown_doc.map((v, i) => (
              <div className="df alc sb text_bg mt10" key={i}>
                <div>{v.filename}</div>
                <IoTrashOutline
                  color="#f00"
                  className="cursor"
                  onClick={() => {
                    setAct(2);
                    setId(v.id);
                    setOpen(true);
                  }}
                />
              </div>
            ))}
          </div>
          <div>
            {edit && (
              <div>
                {doc_list.map((v, i) => (
                  <div className="mt10" key={i}>
                    <Add_Doc
                      placeholder={"Enter file name"}
                      single
                      value={Object.entries(v).length === 0 ? "" : v?.filename}
                      filename={v?.docData?.name}
                      onChange1={(e) =>
                        setDoc_list((pre) =>
                          pre.map((val, ind) =>
                            i == ind
                              ? { ...val, filename: e.target.value }
                              : val
                          )
                        )
                      }
                      onChange={(e) => {
                        if (Object.entries(v).length === 0) {
                          toast.info("please add file name");
                          return;
                        }
                        setDoc_list((pre) =>
                          pre.map((val, ind) =>
                            i == ind
                              ? { ...val, docData: e.target.files[0] }
                              : val
                          )
                        );
                      }}
                      right={
                        <ButtonComp
                          tittle={"Delete"}
                          b_color="#f00"
                          onClick={() =>
                            setDoc_list((pre) =>
                              pre.find((val, ind) => ind == i)
                                ? pre.filter((val, ind) => ind !== i)
                                : [...pre]
                            )
                          }
                        />
                      }
                    />
                  </div>
                ))}
              </div>
            )}
          </div>

          {edit && (
            <div className="df jcc mt20">
              <ButtonComp
                tittle={"Submit"}
                loading={loading == "create" ? true : false}
                onClick={update_godown}
              />
            </div>
          )}
          <div className="mt20 df" />
          <Delete_dialog
            open={open}
            setOpen={setOpen}
            onClick_delete={() => delete_godown(params.id)}
          />
        </div>
      )}
    </>
  );
};

export default GodownDetail;

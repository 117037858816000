import { Dialog, Divider } from "@mui/material";
import React, { useState } from "react";
import { GrBug, GrClose } from "react-icons/gr";
import { toast } from "react-toastify";
import { contactus, report_api } from "../../utils/api";
import { ingress, user_id, valid_mobile } from "../../utils/utils";
import ButtonComp from "../ButtonComp";
import Add_Doc from "../custom/Add_doc";
import SingleInput from "../custom/SingleInput";

const Report = ({ open, setOpen }) => {
  // const [open, setOpen] = useState(false);
  const [report, setReport] = useState({
    name: "",
    title: "",
    mobile: "",
    mess: "",
    doc: {},
  });
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setReport({
      ...report,
      name: "",
      title: "",
      mess: "",
      mobile: "",
      doc: {},
    });
  };

  const handle_submit = () => {
    if (
      !report.mess ||
      !report.mobile ||
      !report.name ||
      !report.title ||
      !report.doc
    ) {
      toast.info("Please fill all fields");
      return;
    }
    if (report.mobile.length != 10) {
      toast.info("Please enter valid a mobile");
      return;
    }
    const body = new FormData();
    body.append("userid", user_id);
    body.append("title", report.title);
    body.append("name", report.name);
    body.append("mobile", report.mobile);
    body.append("message", report.mess);
    body.append("ingress", ingress);
    report_api(body, setLoading).then((v) => {
      setOpen(false);
      setReport({
        ...report,
        name: "",
        title: "",
        mess: "",
        mobile: "",
        doc: {},
      });
    });
  };
  return (
    <>
      <Dialog open={open} fullWidth maxWidth={"md"}>
        <div className="db  flex  ">
          <div className="df alc sb p20">
            <div className="title-text">Report</div>

            <GrClose
              onClick={() => handleClose()}
              size={20}
              className={"cursor"}
            />
          </div>
          <Divider />
          <div className=" p20">
            <SingleInput
              label1={"Name"}
              placeholder1={"Enter name"}
              value1={report.name}
              onChange1={(e) => setReport({ ...report, name: e.target.value })}
            />
            <SingleInput
              label1={"Mobile Number"}
              placeholder1={"Enter mobile number"}
              type1={"number"}
              value1={report.mobile}
              onChange1={(e) =>
                setReport({
                  ...report,
                  mobile: e.target.value.toString().substring(0, 10),
                })
              }
            />
            <SingleInput
              label1={"Title"}
              placeholder1={"Enter name"}
              value1={report.title}
              onChange1={(e) => setReport({ ...report, title: e.target.value })}
            />
            <SingleInput
              label1={"Message"}
              placeholder1={"Enter message "}
              area1
              value1={report.mess}
              onChange1={(e) => setReport({ ...report, mess: e.target.value })}
            />
            <Add_Doc
              title={"Add Document"}
              filename={report.doc.name}
              onChange={(e) => setReport({ ...report, doc: e.target.files[0] })}
            />
            <div className="df alc jcc mt20">
              <ButtonComp
                tittle={"Submit"}
                onClick={() => handle_submit()}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Report;

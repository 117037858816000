import React from "react";
import GaugeChart from "react-gauge-chart";

const AccelDial = ({ title }) => {
  return (
    <div className={"card flex p20 mt10"}>
      <div className="green heading-text mb10">{title}</div>
      <div
        style={{
          flex: 1,
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          height: "auto",
          width: "100%",
          alignSelf: "center",
        }}
      >
        <GaugeChart
          id="gauge-chart3"
          nrOfLevels={3}
          colors={["#f00", "#FFE000", "#169e49"]}
          arcWidth={0.15}
          arcPadding={0}
          percent={0.37}
          textColor={"#000000"}
          needleBaseColor={"#555"}
          needleColor={"#555"}
          hideText
          cornerRadius={0.5}
          // arcsLength={}
          style={
            {
              // flex: 1,
            }
          }
        />
      </div>
      <div className="df jcc">37%</div>
    </div>
  );
};

export default AccelDial;

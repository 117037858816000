import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BiArrowBack } from "react-icons/bi";
import { bank_action } from "../../utils/apis";
import ButtonComp from "../../components/ButtonComp";
import CustomInput from "../../components/custom/CustomInput";
import Add_Doc from "../../components/custom/Add_doc";
import {
  get_partner_profile,
  get_vendor_profile,
  get_da_profile,
} from "../../utils/api";
import { ingress } from "../../utils/utils";
import { useDispatch } from "react-redux";

const Add_P_Bank = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(location?.state?.edit);
  const navigate = useNavigate();
  const [b_name, setB_name] = useState(
    location?.state?.edit ? location.state.data.bankname : ""
  );
  const [name, setName] = useState(
    location?.state?.edit ? location?.state?.data?.name : ""
  );
  const [account, setAccount] = useState(
    location?.state?.edit ? location?.state?.data?.account : ""
  );
  const [branch, setBranch] = useState(
    location?.state?.edit ? location?.state?.data?.branch : ""
  );
  const [ifsc, setIfsc] = useState(
    location?.state?.edit ? location?.state?.data?.ifsccode : ""
  );
  const [file, setFile] = useState({});
  const [loading, setLoading] = useState(false);

  const add_bank = () => {
    if (!b_name || !name || !ifsc || !account || !branch) {
      toast.info("please fill all fields");
      return;
    }
    if (account.length < 8) {
      toast.info("please enter your valid account number");
      return;
    }
    if (!edit) {
      if (file?.name == undefined) {
        toast.info("please select document");
        return;
      }
    }

    bank_action(
      edit ? 2 : 1,
      name,
      b_name,
      account,
      branch,
      ifsc,
      file,
      edit ? location.state.data.id : "",
      setLoading
    ).then((data) => {
      ingress == 4 && get_partner_profile();
      ingress == 1 && get_vendor_profile();
      ingress == 2 && get_da_profile();
      data && navigate(-1);
    });
  };
  return (
    <>
      <div className={"df alc sb mb10"}>
        <h2>{edit ? "Edit Bank" : "Add Bank"}</h2>

        <ButtonComp
          b_color={"black"}
          icon={<BiArrowBack color="#fff" size={16} />}
          onClick={() => navigate(-1)}
          tittle={"Back"}
        />
      </div>
      <div className={"card padding-xy"}>
        <CustomInput
          label1={"Name of the Bank"}
          placeholder1={"Enter Name of the Bank"}
          value1={b_name}
          onChange1={(e) => setB_name(e.target.value)}
          label2={"Account Holder Name"}
          placeholder2={"Enter Account Holder Name"}
          onChange2={(e) => setName(e.target.value)}
          value2={name}
        />
        <CustomInput
          label1={"Account Number"}
          placeholder1={"Enter Account Number"}
          type1={"number"}
          label2={"Branch Name"}
          placeholder2={"Enter Branch Name"}
          onChange1={(e) => setAccount(e.target.value)}
          onChange2={(e) => setBranch(e.target.value)}
          value1={account}
          value2={branch}
        />

        <CustomInput
          label1={"IFSC CODE"}
          placeholder1={"Enter IFSC Code"}
          single
          onChange1={(e) => setIfsc(e.target.value)}
          value1={ifsc}
          items={
            <Add_Doc
              filename={
                edit
                  ? file?.name
                    ? file.name
                    : location?.state?.data?.doc_name
                  : file.name
              }
              onChange={(e) => setFile(e.target.files[0])}
              title={"Cancelled check/Bank statement"}
            />
          }
        />
        <div className="df jcc">
          <ButtonComp
            top={20}
            tittle={edit ? "Update" : "Submit"}
            onClick={add_bank}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default Add_P_Bank;

import { Button, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import ButtonComp from "../../components/ButtonComp";
import { useDispatch, useSelector } from "react-redux";
import { isSmallScreen, Loader, No_data, user_id } from "../../utils/utils";
import { FiEdit2, FiEye } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
import TableView from "../../components/custom/TableView";
import { Delete_dialog } from "../../utils/DialogBox";
import { toast } from "react-toastify";
import { delete_driver } from "../../utils/apis";
import { get_driver_list } from "../../redux/slice/driverSlice";
const headerData = [
  "S. No.",
  "Name",
  "Address",
  "License No.",
  "Aadhar No.",
  "Mobile No.",
  "Actions",
];
const Driver = () => {
  const { driver_list, d_loading } = useSelector((state) => state.driver);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  useEffect(() => {
    dispatch(get_driver_list());
  }, []);

  const handle_deleteDriver = () => {
    setOpen(false);
    delete_driver(user_id, id, 1)
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          dispatch(get_driver_list());
        } else {
          toast.error(data.message);
        }
      })
      .catch((e) => {
        // console.log(e);
      });
  };

  return (
    <>
      <div className={"card df padding-xy sb mb10"}>
        <div
          className={
            "border-gray-200 bg-gray-100 padding-xy alc df br search-bar "
          }
        >
          <input
            className="flex"
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <SearchOutlinedIcon />
        </div>
        <div className="flex"></div>

        <ButtonComp
          onClick={() => navigate("/driver/add-driver")}
          tittle={isSmallScreen ? "Add" : "Add Driver"}
          icon={<AddIcon />}
        />
      </div>

      {d_loading && driver_list.length === 0 ? (
        <div className="card p20 df alc jcc">
          <Loader />
        </div>
      ) : driver_list.length === 0 ? (
        <No_data />
      ) : (
        <TableView
          headerData={headerData}
          bodyData={driver_list
            .filter((item) => {
              return item.name.toLowerCase().includes(search.toLowerCase());
            })
            .map((v, i) => (
              <TableRow key={i}>
                <TableCell align="center">{i + 1}</TableCell>
                <TableCell align="center">{v.name}</TableCell>
                <TableCell align="center">{v.address}</TableCell>
                <TableCell align="center">{v.license}</TableCell>
                <TableCell align="center">{v.aadhaar}</TableCell>
                <TableCell align="center">{v.mobile1}</TableCell>
                <TableCell align="center">
                  <FiEye
                    size={20}
                    className={"icons"}
                    onClick={() =>
                      navigate("/driver/driver-detail", {
                        state: { id: v.id, edit: false },
                      })
                    }
                  />
                  <FiEdit2
                    className={"icons view"}
                    size={20}
                    onClick={() =>
                      navigate("/driver/driver-detail", {
                        state: { id: v.id, edit: true },
                      })
                    }
                  />
                  <IoTrashOutline
                    className={"icons trash"}
                    size={20}
                    onClick={() => {
                      setId(v.id);
                      setOpen(true);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
        />
      )}
      <Delete_dialog
        open={open}
        setOpen={setOpen}
        onClick_delete={() => handle_deleteDriver()}
      />
    </>
  );
};

export default Driver;

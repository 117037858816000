import React, { useEffect } from "react";
import GraphChart from "../../components/charts/GraphChart";
import LineChartComp from "../../components/charts/LineChartComp";
import AccelDial from "../../components/gauge/Guage";
import store from "../../redux/store";
import { get_services_data } from "../../redux/slice/userSlice";

const Home = () => {
  useEffect(() => {
    store.dispatch(get_services_data());
  }, []);

  return (
    <div>
      <div className="input-df flex gap10">
        <div className="df flex ">
          <AccelDial title="Value of Business Financial Year" />
        </div>
        <div className="df flex">
          <AccelDial title={"Commitment"} />
        </div>
      </div>
      <div className="input-df flex gap10">
        <div className="df flex ">
          <GraphChart />
        </div>
        <div className="df flex">
          <LineChartComp />
        </div>
      </div>
    </div>
  );
};

export default Home;

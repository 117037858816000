import React from "react";
import ButtonComp from "../../components/ButtonComp";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import Check from "../../components/Check";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const OrderDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <div className="card padding-xy mb10 df alc sb">
        <div className="heading-text">Order Detail</div>
        <ButtonComp
          b_color={"black"}
          icon={<BiArrowBack color="#fff" size={16} />}
          onClick={() => navigate(-1, { state: 2 })}
          tittle={"Back"}
        />
      </div>
      <div className={"input-df flex gap10"}>
        <div className={"card p20 ass flex"}>
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div className="fs16 fw600 black pb6">
              {location.state.description}
            </div>
            <div className="fs14 fw600 orange">{location.state.indentid}</div>
          </div>
          <div className="df alc gap20 pb6">
            <div className="df alc gap10 ">
              <div className="fs14 fw400 black">Price:</div>
              <div className="fs14 fw400 black">{location.state.price}</div>
            </div>
            <div className="df alc gap10 ">
              <div className="fs14 fw400 black">Quantity:</div>
              <div className="fs14 fw400 black">{location.state.quantity}</div>
            </div>
          </div>
          <div className="df alc gap10 pb6">
            <div className="fs14 fw400 black">Address :</div>
            <div className="fs14 fw400 black">
              {location.state.deliveryaddress}
            </div>
          </div>
          <div className="df alc gap10 pb6">
            <div className="fs14 fw400 black">Datetime:</div>
            <div className="fs14 fw400 black">{location.state.created_at}</div>
          </div>
          <div className={"df alc sb gap10"}>
            <div className="df alc gap10">
              <div className="fs14 fw400 black">Amount:</div>
              <div className="fs14 fw400 view">
                {location.state.totalamount}
              </div>
            </div>

            <ButtonComp
              tittle={"Stock Transfer"}
              height={30}
              onClick={() =>
                navigate("/indents/stock-transfer", { state: location.state })
              }
            />
          </div>
        </div>
        <div className="flex ">
          <div className={"card p20 mb10 flex"}>
            <div className="fs16 fw600 black pb6">Driver Details</div>
            <div className="df alc sb">
              <div className="fs16 fw400 view">Manoj Kumar</div>
              <div className="fs14 fw400 black light-green1 p5 br5 alc df gap10">
                GW - <div className="fs14 fw400 orange"> AA001</div>
              </div>
            </div>
            <div className="fs16 fw400 black">9182374405</div>
          </div>
          <div className={"card p20 flex"}>
            <div className="fs16 fw600 black pb6">Billing Details</div>
            <div className="df alc gap10 ">
              <Checkbox
                style={{ margin: 0, padding: 0 }}
                // checked={type == "lpg" ? true : false}
                // onChange={() => setType("lpg")}
              />
              <div className="fs16 fw400 black">Delivery Challan</div>
            </div>
            <div className="df alc gap10">
              <Checkbox
                style={{ margin: 0, padding: 0 }}
                // checked={type == "lpg" ? true : false}
                // onChange={() => setType("lpg")}
              />
              <div className="fs16 fw400 black">E-Invoice & E-Way Bill</div>
            </div>
            <div className="df alc gap10">
              <Checkbox
                style={{ margin: 0, padding: 0 }}
                // checked={type == "lpg" ? true : false}
                // onChange={() => setType("lpg")}
              />
              <div className="fs16 fw400 black">E-Invoice</div>
            </div>
            <div className={"df jcc"}>
              <ButtonComp
                tittle={"Generate"}
                height={30}
                b_color={"#007bc9"}
                onClick={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetail;

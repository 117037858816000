import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
import {
  add_edit_vehicle,
  delete_vehicle,
  get_vehicle,
} from "../../utils/apis";
import { toast } from "react-toastify";
import { Delete_dialog } from "../../utils/DialogBox";
import ButtonComp from "../../components/ButtonComp";
import { BiArrowBack } from "react-icons/bi";
import { ingress, Loader, user_id } from "../../utils/utils";
import Add_Doc from "../../components/custom/Add_doc";
import CustomInput from "../../components/custom/CustomInput";
import Check from "../../components/Check";

const options = [
  { value: "Minor", label: "Minor" },
  { value: "Major", label: "Major" },
  { value: "Large", label: "Large" },
  { value: "Bulk", label: "Bulk" },
];

const VehicleDetail = () => {
  const [detail, setDetail] = useState({});
  const [doc, setDoc] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [docList, setDocList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = useState(location.state.edit);
  const [action, setAction] = useState("");
  const [v_id, setV_id] = useState(location.state.id);
  const [make, setMake] = useState("");
  const [c_no, setC_no] = useState("");
  const [v_no, setV_no] = useState("");
  const [capacity, setCapacity] = useState("");
  const [status, setStatus] = useState("");

  const get = () => {
    setLoading1(true);
    get_vehicle(location.state.id)
      .then((data) => {
        setLoading1(false);
        if (data.success) {
          setDetail(data.data.details[0]);
          setDetail(data.data.details[0]);
          setMake(data.data.details[0].make);
          setC_no(data.data.details[0].chassis);
          setV_no(data.data.details[0].vehicle);
          setCapacity(data.data.details[0].capacity);
          setStatus(
            data.data.details[0].supply == 2
              ? "supply"
              : data.data.details[0].delivery == 2
              ? "delivery"
              : ""
          );
          setDoc(data.data.documents);
        } else {
          toast.error("wrong");
          setDetail({});

          setDoc([]);
        }
      })
      .catch((e) => {
        setLoading1(false);
      });
  };
  useEffect(() => {
    get();
  }, []);
  const handle_deleteVehicle = () => {
    setOpen(false);
    delete_vehicle(user_id, v_id, action)
      .then((data) => {
        action == 1 && navigate(-1);
        setLoading(false);
        get();
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const filter_data = docList.filter(
    (v, i) => Object.entries(docList[i]).length != 0 && v?.name !== ""
  );

  const edit_vehicle = async () => {
    setLoading(true);
    const body = new FormData();
    body.append("userid", user_id);
    body.append("ingress", ingress);
    body.append("vehicle", v_no);
    body.append("chassis", c_no);
    body.append("make", make);
    body.append("capacity", capacity);
    body.append("documentscount", filter_data.length);
    body.append("names", filter_data.map((v) => v.filename).join(",,,"));
    body.append("vehicleid", location.state.id);
    body.append("supply", status === "supply" ? 2 : 1);
    body.append("delivery", status == "delivery" ? 2 : 1);
    {
      filter_data.forEach((v, i) =>
        body.append(`${"document" + i}`, v.docData)
      );
    }

    add_edit_vehicle(body).then((data) => {
      setLoading(false);
      if (data.success) {
        navigate(-1);
      }
    });
  };

  return (
    <>
      <div className={"card custom_input_df_button padding-xy sb alc mb10"}>
        <div className="heading-text">Vehicle Detail</div>
        <div className="df gap20">
          <ButtonComp
            onClick={() => setEdit(!edit)}
            tittle={edit ? "Cancel" : "Edit"}
            // b_color={"#000"}
            icon={<FiEdit2 />}
          />
          <ButtonComp
            tittle={"Delete"}
            loading={loading == "all" ? true : false}
            b_color={"#f00"}
            onClick={() => {
              setOpen(true);
              setV_id(location.state.id);
              setAction(1);
            }}
            icon={<IoTrashOutline />}
          />
          <ButtonComp
            onClick={() => navigate(-1)}
            tittle={"Back"}
            b_color={"#000"}
            icon={<BiArrowBack color="#fff" />}
          />
        </div>
      </div>
      {loading1 ? (
        <div className="card p20 df jcc">
          <Loader />
        </div>
      ) : (
        <div className={"card padding-xy"}>
          <CustomInput
            textonly={edit ? false : true}
            textonly1={edit ? false : true}
            disabled1={edit ? false : true}
            disabled2={edit ? false : true}
            label1={"Make"}
            value1={make}
            onChange1={(val) => setMake(val.target.value)}
            label2={"Chassis Number"}
            value2={c_no}
            onChange2={(val) => setC_no(val.target.value)}
          />
          <CustomInput
            textonly={edit ? false : true}
            textonly1={edit ? false : true}
            disabled1={edit ? false : true}
            disabled2={edit ? false : true}
            label1={"Vehicle Number"}
            value1={v_no}
            onChange1={(val) => setV_no(val.target.value)}
            label2={"Capacity"}
            type2="number"
            value2={capacity}
            onChange2={(val) => setCapacity(val.target.value)}
          />
          {edit && (
            <div className="df alc gap20 mt20 p10 flex ">
              <Check
                tittle={"Supply"}
                onchange={() => setStatus("supply")}
                checked={status === "supply" ? true : false}
              />
              <Check
                tittle={"Delivery"}
                onchange={() => setStatus("delivery")}
                checked={status === "delivery" ? true : false}
              />
            </div>
          )}

          {edit || (
            <CustomInput
              area1={edit ? true : false}
              textonly={edit ? false : true}
              textonly1={edit ? false : true}
              disabled1={edit ? false : true}
              disabled2={edit ? false : true}
              label1={"Supply"}
              value1={detail.supply == 2 ? "Yes" : "No"}
              label2={"Delivery"}
              value2={detail.delivery == 2 ? "Yes" : "No"}
            />
          )}

          <div className={"df alc sb mt20"}>
            <p>Documents</p>
            {edit && (
              <ButtonComp
                tittle={"Add"}
                onClick={() => setDocList((pre) => [...pre, {}])}
              />
            )}
          </div>

          {doc.map((v) => (
            <div className={"text_bg df sb alc mt10"}>
              <div className="label">{v.name}</div>
              <IoTrashOutline
                className="icons trash"
                onClick={() => {
                  setOpen(true);
                  setV_id(v.id);
                  setAction("2");
                }}
              />
            </div>
          ))}
          <div>
            {docList.map((v, i) => (
              <div className="mt10" key={i}>
                <Add_Doc
                  placeholder={"Enter file name"}
                  single
                  value={Object.entries(v).length === 0 ? "" : v?.filename}
                  filename={v?.docData?.name}
                  onChange1={(e) =>
                    setDocList((pre) =>
                      pre.map((val, ind) =>
                        i == ind ? { ...val, filename: e.target.value } : val
                      )
                    )
                  }
                  onChange={(e) => {
                    if (Object.entries(v).length === 0) {
                      toast.info("please add file name");
                      return;
                    }
                    setDocList((pre) =>
                      pre.map((val, ind) =>
                        i == ind ? { ...val, docData: e.target.files[0] } : val
                      )
                    );
                  }}
                  right={
                    <ButtonComp
                      tittle={"Delete"}
                      b_color={"#f00"}
                      onClick={() =>
                        setDocList((pre) =>
                          pre.find((val, ind) => ind == i)
                            ? pre.filter((val, ind) => ind !== i)
                            : [...pre]
                        )
                      }
                    />
                  }
                />
              </div>
            ))}
          </div>

          {edit && (
            <div className="df jcc mt20">
              <ButtonComp
                loading={loading}
                tittle={"Submit"}
                onClick={() => edit_vehicle()}
              />
            </div>
          )}
          <Delete_dialog
            open={open}
            setOpen={setOpen}
            onClick_delete={() => handle_deleteVehicle()}
          />
        </div>
      )}
    </>
  );
};

export default VehicleDetail;

import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const GraphChart = () => {
  const data = [
    {
      name: "Page A",
      Empty: 4000,
      Filled: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      Empty: 3000,
      Filled: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      Empty: 2000,
      Filled: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      Empty: 2780,
      Filled: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      Empty: 1890,
      Filled: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      Empty: 2390,
      Filled: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      Empty: 3490,
      Filled: 4300,
      amt: 2100,
    },
  ];
  return (
    <div className={"card p20 mt10 flex"}>
      <div className="green heading-text mb10">Inventory</div>
      <div className={"chart"}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Filled" fill="#007BC9" />
            <Bar dataKey="Empty" fill="#FFE000" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default GraphChart;

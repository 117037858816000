import { Dialog, DialogActions, DialogTitle, Divider } from "@mui/material";
import React, { useState } from "react";
import { BiSupport } from "react-icons/bi";
import { GrClose } from "react-icons/gr";
import { toast } from "react-toastify";
import { contactus_api } from "../../utils/api";
import { ingress, isValidEmail, user_id } from "../../utils/utils";
import ButtonComp from "../ButtonComp";
import SingleInput from "../custom/SingleInput";

const ContactUs = ({ open, setOpen }) => {
  // const [open, setOpen] = useState(false);
  const [contact, setContact] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: "",
    comment: "",
  });
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handle_submit = () => {
    if (
      !contact.email ||
      !contact.mobile ||
      !contact.name ||
      !contact.subject ||
      !contact.comment
    ) {
      toast.info("Please fill all fields");
      return;
    }
    if (!isValidEmail.test(contact.email)) {
      toast.info("Please enter a valid email");
      return;
    }
    if (contact.mobile.length != 10) {
      toast.info("Please enter valid a mobile");
      return;
    }
    const body = new FormData();
    body.append("userid", user_id);
    body.append("email", contact.email);
    body.append("name", contact.name);
    body.append("mobile", contact.mobile);
    body.append("subject", contact.subject);
    body.append("comments", contact.comment);
    body.append("action", ingress);
    contactus_api(body, setLoading).then((v) => {
      setOpen(false);
      setContact({
        ...contact,
        mobile: "",
        subject: "",
        comment: "",
        name: "",
        email: "",
      });
    });
  };
  return (
    <>
      {/* <BiSupport className="cursor" size={20} onClick={() => setOpen(true)} /> */}
      <Dialog open={open} fullWidth maxWidth={"md"}>
        <div className="db  flex ">
          <div className="df alc sb p20">
            <div className="title-text">Contact Us</div>

            <GrClose
              onClick={() => handleClose()}
              size={20}
              className={"cursor"}
            />
          </div>
          <Divider />
          <div className=" p20">
            <SingleInput
              label1={"Name"}
              placeholder1={"Enter name"}
              value1={contact.name}
              onChange1={(e) =>
                setContact({ ...contact, name: e.target.value })
              }
            />
            <SingleInput
              label1={"Email Address"}
              placeholder1={"Enter email address"}
              value1={contact.email}
              onChange1={(e) =>
                setContact({ ...contact, email: e.target.value })
              }
            />
            <SingleInput
              label1={"Mobile Number"}
              placeholder1={"Enter mobile number"}
              value1={contact.mobile}
              type1={"number"}
              onChange1={(e) =>
                setContact({
                  ...contact,
                  mobile: e.target.value.toString().substring(0, 10),
                })
              }
            />
            <SingleInput
              label1={"Subject"}
              placeholder1={"Enter subjet"}
              value1={contact.subject}
              onChange1={(e) =>
                setContact({ ...contact, subject: e.target.value })
              }
            />
            <SingleInput
              label1={"Comment"}
              placeholder1={"Enter comment"}
              value1={contact.comment}
              onChange1={(e) =>
                setContact({ ...contact, comment: e.target.value })
              }
              area1
              single
            />
            <div className="df alc jcc mt20">
              <ButtonComp
                tittle={"Submit"}
                loading={loading}
                onClick={() => handle_submit()}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ContactUs;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ButtonComp from "../../components/ButtonComp";
import { useNavigate } from "react-router-dom";
import Check from "../../components/Check";
import { BiArrowBack } from "react-icons/bi";
import { base_url, isSmallScreen, user_id } from "../../utils/utils";
import CustomInput from "../../components/custom/CustomInput";
import { update_title_product } from "../../utils/apis";
import Add_Doc from "../../components/custom/Add_doc";
import { useSelector } from "react-redux";
import { IoMdAdd } from "react-icons/io";
import { Box, Button, Dialog, Modal, Typography } from "@mui/material";
import { GrClose } from "react-icons/gr";
import AlliedProduct from "./AlliedProduct";
import LpgIndustrial from "./LpgIndustrial";

const AddProducts = () => {
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.user);
  const { all_products } = useSelector((state) => state.product);
  const [selectedOption, setSelectedOption] = useState([]);
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [select, setSelect] = useState(
    data[0]?.profile[0]?.pro_ser?.split(",") || []
  );
  const [open_ali, setOpen_ali] = React.useState(false);
  const [open_lpg, setOpen_lpg] = React.useState(false);

  const get_products = async () => {
    try {
      const body = new FormData();
      body.append("userid", user_id);
      const response = await fetch(
        "https://gaswala.vensframe.com/api/productlistbyid",
        { body: body, method: "POST" }
      );
      const res = await response.json();

      if (res.success) {
        setProduct(res.data);
      } else {
        setProduct([]);
      }
    } catch (error) {}
  };

  const update_product = () => {
    update_title_product(select.toString(), setLoading);
  };
  useEffect(() => {
    get_products();
  }, []);
  // const get_allproducts = async () => {
  //   try {
  //     const body = new FormData();
  //     body.append("userid", user_id);
  //     const response = await fetch(
  //       "https://gaswala.vensframe.com/api/productlist",
  //       { method: "POST", body: body }
  //     );
  //     const res = await response.json();
  //     if (res.success) {
  //       // setAllProducts(res.data);
  //       for (let i = 0; i < res.data.length; i++) {
  //         const element = {
  //           value: res.data[i].uuid,
  //           label:
  //             res.data[i].description +
  //             "  " +
  //             "(" +
  //             `${res.data[i].type}` +
  //             ")",
  //         };
  //         list.push(element);
  //         setAllProducts(list);
  //       }
  //     } else {
  //       setAllProducts([]);
  //     }
  //   } catch (error) {}
  // };
  const add_products = async () => {
    if (selectedOption.length == 0) {
      toast.error("Please select product");
      return;
    }
    try {
      const body = new FormData();
      body.append("userid", user_id);
      body.append("action", 1);
      body.append("product", selectedOption.map((v) => v.value).toString());
      setLoading(true);
      const response = await fetch(base_url + "product", {
        method: "POST",
        body: body,
      });
      const res = await response.json();
      setLoading(false);
      if (res.success) {
        // setAllProducts(res.data);
        toast.success("added successfully");
        navigate(-1);
      } else {
        //  toast.error("added successfully");
        toast.error("all fields are mandatory");
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    // get_allproducts();
  }, []);

  return (
    <>
      <div className="card padding-xy mb10 df alc sb ">
        <div className="heading-text">Add Product</div>
        <ButtonComp
          b_color={"black"}
          icon={<BiArrowBack color="#fff" size={16} />}
          onClick={() => navigate(-1)}
          tittle={"Back"}
        />
      </div>

      <div className="card padding-xy mb10 profile_df alc gap20 ">
        <Check
          tittle={"LPG"}
          checked={select?.includes("1") ? true : false}
          onchange={() =>
            setSelect((pre) =>
              pre.find((v) => v == "1")
                ? pre.filter((val) => val != "1")
                : [...pre, "1"]
            )
          }
        />
        <Check
          tittle={"Indrustrial Gas"}
          checked={select?.includes("2") ? true : false}
          onchange={() =>
            setSelect((pre) =>
              pre.find((v) => v == "2")
                ? pre.filter((val) => val != "2")
                : [...pre, "2"]
            )
          }
        />

        <Check
          tittle={"Allied Products"}
          checked={select?.includes("3") ? true : false}
          onchange={() =>
            setSelect((pre) =>
              pre.find((v) => v == "3")
                ? pre.filter((val) => val != "3")
                : [...pre, "3"]
            )
          }
        />
        <div className="df flex" />
        <ButtonComp
          loading={loading == "u_p" ? true : false}
          onClick={() => update_product()}
          tittle={"Update"}
        />
      </div>

      <div className="card padding-xy">
        {data[0]?.profile[0]?.pro_ser?.includes("3") && (
          <div className="df alc sb">
            <div>Allied Products</div>
            <ButtonComp
              onClick={() => setOpen_ali(true)}
              tittle={isSmallScreen ? "Add" : "Add Products"}
              icon={<IoMdAdd />}
            />
          </div>
        )}
        {(data[0]?.profile[0]?.pro_ser?.includes("1") ||
          data[0]?.profile[0]?.pro_ser?.includes("2")) && (
          <div className="df alc sb mt20">
            <div>LPG / Industrial GAS</div>
            <ButtonComp
              onClick={() => setOpen_lpg(true)}
              tittle={isSmallScreen ? "Add" : "Add Products"}
              icon={<IoMdAdd />}
            />
          </div>
        )}
        <CustomInput
          drop1
          label1={"Products*"}
          placeholder1={"Select"}
          multi1
          closeMenuOnSelect1={false}
          onChange1={(v, i) => {
            setSelectedOption(v);
          }}
          // options1={all_products.filter(
          //   (v) => !product.map((v) => v.product_id).includes(v.value)
          // )}
          options1={all_products}
          single
        />
        <div className="df jcc">
          <ButtonComp
            top={20}
            tittle={"Submit"}
            loading={loading == true ? true : false}
            onClick={() => add_products()}
          />
        </div>
      </div>
      <AlliedProduct open={open_ali} setOpen={setOpen_ali} />
      <LpgIndustrial open={open_lpg} setOpen={setOpen_lpg} />
    </>
  );
};

export default AddProducts;

import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { update_profile } from "../../utils/apis";
import ButtonComp from "../../components/ButtonComp";
import Check from "../../components/Check";
import CustomInput from "../../components/custom/CustomInput";

const Settings = () => {
  const { data } = useSelector((state) => state.user);
  const [s_data, setS_data] = useState({});
  const [loading, setLoading] = useState(false);

  const update = async () => {
    update_profile(
      s_data.turnover_p3,
      s_data.credit_days,
      s_data.credit_limit,
      s_data.bank_guarantee_req,
      s_data.credit_fi_req,
      s_data.credit,
      s_data.invoice_b2b,
      s_data.invoice_b2c,
      setLoading
    );
  };
  useEffect(() => {
    setS_data(data[0]?.profile[0]);
  }, [data]);
  return (
    <div>
      <div className="h2_heading">Payment Settings</div>
      <div className="mt10">
        <h4>Credits</h4>
        <CustomInput
          type1={"number"}
          type2={"number"}
          placeholder1="Enter number of days"
          placeholder2="Enter offered credited"
          label1={`Credit days (< 90)`}
          label2={`Credit limit`}
          value1={s_data.credit_days}
          value2={s_data.credit_limit}
          onChange1={(e) => {
            if (e.target.value > 90) {
              alert("Credit days must be less than 90 days");
              return;
            }
            let updatedValue = {};
            updatedValue = { credit_days: e.target.value };
            setS_data((pre) => ({ ...pre, ...updatedValue }));
          }}
          onChange2={(e) => {
            let updatedValue = {};
            updatedValue = { credit_limit: e.target.value };
            setS_data((pre) => ({ ...pre, ...updatedValue }));
          }}
        />
        <div className="flex profile_df  gap20 mt10">
          <div className="flex">
            <Check
              tittle={"Bank Guarantee Request"}
              checked={s_data.bank_guarantee_req === 1 ? false : true}
              onchange={(e) => {
                let updatedValue = {};
                updatedValue = {
                  bank_guarantee_req: s_data.bank_guarantee_req === 1 ? 2 : 1,
                };
                setS_data((pre) => ({ ...pre, ...updatedValue }));
              }}
            />
          </div>
          <div className="flex">
            <Check
              tittle={"Credit Support from FI request"}
              checked={s_data.credit_fi_req === 1 ? false : true}
              onchange={(e) => {
                let updatedValue = {};
                updatedValue = {
                  credit_fi_req: s_data.credit_fi_req === 1 ? 2 : 1,
                };
                setS_data((pre) => ({ ...pre, ...updatedValue }));
              }}
            />
          </div>
        </div>

        <h4>Payment Settlement</h4>
        <div className="profile_df ">
          <div className="df flex">
            <p style={{ flex: 2 }}>
              T + 2
              <Checkbox
                color="success"
                checked={s_data.credit == 1 ? true : false}
                onChange={(e) => {
                  let updatedValue = {};
                  updatedValue = {
                    credit: 1,
                  };
                  setS_data((pre) => ({ ...pre, ...updatedValue }));
                }}
              />
            </p>
            <p style={{ flex: 2 }}>
              T + 7
              <Checkbox
                color="success"
                checked={s_data.credit == 2 ? true : false}
                onChange={(e) => {
                  let updatedValue = {};
                  updatedValue = {
                    credit: 2,
                  };
                  setS_data((pre) => ({ ...pre, ...updatedValue }));
                }}
              />
            </p>
          </div>
          <div className="df flex">
            <p style={{ flex: 2 }}>
              T + 15
              <Checkbox
                color="success"
                checked={s_data.credit == 3 ? true : false}
                onChange={(e) => {
                  let updatedValue = {};
                  updatedValue = {
                    credit: 3,
                  };
                  setS_data((pre) => ({ ...pre, ...updatedValue }));
                }}
              />
            </p>
            <p style={{ flex: 2 }}>
              T + 30
              <Checkbox
                color="success"
                checked={s_data.credit == 4 ? true : false}
                onChange={(e) => {
                  let updatedValue = {};
                  updatedValue = {
                    credit: 4,
                  };
                  setS_data((pre) => ({ ...pre, ...updatedValue }));
                }}
              />
            </p>
          </div>
        </div>
      </div>
      <div className="h2_heading">nvoice Settings</div>
      <div>
        <p style={{ flex: 1, alignItems: "center" }}>
          Privious 3 years any instance of crosiing 10 cr
          <Checkbox
            color="success"
            checked={s_data.turnover_p3 === 1 ? false : true}
            onChange={(e) => {
              let updatedValue = {};
              updatedValue = {
                turnover_p3: s_data.turnover_p3 === 1 ? 2 : 1,
              };
              setS_data((pre) => ({ ...pre, ...updatedValue }));
            }}
          />
        </p>
        <h4>Invoice counter</h4>
        <CustomInput
          placeholder1="Enter B2B"
          placeholder2="Enter B2C"
          label1={"GW-B2B"}
          type1={"number"}
          value1={s_data.invoice_b2b}
          onChange1={(e) => {
            let updatedValue = {};
            updatedValue = {
              invoice_b2b: e.target.value,
            };
            setS_data((pre) => ({ ...pre, ...updatedValue }));
          }}
          label2={"GW-B2C"}
          value2={s_data.invoice_b2c}
          type2={"number"}
          onChange2={(e) => {
            let updatedValue = {};
            updatedValue = {
              invoice_b2c: e.target.value,
            };
            setS_data((pre) => ({ ...pre, ...updatedValue }));
          }}
        />
      </div>
      <div className="df jcc">
        <ButtonComp
          tittle={"Update"}
          loading={loading}
          onClick={update}
          top={20}
        />
      </div>
    </div>
  );
};

export default Settings;

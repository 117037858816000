import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiEdit2, FiEye } from "react-icons/fi";
import { IoAddCircleSharp, IoTrashOutline } from "react-icons/io5";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddIcon from "@mui/icons-material/Add";
import { Button, TableCell, TableRow } from "@mui/material";
import TableView from "../../components/custom/TableView";
import { useSelector, useDispatch } from "react-redux";
import ButtonComp from "../../components/ButtonComp";
import { delete_vehicle } from "../../utils/apis";
import { Delete_dialog } from "../../utils/DialogBox";
import { Loader, No_data, user_id } from "../../utils/utils";
import { get_vehicle_list } from "../../redux/slice/vehicleSlice";

const Vehicle = () => {
  const { vehicle_list, v_loading } = useSelector((state) => state.vehicle);
  const [open, setOpen] = React.useState(false);
  const isSmallScreen = window.matchMedia("(max-width: 500px)").matches;
  const [search, setSearch] = useState("");
  const [id, setId] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const headerData = [
    "S.No.",
    "Company Name",
    "Vehicle No.",
    "Chassis No.",
    "Capacity",
    "Type",
    "Actions",
  ];

  useEffect(() => {
    dispatch(get_vehicle_list());
  }, []);
  const handle_deleteVehicle = () => {
    setOpen(false);
    delete_vehicle(user_id, id, 1)
      .then((data) => {
        dispatch(get_vehicle_list());
      })
      .catch((e) => {});
  };
  // console.log(isSmallScreen);
  return (
    <>
      <div className={"card df padding-xy sb mb10 alc "}>
        <div
          className={
            "border-gray-200 bg-gray-100 padding-xy alc df br search-bar "
          }
        >
          <input
            className="flex"
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <SearchOutlinedIcon />
        </div>
        <div className="flex"></div>

        <ButtonComp
          onClick={() => navigate("/vehicle/add-vehicle")}
          tittle={isSmallScreen ? "Add" : "Add Vehicle"}
          icon={<AddIcon />}
        />
        {/* <IoAddCircleSharp
          size={35}
          className={"green"}
          onClick={() => navigate("/addvehicle")}
        /> */}
      </div>
      {v_loading && vehicle_list.length == 0 ? (
        <div className="card p20 df alc jcc">
          <Loader />
        </div>
      ) : vehicle_list.length == 0 ? (
        <No_data />
      ) : (
        <TableView
          headerData={headerData}
          bodyData={vehicle_list
            .filter((item) => {
              return item.make.toLowerCase().includes(search.toLowerCase());
              // item.vehicle.toLowerCase().includes(search.toLowerCase())
            })
            .map((v, i) => (
              <TableRow key={i}>
                <TableCell align="center">{i + 1}</TableCell>
                <TableCell align="center">{v.make}</TableCell>
                <TableCell align="center">{v.vehicle}</TableCell>
                <TableCell align="center">{v.chassis}</TableCell>
                <TableCell align="center">{v.capacity} kg</TableCell>
                <TableCell align="center">{v.type}</TableCell>
                <TableCell align="center">
                  <FiEye
                    size={20}
                    className={"icons black"}
                    onClick={() =>
                      navigate("/vehicle/vehicle-detail", { state: v })
                    }
                  />
                  <FiEdit2
                    className={"icons view"}
                    size={20}
                    onClick={() =>
                      navigate("/vehicle/vehicle-detail", {
                        state: { id: v.id, edit: true },
                      })
                    }
                  />
                  <IoTrashOutline
                    className={"icons trash"}
                    size={20}
                    onClick={() => {
                      setOpen(true);
                      setId(v.id);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
        />
      )}
      <Delete_dialog
        open={open}
        setOpen={setOpen}
        onClick_delete={() => handle_deleteVehicle()}
      />
    </>
  );
};

export default Vehicle;

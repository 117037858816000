import { Button, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TableView from "../../components/custom/TableView";
import ButtonComp from "../../components/ButtonComp";
import { isSmallScreen, Loader, No_data } from "../../utils/utils";
import { get_inventory_list } from "../../redux/slice/inventorySlice";

const headerData = ["S. No.", "Name", "Stock", "Filled", "Empty", "Date-Time"];

const Inventory = () => {
  const { inventory_list, i_loading } = useSelector((state) => state.inventory);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_inventory_list());
  }, []);

  return (
    <>
      <div className={"card df padding-xy sb alc mb10"}>
        <div
          className={
            "border-gray-200 bg-gray-100 padding-xy alc df br search-bar "
          }
        >
          <input
            className="flex"
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <SearchOutlinedIcon />
        </div>
        <div className="flex"></div>

        <ButtonComp
          onClick={() => navigate("/inventory/add-inventory")}
          tittle={isSmallScreen ? "Add" : "Add Inventory"}
          icon={<AddIcon />}
        />
      </div>
      {inventory_list.length == 0 && i_loading ? (
        <div className="card p20 df alc jcc">
          <Loader />
        </div>
      ) : inventory_list.length == 0 ? (
        <No_data />
      ) : (
        <TableView
          headerData={headerData}
          bodyData={inventory_list
            .filter((item) => {
              return item.description
                .toLowerCase()
                .includes(search.toLowerCase());
            })
            .map((v, i) => (
              <TableRow key={i}>
                <TableCell align="center">{i + 1}</TableCell>
                <TableCell align="center">{v.description}</TableCell>
                <TableCell align="center">{v.stock}</TableCell>
                <TableCell align="center">{v.filled}</TableCell>
                <TableCell align="center">{v.emptyreturn}</TableCell>
                <TableCell align="center">{v.datetime}</TableCell>
              </TableRow>
            ))}
        />
      )}
    </>
  );
};

export default Inventory;

import React from "react";
import ButtonComp from "../../components/ButtonComp";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import Check from "../../components/Check";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const OrderDetail1 = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <div className="card padding-xy mb10 df alc sb">
        <div className="heading-text">Order Detail</div>
        <ButtonComp
          b_color={"black"}
          icon={<BiArrowBack color="#fff" size={16} />}
          onClick={() => navigate(-1)}
          tittle={"Back"}
        />
      </div>
      <div className={"input-df flex gap10"}>
        <div className={"card p20 ass flex"}>
          <div className="fs16 fw600 orange pb6">{location.state.orderid}</div>
          <div className="df alc gap10 pb6">
            <div className="fs14 fw400 black">Datetime:</div>
            <div className="fs14 fw400 black">{location.state.datetime}</div>
          </div>
          <div className="fs16 fw600 Black">Products</div>
          {location.state.products.map((v, i) => (
            <>
              <div className="df alc sb mt10">
                <div className="fs14 fw400 black"> {v.description}</div>
                <div className="fs14 fw400 black">{v.count}</div>
              </div>
            </>
          ))}
        </div>
        <div className="flex">
          <div className={"card p20 mb10 "}>
            <div className="fs16 fw600 black pb6">Driver Details</div>
            <div className="df alc sb">
              <div className="fs16 fw400 view">Manoj Kumar</div>
              <div className="fs14 fw400 black light-green1 p5 br5 alc df gap10">
                GW - <div className="fs14 fw400 orange"> AA001</div>
              </div>
            </div>
            <div className="fs16 fw400 black">9182374405</div>
          </div>
          <div className={"card p20 flex"}>
            <div className="fs16 fw600 black pb6">Billing Details</div>
            <div className="df alc gap10 ">
              <Checkbox
                style={{ margin: 0, padding: 0 }}
                // checked={type == "lpg" ? true : false}
                // onChange={() => setType("lpg")}
              />
              <div className="fs16 fw400 black">Delivery Challan</div>
            </div>
            <div className="df alc gap10">
              <Checkbox
                style={{ margin: 0, padding: 0 }}
                // checked={type == "lpg" ? true : false}
                // onChange={() => setType("lpg")}
              />
              <div className="fs16 fw400 black">E-Invoice & E-Way Bill</div>
            </div>
            <div className="df alc gap10">
              <Checkbox
                style={{ margin: 0, padding: 0 }}
                // checked={type == "lpg" ? true : false}
                // onChange={() => setType("lpg")}
              />
              <div className="fs16 fw400 black">E-Invoice</div>
            </div>

            <div className={"df jcc"}>
              <ButtonComp
                tittle={"Generate"}
                height={30}
                b_color={"#3498db"}
                onClick={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetail1;

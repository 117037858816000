import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonComp from "../../components/ButtonComp";
import { Loader, No_data, ingress, user_id } from "../../utils/utils";
import { Popup } from "../../utils/DialogBox";
import { toast } from "react-toastify";
import { order_action, pilot_order_action } from "../../utils/api";
import SingleInput from "../../components/custom/SingleInput";
import { get_new_order_list } from "../../redux/slice/pilotOrdersSlice";

const NewPilotOrders = () => {
  const { new_order_list, n_order_loading } = useSelector(
    (state) => state.pilotOrders
  );
  const dispatch = useDispatch();
  const [accept, setAccept] = useState(false);
  const [reject, setReject] = useState(false);
  const [id, setId] = useState("");
  const [qty, setQty] = useState("");
  const [mess, setMess] = useState("");
  const [loading, setLoading] = useState("");
  const [view, setView] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    dispatch(get_new_order_list());
  }, []);
  const acceptOrder = (pid) => {
    const body = new FormData();
    body.append("userid", user_id);
    body.append("ingress", ingress);
    body.append("action", 3);
    body.append("id", pid);
    pilot_order_action(body, setLoading, pid).then((v) =>
      dispatch(get_new_order_list())
    );
  };
  const rejectOrder = () => {
    if (!mess) {
      toast.info("Please enter a message");
      return;
    }
    const body = new FormData();
    body.append("userid", user_id);
    body.append("ingress", ingress);
    body.append("action", 4);
    body.append("id", new_order_list[id]?.orderid);
    // body.append("message", mess);
    pilot_order_action(body, setLoading, id).then((v) => {
      v && setReject(false);
      v && setMess("");
      dispatch(get_new_order_list());
    });
  };

  return (
    <div>
      {new_order_list.length == 0 && n_order_loading ? (
        <div className="card p20 df alc jcc">
          <Loader />
        </div>
      ) : new_order_list.length == 0 ? (
        <No_data />
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: window.matchMedia("(max-width: 500px)").matches
              ? "repeat(1, 1fr)"
              : window.matchMedia("(max-width: 900px)").matches
              ? "repeat(2, 1fr)"
              : "repeat(3, 1fr)",
            gridGap: 20,
            // marginTop: 20,
          }}
        >
          {new_order_list.map((v, i) => (
            <div className={"grid-item card p20"} key={i}>
              <div className="df alc sb pb6">
                <div className="fs16 fw600 orange ">{v.orderid}</div>
                <div
                  className="fs14 fw600 green cursor"
                  onClick={() => {
                    setData(v.products);
                    setView(true);
                  }}
                >
                  View Products
                </div>
              </div>

              <div className="df  gap10 pb6">
                <div className="fs14 fw400 black">Address:</div>
                <div className="fs14 fw400 black">
                  {v?.deliveryaddress[0]?.address +
                    "," +
                    v?.deliveryaddress[0]?.landmark +
                    "," +
                    v?.deliveryaddress[0]?.district +
                    "," +
                    v?.deliveryaddress[0]?.pincode +
                    "," +
                    v?.deliveryaddress[0]?.state}
                </div>
              </div>
              <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Datetime:</div>
                <div className="fs14 fw400 black">{v.datetime}</div>
              </div>

              <div className={"df jcc gap20"}>
                <ButtonComp
                  tittle={"Reject"}
                  b_color={"red"}
                  height={30}
                  onClick={() => {
                    setId(i);
                    setQty(v.quantity);
                    // setReject(true);
                    rejectOrder(v.orderid);
                  }}
                />
                <ButtonComp
                  tittle={"Accept"}
                  b_color={"green"}
                  height={30}
                  loading={loading == v.orderid ? true : false}
                  onClick={() => {
                    setId(i);
                    acceptOrder(v.orderid);
                    // setQty(v.quantity);
                    // setAccept(true);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      <Popup
        open={accept}
        message={<div className="green title-text">Accept Order</div>}
        body={
          <>
            <div className="title-text">{new_order_list[id]?.description}</div>
            <div className="df alc gap10 pb6 mt5">
              <div className="fs14 fw400 black">Require Quantity -</div>
              <div className="fs14 fw400 black ">
                {new_order_list[id]?.quantity}
              </div>
            </div>
            <div className="df alc gap10 pb6 mt5">
              <div className="fs14 fw400 black">Quantity:</div>
              <input
                placeholder="Enter Quantity "
                type={"number"}
                className={"flex input-text input-border"}
                value={qty}
                onChange={(e) => {
                  if (e.target.value > new_order_list[id]?.quantity) {
                    toast.info("Quantity must be less than Require Quantity");
                    return;
                  }
                  setQty(e.target.value);
                }}
              />
            </div>
          </>
        }
        setOpen={setAccept}
        b_name={"Confirm"}
        onClick={() => acceptOrder()}
        loading={loading}
      />
      <Popup
        open={reject}
        message={<div className="title-text">Reject Order</div>}
        body={
          <>
            <SingleInput
              area1={true}
              label1={"Message"}
              placeholder1={"Write reject message"}
              value1={mess}
              onChange1={(e) => setMess(e.target.value)}
            />
          </>
        }
        setOpen={setReject}
        b_name={"Confirm"}
        onClick={() => rejectOrder()}
      />
      <Popup
        open={view}
        message={<div className="title-text ">Products detail</div>}
        body={
          <>
            <div className="df alc sb pb6">
              <div className="fs14 fw400 black">Product</div>
              <div className="fs14 fw400 black ">Quantity</div>
            </div>
            {data.map((v, i) => (
              <>
                <div
                  style={{ justifyContent: "space-between", display: "flex" }}
                >
                  <div
                    className="fs16 fw400 black pb6"
                    style={{ lineHeight: 1.3 }}
                  >
                    {v.description}
                  </div>
                  <div
                    className="fs16 fw400 black pb6"
                    style={{ lineHeight: 1.3 }}
                  >
                    {v.count}
                  </div>
                </div>
              </>
            ))}
          </>
        }
        setOpen={setView}
      />
    </div>
  );
};

export default NewPilotOrders;

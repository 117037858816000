import { Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiEye } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import { IoCloseCircleSharp, IoTrashOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonComp from "../../components/ButtonComp";
import { get_documents } from "../../redux/slice/userSlice";
import store from "../../redux/store";
import { document_action } from "../../utils/apis";
import { Delete_dialog } from "../../utils/DialogBox";
import { ingress, No_data, user_id } from "../../utils/utils";

const PartnerDoc = () => {
  const navigate = useNavigate();
  const { data, documents_list } = useSelector((state) => state.user);
  const [open, setOpen] = React.useState(false);
  const [doc, setDoc] = useState([]);
  const [id, setId] = useState("");
  const [open1, setOpen1] = React.useState(false);
  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const doc_action = () => {
    const body = new FormData();
    body.append("userid", user_id);
    body.append("action", 2);
    body.append("documentid", id);
    setOpen(false);
    document_action(body, setLoading)
      .then((data) => {
        store.dispatch(get_documents());
        ingress == 4 && setDoc(data[0]?.documents);
        (ingress == 1 || ingress == 2) && setDoc(documents_list);
      })
      .then((data) => {
        //   store.dispatch(get_documents());
        ingress == 4 && setDoc(data[0]?.documents);
        (ingress == 1 || ingress == 2) && setDoc(documents_list);
      });
  };
  // console.log(documents_list);
  useEffect(() => {
    // store.dispatch(get_documents());
    ingress == 4 && setDoc(data[0]?.documents);
    (ingress == 1 || ingress == 2) && setDoc(documents_list);
  }, [ingress == 4 ? data : documents_list]);
  return (
    <div className={""}>
      <div className={"df alc sb mb10"}>
        <div className="h2_heading">Documents</div>
        <ButtonComp
          tittle={"Add"}
          icon={<IoMdAdd />}
          onClick={() => {
            // navigate("/profile/add-documnets");
            // (ingress == 1 || ingress == 2) &&
            navigate("/profile/add-documnets", {
              state: doc.map((v) => v.name),
            });
          }}
        />
      </div>

      {doc?.length == 0 ? (
        <No_data />
      ) : (
        doc?.map((v, i) => (
          <div className={"card df p10 mt10"} key={i}>
            <div className="flex">{v.name}</div>
            <div className={"df gap10"}>
              <FiEye
                size={20}
                className="cursor"
                onClick={() => {
                  setUrl(v.url);
                  handleOpen();
                }}
              />
              <IoTrashOutline
                size={20}
                className="cursor"
                color={"red"}
                onClick={() => {
                  setId(v.id);
                  setOpen(true);
                }}
              />
            </div>
          </div>
        ))
      )}
      <Delete_dialog
        open={open}
        setOpen={setOpen}
        onClick_delete={() => doc_action()}
      />

      <Modal
        open={open1}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div
          className="image-container"
          style={{
            position: "relative",
            width: "80%",
            height: "90%",
            backgroundColor: "#ffffff99",
          }}
        >
          <IoCloseCircleSharp
            className="cursor"
            onClick={() => handleClose()}
            size={40}
            color="#f00"
            style={{
              position: "absolute",
              zIndex: 1000,
              top: "10px",
              right: "10px",
            }}
          />
          <img
            src={url}
            alt="Image"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              zIndex: 0,
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PartnerDoc;

import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const LineChartComp = () => {
  const data = [
    {
      name: "Page A",
      B: 4000,
      A: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      B: 3000,
      A: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      B: 2000,
      A: 900,
      amt: 290,
    },
    {
      name: "Page D",
      B: 2780,
      A: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      B: 1890,
      A: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      B: 2390,
      A: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      B: 3490,
      A: 4300,
      amt: 2100,
    },
  ];
  return (
    <div className={"card p20 mt10 flex"}>
      <div className="green heading-text mb10">Credits</div>
      <div className={"chart"}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="A"
              stroke="#007BC9"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="B" stroke="#FFE000" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default LineChartComp;

import { Checkbox } from "@mui/material";
import React from "react";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const Check = ({ tittle, checked, onchange }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
      {tittle && <div>{tittle}</div>}
      <Checkbox
        color="success"
        {...label}
        /* defaultChecked */ checked={checked ? true : false}
        onChange={onchange}
      />
    </div>
  );
};

export default Check;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_notification_data } from "../../redux/slice/notificationSlice";
import { Loader } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { Grid, Paper } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
const NotificationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notification_data, n_loading } = useSelector(
    (state) => state.notification
  );
  React.useEffect(() => {
    dispatch(get_notification_data());
  }, []);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  return (
    <div className="">
      <h2 className="mb10">Notifications</h2>

      {notification_data?.length == 0 && n_loading ? (
        <div className="card p20 df alc jcc">
          <Loader />
        </div>
      ) : (
        <div className="grid-container">
          {notification_data.map((v, i) => (
            <div
              className={`card padding-xy cursor ${
                v.type == "orders" ? "br-left-orange" : "br-left-blue"
              }`}
              key={i}
              onClick={() =>
                v.type == "orders"
                  ? navigate("/orders")
                  : navigate("/notifications/notification-detail")
              }
            >
              <div className="fs16 fw700 black ">{v.title}</div>
              <div className="df alc gap10 ">
                <div className="fs16 fw400 black">Order ID :</div>
                <div className="fs16 fw600 green">{v.subject}</div>
              </div>
              <div className="df alc gap10 ">
                <div className="fs14 fw400 black">Datetime :</div>
                <div className="fs14 fw600 black">{v.datetime}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NotificationPage;

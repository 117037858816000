import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonComp from "../../components/ButtonComp";
import Add_Doc from "../../components/custom/Add_doc";
import CustomInput from "../../components/custom/CustomInput";
import { check_pincode, create_godown, get_dist_list } from "../../utils/apis";
import { user_id } from "../../utils/utils";

const AddGodown = () => {
  const navigate = useNavigate();
  const { profile_data, state_list } = useSelector((state) => state.user);
  const [dist_list, setDist_list] = useState([]);
  const [license_list, setLicense_list] = useState([]);
  const [doc_list, setDoc_list] = useState([]);
  const [s_id, setS_id] = useState("");
  const [godown_data, setGodown_data] = useState({
    name: "",
    l_no: "",
    validity: "",
    capacity: "",
    address: "",
    state: "",
    district: "",
    pincode: "",
    lat: "",
    long: "",
  });
  const [pincode_verified, setPincode_verified] = useState(false);
  const [loading, setLoading] = useState("");
  const new_license_data = license_list.filter(
    (value) => Object.keys(value).length !== 0
  );
  const new_doc_data = doc_list.filter(
    (value) => Object.keys(value).length !== 0
  );

  const add_godown = () => {
    if (
      godown_data.name === "" ||
      godown_data.address === "" ||
      godown_data.capacity === "" ||
      godown_data.district === "" ||
      godown_data.state === "" ||
      godown_data.l_no === "" ||
      godown_data.lat === "" ||
      godown_data.long === "" ||
      godown_data.pincode === "" ||
      godown_data.validity === ""
    ) {
      toast.info("Please fill all fields");
      return;
    }
    if (doc_list.length == 0) {
      toast.info("Please add documents");
      return;
    }
    if (!pincode_verified) {
      toast.info("Please enter valid Pin Code");
      return;
    }
    const body = new FormData();
    body.append("userid", user_id);
    body.append("name", godown_data.name);
    body.append("license", godown_data.l_no);
    body.append("capacity", godown_data.capacity);
    body.append(
      "address",
      godown_data.address +
        ",,," +
        godown_data.state +
        ",,," +
        godown_data.district +
        ",,," +
        godown_data.pincode
    );
    body.append("latitude", godown_data.lat);
    body.append("longitude", godown_data.long);
    body.append("validity", godown_data.validity);
    body.append("documentscount", new_doc_data.length);
    body.append(
      "otherlicense",
      new_license_data.map((v) => v.l_no).join(",,,")
    );
    body.append(
      "othervalidity",
      new_license_data.map((v) => v.val).join(",,,")
    );
    new_doc_data.forEach((v, i) => body.append(`${"document" + i}`, v.docData));
    body.append("names", new_doc_data.map((v) => v.filename).join(",,,"));
    body.append("godownid", "");

    create_godown(body, setLoading).then((data) => data && navigate(-1));
  };
  const checkPincode = (v) => {
    if (godown_data.state.length == 0) {
      toast.info("please select state");
      return;
    }
    check_pincode(s_id, v, setLoading).then((data) =>
      setPincode_verified(data)
    );
  };

  return (
    <>
      <div className={"card df padding-xy sb alc mb10"}>
        <div className="heading-text">Add Godown</div>
        <ButtonComp
          onClick={() => navigate(-1)}
          tittle={"Back"}
          b_color={"#000"}
          icon={<BiArrowBack color="#fff" />}
        />
      </div>
      <div className="card ph20 ">
        <CustomInput
          label1={"Name"}
          placeholder1={"Enter name"}
          value1={godown_data.name}
          onChange1={(e) => {
            setGodown_data({ ...godown_data, name: e.target.value });
          }}
          label2={"License Number"}
          placeholder2={"Enter License number"}
          value2={godown_data.l_no}
          onChange2={(e) => {
            setGodown_data({ ...godown_data, l_no: e.target.value });
          }}
        />
        <CustomInput
          label1={"License Validity"}
          type1={"date"}
          placeholder1={"Enter Validity"}
          value1={godown_data.validity}
          min1={new Date().toISOString().split("T")[0]}
          onChange1={(e) => {
            setGodown_data({ ...godown_data, validity: e.target.value });
          }}
          label2={"Storage Capacity (kgs)"}
          placeholder2={"Enter Storage capacity"}
          type2={"number"}
          value2={godown_data.capacity}
          onChange2={(e) => {
            setGodown_data({ ...godown_data, capacity: e.target.value });
          }}
        />
        <CustomInput
          label1={"Address"}
          placeholder1={"Enter Address"}
          value1={godown_data.address}
          onChange1={(e) => {
            setGodown_data({ ...godown_data, address: e.target.value });
          }}
          area1
          label2={"State"}
          //   value2={godown_data.state}
          options2={state_list}
          onChange2={(e) => {
            setS_id(e.value);
            setGodown_data({ ...godown_data, state: e.label });

            get_dist_list(e.value, setDist_list);
          }}
          drop2
        />
        <CustomInput
          label1={"District"}
          menuPlacement1={"top"}
          drop1
          //   value1={godown_data.district}
          disabled1={dist_list.length == 0 ? true : false}
          options1={dist_list}
          onChange1={(e) => {
            setGodown_data({ ...godown_data, district: e.label });
          }}
          label2={"Pincode"}
          placeholder2={"Enter Pincode"}
          type2={"number"}
          value2={godown_data.pincode}
          right2={
            loading == "pin" && (
              <Oval
                height={15}
                width={15}
                color="#747bff"
                wrapperStyle={{}}
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#fff"
                strokeWidth={7}
                strokeWidthSecondary={7}
              />
            )
          }
          onChange2={(e) => {
            e.target.value.length == 6 && checkPincode(e.target.value);
            setGodown_data({
              ...godown_data,
              pincode: e.target.value.toString().substring(0, 6),
            });

            // e.target.value.length <= 6
            //   ? (checkPincode(e.target.value),
            //     setGodown_data({ ...godown_data, pincode: e.target.value }))
            //   : setGodown_data({
            //       ...godown_data,
            //       pincode: parseInt(e.target.value.toString().substring(0, 6)),
            //     });
          }}
        />
        <CustomInput
          label1={"Latitute"}
          placeholder1={"Enter Latitute"}
          value1={godown_data.lat}
          onChange1={(e) => {
            setGodown_data({ ...godown_data, lat: e.target.value });
          }}
          label2={"Longitute"}
          placeholder2={"Enter Longitute"}
          value2={godown_data.long}
          onChange2={(e) => {
            setGodown_data({ ...godown_data, long: e.target.value });
          }}
        />
        <div className="df alc sb mt15">
          <div>Other Licenses</div>
          <ButtonComp
            onClick={() => setLicense_list((pre) => [...pre, {}])}
            tittle={"Add"}
          />
        </div>
        <div>
          {license_list.map((v, i) => (
            <div className="mt10" key={i}>
              <CustomInput
                placeholder1={"Enter license number"}
                value1={Object.entries(v).length === 0 ? "" : v?.l_no}
                onChange1={(e) =>
                  setLicense_list((pre) =>
                    pre.map((val, ind) =>
                      i == ind ? { ...val, l_no: e.target.value } : val
                    )
                  )
                }
                placeholder2={"Enter validity"}
                type2={"date"}
                min2={new Date().toISOString().split("T")[0]}
                value2={Object.entries(v).length === 0 ? "" : v?.val}
                onChange2={(e) =>
                  setLicense_list((pre) =>
                    pre.map((val, ind) =>
                      i == ind ? { ...val, val: e.target.value } : val
                    )
                  )
                }
              />
              <div className="flex mt10 df">
                <div className="flex"></div>
                <ButtonComp
                  tittle={"Delete"}
                  b_color={"#f00"}
                  onClick={() =>
                    setLicense_list((pre) =>
                      pre.find((val, ind) => ind == i)
                        ? pre.filter((val, ind) => ind !== i)
                        : [...pre]
                    )
                  }
                />
              </div>
            </div>
          ))}
        </div>
        <div className="df alc sb mt15">
          <div>Documents</div>
          <ButtonComp
            tittle={"Add"}
            onClick={() => setDoc_list((pre) => [...pre, {}])}
          />
        </div>
        <div>
          {doc_list.map((v, i) => (
            <div className="mt10" key={i}>
              <Add_Doc
                placeholder={"Enter file name"}
                single
                value={Object.entries(v).length === 0 ? "" : v?.filename}
                filename={v?.docData?.name}
                onChange1={(e) =>
                  setDoc_list((pre) =>
                    pre.map((val, ind) =>
                      i == ind ? { ...val, filename: e.target.value } : val
                    )
                  )
                }
                onChange={(e) => {
                  if (Object.entries(v).length === 0) {
                    toast.info("please add file name");
                    return;
                  }
                  setDoc_list((pre) =>
                    pre.map((val, ind) =>
                      i == ind ? { ...val, docData: e.target.files[0] } : val
                    )
                  );
                }}
                right={
                  <ButtonComp
                    tittle={"Delete"}
                    b_color={"#f00"}
                    onClick={() =>
                      setDoc_list((pre) =>
                        pre.find((val, ind) => ind == i)
                          ? pre.filter((val, ind) => ind !== i)
                          : [...pre]
                      )
                    }
                  />
                }
              />
            </div>
          ))}
        </div>
        <div className="df jcc p20">
          <ButtonComp
            onClick={() => add_godown()}
            tittle={"Submit"}
            loading={loading == "create" ? true : false}
          />
        </div>
      </div>
    </>
  );
};

export default AddGodown;

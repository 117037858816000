import React, { useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { MdOutlineVerified } from "react-icons/md";
import { useSelector } from "react-redux";
import ButtonComp from "../../components/ButtonComp";
import CustomInput from "../../components/custom/CustomInput";
import { update_partner_profile } from "../../utils/api";
import { ingress } from "../../utils/utils";

const ProfileInfo = () => {
  const { data } = useSelector((state) => state.user);
  const [edit, setEdit] = useState(false);
  const [edit1, setEdit1] = useState(false);
  const [partner, setPartner] = useState({});
  const [vehicle, setVehicle] = useState({});
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(1);

  const updateProfile = () => {
    const body = new FormData();
    body.append("userid", partner.id);
    body.append("action", 1);
    body.append("name", partner.name);
    body.append("mobile1", partner.mobile1);
    body.append("mobile2", partner.mobile2);
    body.append("email", partner.email);
    body.append("address", partner.address);
    body.append("license", partner.license);
    body.append("license_validity", partner.license_validity);

    update_partner_profile(body, setLoading).then(() => setEdit(false));
  };
  const updateVehicle = () => {
    const body = new FormData();
    body.append("userid", partner.id);
    body.append("id", vehicle.id);
    body.append("action", 2);
    body.append("vehicleno", vehicle.vehicleno);
    body.append("make", vehicle.make);
    body.append("chassisno", vehicle.chassisno);
    body.append("type", vehicle.type);
    body.append("capacity", vehicle.capacity);
    update_partner_profile(body, setLoading).then(() => setEdit1(false));
  };

  useEffect(() => {
    ingress == 4 && setPartner(data[0]?.profile[0]);
    ingress == 4 && setVehicle(data[0]?.vehicle[0]);
  }, [data]);

  return (
    <div>
      <div className={"card flex"}>
        <div
          className={"text_bg flex df alc sb cursor"}
          onClick={() => setShow(1)}
        >
          <h2>Personal Information</h2>
          <ButtonComp
            tittle={edit ? "Cancel" : "Edit"}
            icon={!edit ? <FiEdit2 /> : ""}
            onClick={() => {
              setEdit(!edit);
            }}
          />
        </div>
        {show == 1 && data?.length != 0 && (
          <div className="padding-xy">
            <CustomInput
              textonly={!edit}
              textonly1={!edit}
              label1={"Name"}
              value1={partner?.name}
              onChange1={(e) =>
                setPartner({ ...partner, name: e.target.value })
              }
              label2={"Email"}
              value2={partner?.email}
              onChange2={(e) =>
                setPartner({ ...partner, email: e.target.value })
              }
            />
            <CustomInput
              textonly={!edit}
              textonly1={!edit}
              type1={"number"}
              label1={"Mobile 1"}
              value1={partner?.mobile1}
              onChange1={(e) =>
                setPartner({ ...partner, mobile1: e.target.value })
              }
              right1={
                partner?.verify1 === 1 && <MdOutlineVerified color="green" />
              }
              type2={"number"}
              label2={"Mobile 2"}
              value2={partner?.mobile2}
              right2={
                partner?.verify2 === 1 && <MdOutlineVerified color="green" />
              }
              onChange2={(e) =>
                setPartner({ ...partner, mobile2: e.target.value })
              }
            />
            <CustomInput
              textonly
              textonly1
              label1={"Aadhaar"}
              value1={partner?.aadhaar}
              onChange1={(e) =>
                setPartner({ ...partner, aadhaar: e.target.value })
              }
              type1={"number"}
              label2={"Pan"}
              value2={partner?.pan}
              onChange2={(e) => setPartner({ ...partner, pan: e.target.value })}
            />
            <CustomInput
              textonly={!edit}
              textonly1={!edit}
              label1={"License Number"}
              value1={partner?.license}
              onChange1={(e) =>
                setPartner({ ...partner, license: e.target.value })
              }
              label2={"License Validity"}
              value2={partner?.license_validity}
              min2={new Date().toISOString().split("T")[0]}
              onChange2={(e) =>
                setPartner({ ...partner, license_validity: e.target.value })
              }
              type2={"date"}
            />
            <CustomInput
              textonly={!edit}
              label1={"Address"}
              value1={partner?.address}
              onChange1={(e) =>
                setPartner({ ...partner, address: e.target.value })
              }
              area1
              single
            />
            {edit && (
              <div className="df jcc mt20">
                <ButtonComp
                  tittle={"Update"}
                  onClick={() => updateProfile()}
                  loading={loading}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className={"card flex mt20"}>
        <div
          className={"text_bg flex df alc sb cursor"}
          onClick={() => setShow(2)}
        >
          <h2>Vehicle Information</h2>
          <ButtonComp
            tittle={edit1 ? "Cancel" : "Edit"}
            icon={!edit1 ? <FiEdit2 /> : ""}
            onClick={() => {
              setEdit1(!edit1);
            }}
          />
        </div>
        {show == 2 && (
          <div className="padding-xy">
            <CustomInput
              textonly={!edit1}
              textonly1={!edit1}
              label1={"Make"}
              value1={vehicle.make}
              onChange1={(e) =>
                setVehicle({ ...vehicle, make: e.target.value })
              }
              label2={"Chassis Number"}
              value2={vehicle.chassis}
              onChange2={(e) =>
                setVehicle({ ...vehicle, chassis: e.target.value })
              }
            />
            <CustomInput
              textonly={!edit1}
              textonly1={!edit1}
              label1={"Vehicle Number"}
              value1={vehicle.vehicleno}
              onChange1={(e) =>
                setVehicle({ ...vehicle, vehicleno: e.target.value })
              }
              label2={"Capacity"}
              value2={vehicle.capacity}
              type2={"number"}
              onChange2={(e) =>
                setVehicle({ ...vehicle, capacity: e.target.value })
              }
            />

            {edit1 && (
              <div className="df jcc mt20">
                <ButtonComp
                  tittle={"Update"}
                  onClick={() => updateVehicle()}
                  loading={loading}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileInfo;

import { Avatar, Tab, Tabs } from "@mui/material";
import { Outlet, Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import { ingress, user_id } from "../../utils/utils";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box } from "@mui/system";
import Bussiness_profile from "./Bussiness_profile";
import P_BankInfo from "./P_BankInfo";
import PartnerDoc from "./PartnerDoc";
import Settings from "./Settings";
import Payments from "./Payments";
import Personal_profile from "./Personal_profile";
import ChangePassword from "./ChangePassword";
import Add_P_Bank from "./Add_P_Bank";
import Add_v_Documents from "./Add_v_Documents";
import Referral from "./Referral";

const Profile = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { data } = useSelector((state) => state.user);
  const [c_tab, setC_tab] = useState(location.pathname);
  const [value, setValue] = React.useState("1");
  const [activeIndex, setActiveIndex] = useState(1);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setC_tab(location.pathname);
  }, [location]);
  const tabStyle = {
    default_tab: {
      color: "#000",
      backgroundColor: "#FFFFFF",
      fontSize: 15,
    },
    active_tab: {
      color: "#169e49",
      backgroundColor: "#FFFFFF",
      fontSize: 15,
    },
  };
  const getStyle = (isActive) => {
    return isActive ? tabStyle.active_tab : tabStyle.default_tab;
  };
  return (
    <>
      <div className={"card show_profile_options p20"}>
        <div className={"r_border mb10"}>
          <div className={"df gap10 alc mb10"}>
            <div className="avtar">
              {data?.length != 0 &&
                (ingress == 4
                  ? data[0]?.profile[0]?.name?.split("")[0]
                  : data[0]?.profile[0]?.tradename?.split("")[0])}
            </div>
            {data?.length != 0 && (
              <div>
                {ingress == 4 && (
                  <>
                    <div>{data[0]?.profile[0]?.name}</div>
                    <div style={{ fontSize: 10 }}>
                      {data[0]?.profile[0]?.driverid}
                    </div>
                  </>
                )}
                {(ingress == 1 || ingress == 2) && data?.length != 0 && (
                  <>
                    <div>{data[0]?.profile[0]?.tradename?.split(" ")[0]}</div>
                    {ingress == 1 ? (
                      <div style={{ fontSize: 10 }}>
                        {data[0]?.profile[0]?.vendorid}
                      </div>
                    ) : (
                      <div style={{ fontSize: 10 }}>
                        {data[0]?.profile[0]?.dpid}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <hr />
          <div>
            <Link
              to={"/profile/information"}
              className={`p_link_unactive ${
                c_tab.split("/")[2] == "information" && "p_link_active"
              }`}
            >
              Profile Information
              <ChevronRightIcon />
            </Link>
            <Link
              to={"/profile/bank-information"}
              className={`p_link_unactive ${
                (c_tab.split("/")[2] == "bank-information" ||
                  c_tab.split("/")[2] == "add-bank-account") &&
                "p_link_active"
              }`}
            >
              Bank Information
              <ChevronRightIcon />
            </Link>
            {ingress == 1 && (
              <Link
                to={"/profile/setting"}
                className={`p_link_unactive ${
                  c_tab.split("/")[2] == "setting" && "p_link_active"
                }`}
              >
                Settings
                <ChevronRightIcon />
              </Link>
            )}
            <Link
              to={"/profile/documents-information"}
              className={`p_link_unactive ${
                (c_tab.split("/")[2] == "documents-information" ||
                  c_tab.split("/")[2] == "add-documnets") &&
                "p_link_active"
              }`}
            >
              Documents
              <ChevronRightIcon />
            </Link>
            <Link
              to={"/profile/payment-information"}
              className={`p_link_unactive ${
                c_tab.split("/")[2] == "payment-information" && "p_link_active"
              }`}
            >
              Payments
              <ChevronRightIcon />
            </Link>
            <Link
              to={"/profile/change-password"}
              className={`p_link_unactive ${
                c_tab.split("/")[2] == "change-password" && "p_link_active"
              }`}
            >
              Change Password
              <ChevronRightIcon />
            </Link>
            <Link
              to={"/profile/referral"}
              className={`p_link_unactive ${
                c_tab.split("/")[2] == "referral" && "p_link_active"
              }`}
            >
              Referral
              <ChevronRightIcon />
            </Link>
          </div>
        </div>
        <div className={"flex"}>
          <Outlet />
        </div>
      </div>
      <div className=" over_hidden hide_profile_options">
        <TabContext value={value}>
          <TabList
            className="card"
            onChange={handleChange}
            variant="scrollable"
            aria-label="lab API tabs example"
            // visibleScrollbar
            allowScrollButtonsMobile
            TabIndicatorProps={{
              style: {
                backgroundColor: "#169e49",
              },
            }}
          >
            <Tab
              style={getStyle(activeIndex == 1)}
              label="Business"
              onClick={() => setActiveIndex(1)}
              value={"1"}
            />
            <Tab
              style={getStyle(activeIndex == 2)}
              label="Personal"
              onClick={() => setActiveIndex(2)}
              value={"2"}
            />
            <Tab
              style={getStyle(activeIndex == 3)}
              label="Bank"
              onClick={() => setActiveIndex(3)}
              value={"3"}
            />
            {ingress == 1 && (
              <Tab
                style={getStyle(activeIndex == 4)}
                label="Settings"
                onClick={() => setActiveIndex(4)}
                value={"4"}
              />
            )}
            <Tab
              style={getStyle(activeIndex == 5)}
              label="documents"
              onClick={() => setActiveIndex(5)}
              value={"5"}
            />
            <Tab
              style={getStyle(activeIndex == 6)}
              label="Payment"
              onClick={() => setActiveIndex(6)}
              value={"6"}
            />
            <Tab
              style={getStyle(activeIndex == 7)}
              label="Change Password"
              onClick={() => setActiveIndex(7)}
              value={"7"}
            />
            <Tab
              style={getStyle(activeIndex == 8)}
              label="Referral"
              onClick={() => setActiveIndex(8)}
              value={"8"}
            />
          </TabList>
          <div className="card mt10">
            <TabPanel value="1">
              <Bussiness_profile />
            </TabPanel>
            <TabPanel value="2">
              <Personal_profile />
            </TabPanel>
            <TabPanel value="3">
              {c_tab.split("/")[2] == "add-bank-account" && <Add_P_Bank />}
              {c_tab.split("/")[2] == "add-bank-account" || <P_BankInfo />}
            </TabPanel>
            {ingress == 1 && (
              <TabPanel value="4">
                <Settings />
              </TabPanel>
            )}
            <TabPanel value="5">
              {c_tab.split("/")[2] == "add-documnets" && <Add_v_Documents />}
              {c_tab.split("/")[2] == "add-documnets" || <PartnerDoc />}
            </TabPanel>
            <TabPanel value="6">
              <Payments />
            </TabPanel>
            <TabPanel value="7">
              <ChangePassword />
            </TabPanel>
            <TabPanel value="8">
              <Referral />
            </TabPanel>
          </div>
        </TabContext>
      </div>
    </>
  );
};

export default Profile;

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { send_token } from "./utils/api";

const firebaseConfig = {
  apiKey: "AIzaSyAT5LIRIa0ZRSuhrRT52llJk7WZoGFj3CY",
  authDomain: "gaswale-f2d17.firebaseapp.com",
  projectId: "gaswale-f2d17",
  storageBucket: "gaswale-f2d17.appspot.com",
  messagingSenderId: "537838672510",
  appId: "1:537838672510:web:089d698a0822eb5664962f",
  measurementId: "G-Q0EGTPK8JP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const messaging = getMessaging(app);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      //   console.log("payload", payload);
      resolve(payload);
    });
  });

export const requestPermission = async () => {
  Notification.requestPermission()
    .then(async (permission) => {
      if (permission == "granted") {
        try {
          await requestForToken();
        } catch (error) {
          console.log("An error occurred while retrieving token. ", error);
        }
      }
    })
    .catch((error) => console.log(error));
};
const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);

const requestForToken = () => {
  // requestPermission()
  return getToken(messaging, {
    vapidKey:
      "BC_qsLtrm-5Z3Q6cWyKuadYiYiwg1B9YWIG1H-c3luFaciHr7rBQYpWtFsoVEOSaGH-RhIRLWwRoQCtgIdXspKI",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        user == null || send_token(currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

import React, { useEffect, useState } from "react";
import Bussiness_profile from "./Bussiness_profile";
import Personal_profile from "./Personal_profile";

const V_DA_Profile = () => {
  return (
    <div>
      <Bussiness_profile />
      {/* -============================================personal info=================================================== */}
      <div className="mt20 df" />
      <Personal_profile />
    </div>
  );
};

export default V_DA_Profile;

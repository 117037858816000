import * as React from "react";
import Popover from "@mui/material/Popover";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import css from "./header.module.scss";
import moment from "moment";
import { IoCloseSharp } from "react-icons/io5";
import { MdOutlineNotificationsNone } from "react-icons/md";
import { Loader } from "../../utils/utils";
import { get_notification_data } from "../../redux/slice/notificationSlice";
import { Badge } from "@mui/material";

export default function Notification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { notification_data, n_loading } = useSelector(
    (state) => state.notification
  );
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  React.useEffect(() => {
    dispatch(get_notification_data());
  }, []);

  return (
    <>
      <Badge variant="standard" badgeContent={0} color="success">
        <MdOutlineNotificationsNone
          size={25}
          aria-describedby={id}
          // variant="contained"
          onClick={handleClick}
        />
      </Badge>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        sx={{ padding: "10px" }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {notification_data.length == 0 && n_loading ? (
          <div className=" p20 df alc jcc">
            <Loader />
          </div>
        ) : (
          <div className={css.noti_wrapper}>
            {notification_data.length == 0 ? (
              <h3 className="p20">No Notification</h3>
            ) : (
              notification_data.slice(0, 5).map((v, i) => (
                <div
                  className={css.notification_item}
                  key={i}
                  onClick={() => {
                    handleClose();
                    navigate("/notifications/notification-detail");
                  }}
                >
                  <div>
                    <div className="fs16 fw600">{v.title}</div>
                    <div className={css.row_div}>
                      <div className={css.noti_type}>{v.type}</div>
                      <div className="fs14">{v.datetime}</div>
                    </div>
                  </div>
                </div>
              ))
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignSelf: "end",
                margin: 20,
              }}
            >
              <Link
                style={{
                  textDecorationStyle: "unset",
                  textDecorationColor: "white",
                }}
                to={"notifications"}
                onClick={handleClose}
              >
                See More
              </Link>
            </div>
          </div>
        )}
      </Popover>
    </>
  );
}

import { toast } from "react-toastify";
import { get_driver_list } from "../redux/slice/driverSlice";
import { get_documents, get_profile } from "../redux/slice/userSlice";
import store from "../redux/store";
import { base_url } from "./utils";
import { get_da_profile, get_partner_profile, get_vendor_profile } from "./api";

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
const user_id = user?.userid;
const ingress = user?.access;

export const verify_email = async (id, setloading) => {
  try {
    setloading(id);
    const body = new FormData();
    body.append("userid", user_id);
    body.append("email", id);
    body.append("ingress", ingress);

    const response = await fetch(base_url + "emailverification", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    setloading("");
    if (res.success) {
      toast.success(res.message);
      store.dispatch(get_profile());
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    setloading("");
  }
};

export const create_godown = async (body, setloading) => {
  try {
    setloading("create");
    const response = await fetch(base_url + "creategodown", {
      body: body,
      method: "POST",
    });
    const res = await response.json();
    console.log(res);
    setloading("");
    if (res.success) {
      toast.success(res.message);
      return true;
    } else {
      toast.error(res.message || "Something went wrong");
      return false;
    }
  } catch (error) {
    setloading("");
  }
};

export const godown_detail = async (u_id, id, setloading) => {
  try {
    setloading("detail");
    const body = new FormData();
    body.append("userid", u_id);
    body.append("godownid", id);
    // setOtp_loading(load);
    const response = await fetch(base_url + "godown_details", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    setloading("");
    if (res.success) {
      toast.success(res.message);
      return res.data;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    setloading("");
  }
};

export const godown_delete = async (u_id, action, id, setloading) => {
  try {
    setloading(action == 1 ? "all" : "doc");
    const body = new FormData();
    body.append("userid", u_id);
    body.append("action", action);
    body.append("godownid", id);
    // setOtp_loading(load);
    const response = await fetch(base_url + "delete_godown", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    setloading("");
    if (res.success) {
      toast.success(res.message);
      return action == 1 ? true : false;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    setloading("");
  }
};

export const get_dist_list = async (id, setDist) => {
  try {
    const body = new FormData();
    body.append("stateid", id);
    const response = await fetch(base_url + "completelist", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    if (res.success) {
      const data1 = [];
      for (let i = 0; i < res.data.reponselist.districts.length; i++) {
        const element = {
          value: res.data.reponselist.districts[i].id,
          label: res.data.reponselist.districts[i].name,
        };
        data1.push(element);
        setDist(data1);
      }
    } else {
      toast.error(res.message);
    }
  } catch (error) {}
};

export const check_pincode = async (s_id, code, setloading) => {
  try {
    setloading("pin");
    const body = new FormData();
    body.append("state", s_id);
    body.append("pincode", code);
    // setOtp_loading(load);
    const response = await fetch(base_url + "pincode_verify", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    setloading("");
    if (res.success) {
      toast.success(res.message);
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    setloading("");
  }
};

export const check_gst = async (id, setloading) => {
  try {
    setloading("gst");
    const body = new FormData();
    body.append("gstin", id);
    const response = await fetch(base_url + "gstfetch", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    setloading("");
    if (res.error) {
      toast.error(res.data);
      return {};
    } else {
      return res;
    }
  } catch (error) {
    setloading("");
  }
};

export const create_driver = async (body, setloading) => {
  try {
    setloading(true);

    const response = await fetch(base_url + "createdriver", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    setloading(false);
    if (res.success) {
      toast.success(res.message);
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    setloading(false);
  }
};

export const bank_action = async (
  action,
  name,
  bank,
  account,
  branch,
  ifsc,
  document,
  id,
  setLoading
) => {
  try {
    setLoading(true);
    const body = new FormData();
    body.append("userid", user_id);
    body.append("action", action);
    body.append("name", name);
    body.append("bank", bank);
    body.append("account", account);
    body.append("branch", branch);
    body.append("ifsc", ifsc);
    body.append("document", document);
    body.append("id", id);
    const response = await fetch(
      base_url +
        `${ingress == 1 ? "bank" : ingress == 4 ? "bank_d" : "bank_dp"}`,
      {
        body: body,
        method: "POST",
      }
    );
    const res = await response.json();
    // console.log(res);
    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      ingress == 1
        ? get_vendor_profile()
        : ingress == 4
        ? get_partner_profile()
        : get_da_profile();
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const update_profile = async (
  tp3,
  credit_days,
  credit_limit,
  b_guarantee_req,
  b_fi_request,
  p_setlement,
  b2b,
  b2c,
  setLoading
) => {
  try {
    setLoading(true);
    const body = new FormData();
    body.append("userid", user_id);
    body.append("tp3", tp3);
    body.append("days", credit_days);
    body.append("limit", credit_limit);
    body.append("bank_req", b_guarantee_req);
    body.append("credit_req", b_fi_request);
    body.append("credit", p_setlement);
    body.append("invoiceb2b", b2b);
    body.append("invoiceb2c", b2c);
    body.append("action", 3);
    const response = await fetch(base_url + "update_profile", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      get_vendor_profile();
    } else {
      toast.error(res.message);
    }
  } catch (error) {
    setLoading(false);
  }
};

export const document_action = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "document", {
      method: "POST",
      body: body,
    });
    const res = await response.json();

    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      await store.dispatch(get_documents());
      return true;
    } else {
      toast.error(res.message);
      return true;
    }
  } catch (error) {
    setLoading(false);
  }

  // dispatch(
  //   add_driver(body).then((data) =>
  // );
};

export const signup = async (body, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(base_url + "register", {
      body: body,
      method: "POST",
    });
    //
    const res = await response.json();

    setLoading(false);
    if (res.success) {
      // dispatch(signup_reducer(res.data));
      toast.success(res.message);
      return res;
    } else {
      toast.error(res.message);
      return res;
    }
  } catch (error) {
    setLoading(false);
  }
};

export const update_title_product = async (id, setLoading) => {
  try {
    setLoading("u_p");
    const body = new FormData();
    body.append("products", id);
    body.append("userid", user_id);
    body.append("action", 1);
    const response = await fetch(base_url + "update-products", {
      body: body,
      method: "POST",
    });
    const res = await response.json();
    console.log("product", res);
    setLoading("");
    if (res.success) {
      toast.success(res.message);
      get_vendor_profile(user_id);
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    setLoading("");
  }
};

export const delete_product = async (id, setLoading) => {
  try {
    setLoading("u_p");
    const body = new FormData();
    body.append("userid", user_id);
    body.append("productid", id);
    const response = await fetch(base_url + "delete_vendorproduct", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    setLoading("");
    if (res.success) {
      toast.success(res.message);
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    setLoading("");
  }
};

export const send_otp = async (no) => {
  try {
    const body = new FormData();
    body.append("mobile", no);
    body.append("action", 1);
    const response = await fetch(base_url + "otp_request", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    if (res.success) {
      toast.success(res.message);
      return res;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {}
};

export const get_otp = async (no) => {
  try {
    const body = new FormData();
    body.append("mobile", no);
    body.append("action", 1);
    // setOtp_loading(load);
    const response = await fetch(
      "https://gaswala.vensframe.com/api/otp_request",
      { body: body, method: "POST" }
    );
    const res = await response.json();

    return res;
  } catch (error) {}
};
export const get_vehicle = async (d_id) => {
  try {
    const body = new FormData();
    body.append("userid", user_id);
    body.append("ingress", ingress);
    body.append("vehicleid", d_id);
    const response = await fetch(
      "https://gaswala.vensframe.com/api/vehicle_details",
      { body: body, method: "POST" }
    );
    const res = await response.json();

    return res;
  } catch (error) {}
};

export const delete_vehicle = async (u_id, d_id, action) => {
  try {
    const body = new FormData();
    body.append("userid", u_id);
    body.append("vehicleid", d_id);
    body.append("action", action);
    body.append("ingress", ingress);

    const response = await fetch(base_url + "delete_vehicle", {
      body: body,
      method: "POST",
    });
    const res = await response.json();
    console.log("delete_vehicle", res);
    if (res.success) {
      toast.success(res.message);

      return res;
    } else {
      toast.error(res.message);
    }
  } catch (error) {}
};

export const get_driver = async (u_id, d_id) => {
  try {
    const body = new FormData();
    body.append("userid", u_id);
    body.append("driverid", d_id);
    const response = await fetch(base_url + "driver_details", {
      body: body,
      method: "POST",
    });
    const res = await response.json();
    console.log(res);
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const delete_driver = async (u_id, id, action, d_id) => {
  try {
    const body = new FormData();
    body.append("userid", u_id);
    body.append("driverid", id);
    body.append("action", action);
    body.append("ingress", ingress);
    d_id && body.append("id", d_id);

    const response = await fetch(base_url + "delete_driver", {
      body: body,
      method: "POST",
    });
    const res = await response.json();
    console.log(res);
    return res;
  } catch (error) {}
};

export const add_vendorProduct = async () => {
  try {
    const body = new FormData();
    body.append("userid", user_id);
    const response = await fetch(
      "https://gaswala.vensframe.com/api/createinventory",
      { method: "POST", body: body }
    );
    const res = await response.json();

    if (res.success) {
      return res.data;
    } else {
      toast.error(res.message);
    }
  } catch (error) {}
};

export const add_inventory = async () => {
  try {
    const body = new FormData();
    body.append("userid", user_id);
    body.append("action", "1");
    body.append("productid", "PCI002");
    body.append("qty", "10");
    body.append("pilotid", "GW3shDlBD-AA00018");
    body.append("vehicleno", user_id);
    body.append("status", "1");
    const response = await fetch(
      "https://gaswala.vensframe.com/api/createinventory",
      { method: "POST", body: body }
    );
    const res = await response.json();

    if (res.success) {
      return res.data;
    } else {
      toast.error(res.message);
    }
  } catch (error) {}
};
export const get_allProduct = async () => {
  try {
    const body = new FormData();
    body.append("userid", user_id);
    const response = await fetch(
      "https://gaswala.vensframe.com/api/productlist",
      { method: "POST", body: body }
    );
    const res = await response.json();

    if (res.success) {
      return res.data;
    } else {
      toast.error(res.message);
    }
  } catch (error) {}
};

export const add_edit_vehicle = async (body) => {
  try {
    const response = await fetch(base_url + "createvehicle", {
      body: body,
      method: "POST",
    });
    const res = await response.json();

    if (res.success) {
      toast.success(res.message);
      return res;
    } else {
      toast.error(res.message);
    }
  } catch (error) {}
};

export const add_edit_diver = async (body) => {
  try {
    const response = await fetch(base_url + "createdriver", {
      method: "POST",
      body: body,
    });
    const res = await response.json();
    console.log(res);
    if (res.success) {
      toast.success(res.message);
      store.dispatch(get_driver_list());
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {}

  // dispatch(
  //   add_driver(body).then((data) =>
  // );
};

// export const bank_action = async (body) => {
//   try {
//     const response = await fetch("https://gaswala.vensframe.com/api/bank", {
//       method: "POST",
//       body: body,
//     });
//     const res = await response.json();
//

//     if (res.success) {
//       toast.success(res.message);
//       return res;
//     } else {
//       toast.error(res.message);
//     }
//   } catch (error) {
//
//   }

//   // dispatch(
//   //   add_driver(body).then((data) =>
//   // );
// };

// const
export const get_pincode = async (no) => {
  try {
    const body = new FormData();
    body.append("mobile", no);
    body.append("action", 1);
    // setOtp_loading(load);
    const response = await fetch(
      "https://gaswala.vensframe.com/api/pincode_verify",
      { body: body, method: "POST" }
    );
    const res = await response.json();

    if (res.success) {
      toast.success(res.message);
      return;
    } else {
      toast.error(res.message);
      return;
    }
  } catch (error) {}
};

import React, { useEffect, useState } from "react";
import ButtonComp from "../../components/ButtonComp";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { get_vendorproducts_list } from "../../redux/slice/userSlice";
import store from "../../redux/store";
import { useSelector } from "react-redux";
import CustomInput from "../../components/custom/CustomInput";
import { Popup } from "../../utils/DialogBox";
import { IoTrashOutline } from "react-icons/io5";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Checkbox } from "@mui/material";
import { Close } from "@mui/icons-material";
import { create_inventory } from "../../utils/api";
import { ingress, user_id } from "../../utils/utils";
import { toast } from "react-toastify";

const StockTransfer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { vendorProducts } = useSelector((state) => state.user);
  const [selected_product, setSelected_Product] = useState([]);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [search, setSearch] = useState("");
  const filter_data = selected_product.filter((v) => v?.qty);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    store.dispatch(get_vendorproducts_list());
    setSelected_Product([
      {
        name: location.state.description,
        qty: location.state.accepted_qty,
        product_id: location.state.product_id,
      },
    ]);
  }, []);

  const create = () => {
    if (!data.v_no || !data.d_id || filter_data.length == 0) {
      toast.info("all feildes are required");
      return;
    }
    const body = new FormData();
    body.append("userid", user_id);
    body.append("productid", filter_data.map((v) => v.product_id).toString());
    body.append("qty", filter_data.map((v) => v.qty).toString());
    body.append("pilotid", data.d_id);
    body.append("ingress", ingress);
    body.append("vehicleno", data.v_no);
    body.append("status", 2);
    create_inventory(body, setLoading).then((v) => {
      if (v.success) {
        toast.success(v.message);
        navigate(-1);
      } else {
        toast.error(v.message);
      }
    });
  };

  return (
    <>
      <div className="card padding-xy mb10 df alc sb">
        <div className="heading-text">Stock Transfer</div>
        <ButtonComp
          b_color={"black"}
          icon={<BiArrowBack color="#fff" size={16} />}
          onClick={() => navigate(-1)}
          tittle={"Back"}
        />
      </div>
      <div className={"card p20 "}>
        <CustomInput
          label1={"Driver id"}
          placeholder1={"Enter Driver id"}
          value1={data.d_id}
          onChange1={(e) => setData({ ...data, d_id: e.target.value })}
          label2={"Vehicle Number"}
          placeholder2={"Enter vehicle number"}
          value2={data.v_no}
          onChange2={(e) => setData({ ...data, v_no: e.target.value })}
        />

        <div style={{ height: 10 }} />
        <div className={"df alc sb"}>
          <p>Products</p>
          <ButtonComp onClick={() => setShow(true)} tittle={"Add"} />
        </div>
        {selected_product.length == 0 || (
          <div className="df alc jcc mt10 ">
            <table className="inv_table">
              <tr className="inv_tr">
                <th className="inv_td">S.No.</th>
                <th className="inv_td">Name</th>
                <th className="inv_td">Quantity</th>
                <th className="inv_td">Action</th>
              </tr>
              {selected_product.map((v, i) => {
                return (
                  <tr className="inv_tr" key={i}>
                    <td className="inv_td">{i + 1}</td>
                    <td className="inv_td"> {v.name}</td>
                    <td className="inv_td">
                      <input
                        value={v?.qty}
                        className="text_bg"
                        placeholder="Enter Quntity"
                        onChange={(val) => {
                          setSelected_Product((pre) =>
                            pre.find((item, ind) => i === ind)
                              ? pre.map((el, index) =>
                                  i === index
                                    ? {
                                        ...el,
                                        qty: val.target.value.replace(
                                          /\D/g,
                                          ""
                                        ),
                                      }
                                    : el
                                )
                              : [...pre]
                          );
                        }}
                      />
                    </td>
                    <td className="inv_td">
                      <IoTrashOutline
                        color="red"
                        size={20}
                        className="cursor"
                        onClick={() =>
                          setSelected_Product((pre) =>
                            pre.find((item, ind) => i === ind)
                              ? pre.filter((el, index) => i !== index)
                              : [...pre]
                          )
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        )}
        <Popup
          open={show}
          one
          message={
            <div className="df alc sb pb6">
              <div className="title-text">Products</div>
              <Close className="cursor" onClick={() => setShow(false)} />
            </div>
          }
          body={
            <>
              <div
                style={{ position: "sticky", top: 0, zIndex: 1000 }}
                className={
                  "border-gray-200 bg-gray-100 padding-xy alc df br search-bar"
                }
              >
                <input
                  className="flex"
                  type="text"
                  placeholder="Search..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <SearchOutlinedIcon />
              </div>
              {vendorProducts
                .filter((el) => {
                  return el.label.toLowerCase().includes(search.toLowerCase());
                })
                .map((v, i) => (
                  <>
                    <div className="df alc sb pb6">
                      <div className="fs14 fw400 black ">{v.label}</div>
                      <Checkbox
                        color="success"
                        onChange={() =>
                          setSelected_Product((pre) => [
                            ...pre,
                            {
                              name: v.label,
                              qty: 0,
                              product_id: v.value,
                            },
                          ])
                        }
                        checked={
                          selected_product.find(
                            (el) => el.product_id == v.value
                          )
                            ? true
                            : false
                        }
                      />
                    </div>
                  </>
                ))}
            </>
          }
          setOpen={setShow}
          b_name={"Done"}
        />

        <div className="df alc jcc mt20">
          <ButtonComp
            tittle={"Submit"}
            loading={loading}
            onClick={() => create()}
          />
        </div>
      </div>
    </>
  );
};

export default StockTransfer;

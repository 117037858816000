import React, { useState } from "react";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";
import { create_driver, get_otp, send_otp } from "../../utils/apis";
import { useDispatch } from "react-redux";
import ButtonComp from "../../components/ButtonComp";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import CustomInput from "../../components/custom/CustomInput";
import { ingress, isValidEmail, user_id } from "../../utils/utils";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Add_Doc from "../../components/custom/Add_doc";

const AddDriver = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [docList, setDocList] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [address, setAddress] = useState("");
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [loading, setLoading] = useState(false);
  const [aadhar, setAadhar] = useState("");
  const [pan, setPan] = useState("");
  const [validity, setValidity] = useState("");
  const [license, setLicense] = useState("");
  const [password, setPassword] = useState("");
  const [otp1_show, setOtp1_show] = useState(false);
  const [otp2_show, setOtp2_show] = useState(false);
  const [otp_loading, setOtp_loading] = useState("0");
  const [user1, setUser1] = useState("");
  const [user2, setUser2] = useState("");
  const [showPass, setShowPass] = useState(false);

  const handleFileChange = (e, i) => {
    if (Object.entries(docList[i]).length == 0) {
      toast.error("please enter document name");
      return;
    }
    let newitem_detail = docList.map((el, ind) =>
      ind === i
        ? {
            ...el,
            name: e.target.files[0].name,
            docData: e.target.files[0],
          }
        : el
    );
    setDocList(newitem_detail);
  };
  const filter_data = docList.filter(
    (v, i) => Object.entries(docList[i]).length != 0 && v?.name !== ""
  );

  const handle_add_diver = async () => {
    if (
      !name ||
      !email ||
      !phone1 ||
      !phone2 ||
      !address ||
      !aadhar ||
      !pan ||
      !docList ||
      !otp1 ||
      !otp2
    ) {
      toast.info("Please fill all the fields");
      return;
    }
    if (!email.match(isValidEmail)) {
      toast.info("Please enter a valid email");
      return;
    }
    if (filter_data.length == 0) {
      toast.info("Please select documents");
      return;
    }
    const body = new FormData();
    body.append("userid", user_id);
    body.append("id", user1 + "," + user2);
    body.append("name", name);
    body.append("email", email);
    body.append("mobile1", phone1);
    body.append("mobile2", phone2);
    body.append("address", address);
    body.append("aadhar", aadhar);
    body.append("pan", pan);
    body.append("drivinglicense", license);
    body.append("otp1", otp1);
    body.append("otp2", otp2);
    body.append("action", 2);
    body.append("ingress", ingress);
    body.append("password", password);
    body.append("documentscount", filter_data.length);
    body.append("ingress", ingress);
    body.append("names", filter_data.map((v) => v.filename).join(",,,"));
    body.append("drivinglicensevalidity", validity);
    {
      filter_data.forEach((v, i) =>
        body.append(`${"document" + i}`, v.docData)
      );
    }
    create_driver(body, setLoading).then((v) => v && navigate(-1));
  };
  const sendOtp = (no, load) => {
    if (no.length == 0) {
      toast.info("please enter mobile number");
      return;
    }
    setOtp_loading(load);
    send_otp(no)
      .then((data) => {
        if (data.success) {
          if (load == 1) {
            setOtp1_show(true);
            setUser1(data.id);
          } else {
            setOtp2_show(true);
            setUser2(data.id);
          }
        } else {
          if (load == 1) {
            setOtp1_show(false);
            setUser1("");
          } else {
            setOtp2_show(false);
            setUser2("");
          }
        }
        setOtp_loading("");
      })
      .catch(() => setLoading(""));
  };

  return (
    <>
      <div className={"card df padding-xy sb alc mb10"}>
        <div className="heading-text">Add Driver</div>
        <ButtonComp
          onClick={() => navigate(-1)}
          tittle={"Back"}
          b_color={"#000"}
          icon={<BiArrowBack color="#fff" />}
        />
      </div>
      <div className={"card ph20"}>
        <CustomInput
          label1={"Name"}
          type1="text"
          placeholder1={"Enter Name"}
          value1={name}
          onChange1={(e) => setName(e.target.value)}
          label2={"Email"}
          type2="text"
          placeholder2={"Enter email"}
          value2={email}
          onChange2={(e) => setEmail(e.target.value)}
        />
        <CustomInput
          label1={"Driving License Number"}
          type1="text"
          placeholder1={"Enter driving license number"}
          value1={license}
          onChange1={(e) => setLicense(e.target.value)}
          label2={"Driving License Validity"}
          type2="date"
          // placeholder2={"Enter pan number"}
          min2={new Date().toISOString().split("T")[0]}
          value2={validity}
          onChange2={(e) => setValidity(e.target.value)}
        />
        <CustomInput
          label1={"Pan Number"}
          type1="number"
          placeholder1={"Enter pan number"}
          value1={pan}
          onChange1={(e) => setPan(e.target.value)}
          label2={"Aadhar Number"}
          type2="number"
          placeholder2={"Enter aadhar number"}
          value2={aadhar}
          onChange2={(e) => setAadhar(e.target.value)}
        />
        <CustomInput
          label1={"Address"}
          type1="text"
          placeholder1={"Enter address"}
          area1
          value1={address}
          onChange1={(e) => setAddress(e.target.value)}
          label2={"Password"}
          type2={showPass ? "text" : "password"}
          placeholder2={"Enter password"}
          value2={password}
          onChange2={(e) => setPassword(e.target.value)}
          right2={
            showPass ? (
              <BsEyeSlash
                size={16}
                style={{ cursor: "pointer" }}
                onClick={() => setShowPass(!showPass)}
              />
            ) : (
              <BsEye
                size={16}
                style={{ cursor: "pointer" }}
                onClick={() => setShowPass(!showPass)}
              />
            )
          }
        />
        <CustomInput
          label1={"Mobile 1"}
          type1="number"
          placeholder1={"Enter mobile number"}
          value1={phone1}
          onChange1={(e) => setPhone1(e.target.value)}
          label2={"Mobile 2"}
          type2="number"
          placeholder2={"Enter mobile number"}
          value2={phone2}
          onChange2={(e) => setPhone2(e.target.value)}
          button1={
            <ButtonComp
              tittle={"Get OTP"}
              loading={otp_loading == "1" ? true : false}
              onClick={() => sendOtp(phone1, 1)}
            />
          }
          button2={
            <ButtonComp
              tittle={"Get OTP"}
              loading={otp_loading == "2" ? true : false}
              onClick={() => sendOtp(phone2, 2)}
            />
          }
          bottom1={
            otp1_show && (
              <div className={"df jcc m20"}>
                <OtpInput
                  value={otp1}
                  shouldAutoFocus={true}
                  onChange={(e) => setOtp1(e)}
                  numInputs={4}
                  inputStyle={"inputStyle"}
                  separator={<span style={{ margin: 5 }}> </span>}
                />
              </div>
            )
          }
          bottom2={
            otp2_show && (
              <div className={"df jcc m20"}>
                <OtpInput
                  value={otp2}
                  onChange={(e) => setOtp2(e)}
                  numInputs={4}
                  inputStyle={"inputStyle"}
                  separator={<span style={{ margin: 5 }}> </span>}
                />
              </div>
            )
          }
        />

        <div className={"df alc sb mt10"}>
          <div>Documents</div>
          <ButtonComp
            tittle={"Add"}
            onClick={() => setDocList((pre) => [...pre, {}])}
          />
        </div>
        {docList.map((v, i) => (
          <Add_Doc
            top={5}
            value={Object.entries(v).length === 0 ? "" : v.filename}
            onChange1={(e) => {
              let newitem_detail = docList.map((el, ind) =>
                ind === i
                  ? {
                      ...el,
                      filename: e.target.value,
                      name: "",
                    }
                  : el
              );
              setDocList(newitem_detail);
            }}
            placeholder="Enter document name"
            single
            onChange={(e) => handleFileChange(e, i)}
            filename={v.name}
            right={
              <ButtonComp
                top={4}
                tittle={"Delete"}
                b_color={"#f00"}
                onClick={() =>
                  setDocList((pre) =>
                    pre.find((v, ind) => ind === i)
                      ? pre.filter((v, index) => index !== i)
                      : [...pre]
                  )
                }
              />
            }
          />
        ))}
        <div className="df jcc p20">
          <ButtonComp
            tittle={"Submit"}
            loading={loading}
            onClick={() => handle_add_diver()}
          />
        </div>
      </div>
    </>
  );
};

export default AddDriver;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader, No_data } from "../../utils/utils";
import store from "../../redux/store";
import ButtonComp from "../../components/ButtonComp";
import { useNavigate } from "react-router-dom";
import { Popup } from "../../utils/DialogBox";
import { get_processsing_list } from "../../redux/slice/pilotOrdersSlice";

const ProcessingPilotOrders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState("");
  const [view, setView] = useState(false);
  const [data, setData] = useState([]);
  const { processing_list, processing_loading } = useSelector(
    (state) => state.pilotOrders
  );
  useEffect(() => {
    store.dispatch(get_processsing_list());
  }, []);
  return (
    <div>
      {processing_list.length == 0 && processing_loading ? (
        <div className="card p20 df alc jcc">
          <Loader />
        </div>
      ) : processing_list.length == 0 ? (
        <No_data />
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: window.matchMedia("(max-width: 500px)").matches
              ? "repeat(1, 1fr)"
              : window.matchMedia("(max-width: 900px)").matches
              ? "repeat(2, 1fr)"
              : "repeat(3, 1fr)",
            gridGap: 20,
          }}
        >
          {processing_list.map((v, i) => (
            <div className={"grid-item card p20"} key={i}>
              <div className="df alc sb pb6">
                <div className="fs16 fw600 orange ">{v.orderid}</div>
                {/* <div className="fs14 fw400 black">{v.datetime}</div> */}
                <div
                  className="fs14 fw600 green cursor"
                  onClick={() => {
                    setData(v.products);
                    setView(true);
                  }}
                >
                  View Products
                </div>
              </div>

              <div className="df  gap10 pb6">
                <div className="fs14 fw400 black">Address:</div>
                <div className="fs14 fw400 black">
                  {v?.deliveryaddress[0]?.address +
                    "," +
                    v?.deliveryaddress[0]?.landmark +
                    "," +
                    v?.deliveryaddress[0]?.district +
                    "," +
                    v?.deliveryaddress[0]?.pincode +
                    "," +
                    v?.deliveryaddress[0]?.state}
                </div>
              </div>

              <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Datetime:</div>
                <div className="fs14 fw400 black">{v.datetime}</div>
              </div>
              <div className="df alc gap10 pb6">
                <div className="fs14 fw400 black">Status:</div>
                <div className="fs14 fw400 green">{v.orderstatustext}</div>
              </div>
              <div className={"df jcc gap20"}>
                <ButtonComp
                  tittle={"Get Direction"}
                  height={30}
                  // onClick={() => {
                  //   setId(i);
                  //   setQty(v.quantity);
                  //   setReject(true);
                  // }}
                />
                <ButtonComp
                  tittle={"View Detail"}
                  height={30}
                  onClick={() => {
                    navigate("/orders/order-detail", { state: v });
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <Popup
        open={view}
        message={<div className="title-text ">Products detail</div>}
        body={
          <>
            <div className="df alc sb pb6">
              <div className="fs14 fw400 black">Product</div>
              <div className="fs14 fw400 black ">Quantity</div>
            </div>
            {data.map((v, i) => (
              <>
                <div
                  key={i}
                  style={{ justifyContent: "space-between", display: "flex" }}
                >
                  <div
                    className="fs16 fw400 black pb6"
                    style={{ lineHeight: 1.3 }}
                  >
                    {v.description}
                  </div>
                  <div
                    className="fs16 fw400 black pb6"
                    style={{ lineHeight: 1.3 }}
                  >
                    {v.count}
                  </div>
                </div>
              </>
            ))}
          </>
        }
        setOpen={setView}
      />
    </div>
  );
};

export default ProcessingPilotOrders;

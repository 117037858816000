import React, { useEffect } from "react";
import { AiOutlineDashboard } from "react-icons/ai";
import { FiPackage, FiUser } from "react-icons/fi";
import { IoCartOutline } from "react-icons/io5";
import {
  MdMiscellaneousServices,
  MdOutlineInventory,
  MdOutlineWarehouse,
} from "react-icons/md";
import { TbTruckDelivery, TbUser } from "react-icons/tb";
import { NavLink, useLocation } from "react-router-dom";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { HiOutlineClipboardList } from "react-icons/hi";
import { ingress } from "../../utils/utils";
import store from "../../redux/store";
import { setActive_Tab } from "../../redux/slice/userSlice";

const SidebarItems = ({ setClose, onclick }) => {
  const location = useLocation();
  const [isActive, setIsActive] = React.useState(location.pathname);

  useEffect(() => {
    setIsActive(location.pathname);
  }, [location]);
  return (
    <div className={""} style={{ marginTop: "20px" }}>
      <NavLink
        to={"/"}
        onClick={onclick}
        className={`df alc mt10 ph ${
          isActive == "/"
            ? "link activelink-bg link-br"
            : "link unactivelink-bg"
        }`}
      >
        <AiOutlineDashboard size={25} />
        Dashboard
      </NavLink>
      {(ingress == 1 || ingress == 4) && (
        <NavLink
          to={"orders"}
          onClick={() => store.dispatch(setActive_Tab("1"))}
          className={`df alc mt10 ph ${
            isActive.split("/")[1] == "orders"
              ? "link activelink-bg link-br"
              : "link unactivelink-bg"
          }`}
        >
          <IoCartOutline size={25} />
          Orders
        </NavLink>
      )}
      {ingress == 1 && (
        <NavLink
          to={"indents"}
          onClick={() => store.dispatch(setActive_Tab("1"))}
          className={`df alc mt10 ph ${
            isActive.split("/")[1] == "indents"
              ? "link activelink-bg link-br"
              : "link unactivelink-bg"
          }`}
        >
          <IoCartOutline size={25} />
          Indents
        </NavLink>
      )}
      {(ingress == 1 || ingress == 4) && (
        <NavLink
          onClick={onclick}
          to={"inventory"}
          className={`df alc mt10 ph ${
            isActive.split("/")[1] == "inventory"
              ? "link activelink-bg link-br"
              : "link unactivelink-bg"
          }`}
        >
          <MdOutlineInventory size={25} />
          Inventory
        </NavLink>
      )}
      {ingress == 1 && (
        <NavLink
          onClick={onclick}
          to={"products"}
          className={`df alc mt10 ph ${
            isActive.split("/")[1] == "products"
              ? "link activelink-bg link-br"
              : "link unactivelink-bg"
          }`}
        >
          <FiPackage size={25} />
          Products
        </NavLink>
      )}
      {ingress == 1 && (
        <NavLink
          onClick={onclick}
          to={"services"}
          className={`df alc mt10 ph ${
            isActive == "/services"
              ? "link activelink-bg link-br"
              : "link unactivelink-bg"
          }`}
        >
          <MdMiscellaneousServices size={25} />
          Services
        </NavLink>
      )}
      {ingress == 1 && (
        <NavLink
          onClick={onclick}
          to={"godowns"}
          className={`df alc mt10 ph ${
            isActive.split("/")[1] == "godowns"
              ? "link activelink-bg link-br"
              : "link unactivelink-bg"
          }`}
        >
          <MdOutlineWarehouse size={25} />
          Godowns
        </NavLink>
      )}
      {ingress == 4 || (
        <NavLink
          onClick={onclick}
          to={"driver"}
          className={`df alc mt10 ph ${
            isActive.split("/")[1] == "driver"
              ? "link activelink-bg link-br"
              : "link unactivelink-bg"
          }`}
        >
          <FiUser size={25} />
          Driver
        </NavLink>
      )}
      {ingress == 4 || (
        <NavLink
          onClick={onclick}
          to={"vehicle"}
          className={`df alc mt10 ph ${
            isActive.split("/")[1] == "vehicle"
              ? "link activelink-bg link-br"
              : "link unactivelink-bg"
          }`}
        >
          <TbTruckDelivery size={25} />
          Vehicle
        </NavLink>
      )}
      {ingress == 4 || (
        <NavLink
          onClick={onclick}
          to={"/allotment"}
          className={`df alc mt10 ph ${
            isActive.split("/")[1] == "allotment"
              ? "link activelink-bg link-br"
              : "link unactivelink-bg"
          }`}
        >
          <LocalShippingIcon />
          Allotment
        </NavLink>
      )}

      {ingress == 1 && (
        <NavLink
          onClick={onclick}
          to={"quotation"}
          className={`df alc mt10 ph ${
            isActive == "/quotation"
              ? "link activelink-bg link-br"
              : "link unactivelink-bg"
          }`}
        >
          <HiOutlineClipboardList size={25} />
          Quotation
        </NavLink>
      )}
      <NavLink
        onClick={onclick}
        to={"/profile/information"}
        className={`df alc mt10 ph ${
          isActive.split("/")[1] == "profile"
            ? "link activelink-bg link-br"
            : "link unactivelink-bg"
        }`}
      >
        <TbUser size={25} />
        Profile
      </NavLink>
    </div>
  );
};

export default SidebarItems;

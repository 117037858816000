import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { isSmallScreen } from "../../utils/utils";

const Footer = () => {
  return (
    <div
      className="footer_div p10"
      style={{
        backgroundColor: "#fff",
        paddingInline: "30px",
        justifyContent: "space-between",
        height: "4rem",
        alignItems: "center",
      }}
    >
      <div
        className="df alc"
        style={{
          gap: 4,
        }}
      >
        <AiOutlineCopyrightCircle />
        <div style={{ fontSize: 16 }}>2023</div>
        <div style={{ fontSize: 16 }}>Gaswale.com</div>
      </div>
      <div className="df gap20">
        <div>
          <Link style={{ textDecorationLine: "none", color: "#000" }}>
            Privacy Policy
          </Link>
        </div>
        <Link style={{ textDecorationLine: "none", color: "#000" }}>
          Terms & Conditions
        </Link>
      </div>
    </div>
  );
};

export default Footer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { base_url } from "../../utils/utils";

const loggedInUser = localStorage.getItem("user");
const user = JSON.parse(loggedInUser);
const user_id = user?.userid;
const ingress = user?.access;

export const get_driver_list = createAsyncThunk("driver", async () => {
  const body = new FormData();
  body.append("userid", user_id);
  body.append("ingress", ingress);

  const response = await fetch(base_url + "driverlist", {
    method: "POST",
    body: body,
  });
  const res = await response.json();
  return res;
});

const driverSlice = createSlice({
  initialState: {
    d_loading: false,
    driver_list: [],
  },
  name: "driver",
  extraReducers: (builder) => {
    builder.addCase(get_driver_list.fulfilled, (state, action) => {
      const data = action.payload;
      state.d_loading = false;
      if (data.success) {
        state.driver_list = data.data;
      } else {
        state.driver_list = [];
      }
    });
    builder.addCase(get_driver_list.pending, (state, action) => {
      state.d_loading = true;
    });
  },
});

export default driverSlice.reducer;
